import React, { useState } from 'react';
import './BatchPlanSection.css';
import Api from '../../common/APIUtils';
import BatchPlanEditor from './BatchPlanEditor';
import { MdOutlineModeEdit } from "react-icons/md";

const api = new Api();

const BatchPlanSection = ({ batchPlan, batchId, onUpdate, recipe }) => {
    const [isMetric, setIsMetric] = useState(() => {
        const stored = localStorage.getItem('temperature-unit');
        return stored ? stored === 'C' : false;
    });
    const [editorConfig, setEditorConfig] = useState({
        isOpen: false,
        fieldKey: '',
        label: '',
        type: 'text',
        initialValue: ''
    });

    const convertToC = (f) => Math.round((f - 32) * 5 / 9);
    const convertToF = (c) => Math.round(c * 9 / 5 + 32);

    const handleTemperatureUpdate = async (field, value) => {
        const updates = {
            [field]: isMetric ? convertToF(value) : value
        };
        
        // Create a new object with all existing properties plus the update
        const updatedPlan = { ...batchPlan, ...updates };
        
        // Optimistic update
        onUpdate(updatedPlan);
        
        try {
            await api.updateBatchPlan(batchPlan._id, updates);
        } catch (error) {
            // Silently fail as per requirements
            console.error('Failed to update batch plan:', error);
        }
    };

    const handleProcessUpdate = async (field, value) => {
        const updates = { [field]: value };
        
        // Create a new object with all existing properties plus the update
        const updatedPlan = { ...batchPlan, ...updates };
        
        // Optimistic update
        onUpdate(updatedPlan);
        
        try {
            await api.updateBatchPlan(batchPlan._id, updates);
        } catch (error) {
            // Silently fail as per requirements
            console.error('Failed to update batch plan:', error);
        }
    };

    const openEditor = (key, label, type = 'text', value) => {
        setEditorConfig({
            isOpen: true,
            fieldKey: key,
            label,
            type,
            initialValue: value
        });
    };

    const closeEditor = () => {
        setEditorConfig(prev => ({ ...prev, isOpen: false }));
    };

    const temperatureFields = [
        { key: 'pour_temp_f', label: 'Pour Temperature' },
        { key: 'fo_add_temp_f', label: 'FO Add Temperature' },
        { key: 'room_temp_f', label: 'Room Temperature' }
    ];

    const processFields = [
        { key: 'cure_time_days', label: 'Cure Time (Days)', type: 'number' },
        { key: 'heat_source', label: 'Heat Source', type: 'text' },
        { key: 'thermometer', label: 'Thermometer', type: 'text' },
        { key: 'preheated_jars', label: 'Preheated Jars', type: 'boolean' }
    ];

    return (
        <div className="batch-plan-section">
            <div className="batch-desktop-split">
                <div className="batch-desktop-split-section">
                    <div>
                        <div className="batch-plan-section-header">
                            <h2>Temperature</h2>
                            <div className="batch-plan-unit-selector">
                                <button 
                                    className={`batch-plan-unit-option ${!isMetric ? 'selected' : ''}`}
                                    onClick={() => {
                                        setIsMetric(false);
                                        localStorage.setItem('temperature-unit', 'F');
                                    }}
                                >
                                    °F
                                </button>
                                <button 
                                    className={`batch-plan-unit-option ${isMetric ? 'selected' : ''}`}
                                    onClick={() => {
                                        setIsMetric(true);
                                        localStorage.setItem('temperature-unit', 'C');
                                    }}
                                >
                                    °C
                                </button>
                            </div>
                        </div>
                        <div className="batch-plan-fields">
                            {temperatureFields.map(({ key, label }) => (
                                <div key={key} className="batch-plan-field">
                                    <span className="batch-plan-label">{label}</span>
                                    <div 
                                        className="batch-plan-pill"
                                        onClick={() => openEditor(
                                            key,
                                            label,
                                            'number',
                                            isMetric ? convertToC(batchPlan[key]) : batchPlan[key]
                                        )}
                                    >
                                        {isMetric 
                                            ? `${convertToC(batchPlan[key])}°C`
                                            : `${batchPlan[key]}°F`
                                        }
                                        <MdOutlineModeEdit className="batch-plan-edit-icon" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="batch-desktop-split-section">
                    <div>
                        <h2>Process</h2>
                        <div className="batch-plan-fields">
                            {processFields.map(({ key, label, type }) => (
                                <div key={key} className="batch-plan-field">
                                    <span className="batch-plan-label">{label}</span>
                                    {type === 'boolean' ? (
                                        <label className="batch-plan-toggle">
                                            <input
                                                type="checkbox"
                                                checked={batchPlan[key] || false}
                                                onChange={(e) => handleProcessUpdate(key, e.target.checked)}
                                            />
                                            <span className="batch-plan-toggle-slider"></span>
                                        </label>
                                    ) : (
                                        <div 
                                            className="batch-plan-pill"
                                            onClick={() => openEditor(key, label, type, batchPlan[key])}
                                        >
                                            {type === 'number' ? batchPlan[key] : batchPlan[key] || 'Not set'}
                                            <MdOutlineModeEdit className="batch-plan-edit-icon" />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {recipe.notes &&
            <div className="batch-desktop-split">
                <div className="batch-desktop-split-section">
                <div>
                    <h2>Recipe Notes</h2>
                    <p className="batch-plan-recipe-notes">{recipe.notes}</p>
                </div>
                </div>
            </div>

            }

            <BatchPlanEditor
                {...editorConfig}
                onClose={closeEditor}
                onSave={editorConfig.fieldKey.includes('temp') ? handleTemperatureUpdate : handleProcessUpdate}
                isMetric={isMetric}
            />
        </div>
    );
};

export default BatchPlanSection; 