import React from 'react'
import { MdClear } from 'react-icons/md'
import { useEffect, useReducer, useState } from 'react'
import LoadingSymbol from '../misc/LoadingSymbol'
import { getSupplyNameById, sortByProperty } from '../../common/Helpers'

export default function ModalBatchException({ hideModal, batch, recipe, saveBatchUpdates, globalState }) {
    const [updatedBatch, updatedBatchDispatch] = useReducer((state, action) => {
        if (action.type === "init") {
            let hasExceptions = false
            let bufferPercent = 0
            if (batch.has_exceptions) hasExceptions = true
            if (batch.buffer_percentage) bufferPercent = batch.buffer_percentage
            
            // Init wax
            let exceptionWaxMap = { blends: [] }
            let baseWaxMap = { blends: [] } // original supplies from starting point

            // Calculate original supplies needs
            const totalWaxWeightGrams = parseFloat(batch.weight_wax_grams * (1 + (bufferPercent/100))).toFixed(0)
            let waxBlends = []
            let baseWaxBlends = []
            if (recipe.schema === 1 || !recipe.schema) {
                waxBlends = [ {name: recipe.wax, weight_grams: totalWaxWeightGrams }]
            }
            if (recipe.schema === 2) {
                if (recipe.wax_blend_map) {
                    const currentRecipeBlends = recipe.wax_blend_map.blends
                    for (let i = 0; i < currentRecipeBlends.length; i++) {
                        const blendElement = currentRecipeBlends[i];
                        let proposedWaxBlend = {
                            name: blendElement['name'],
                            weight_grams: parseFloat(totalWaxWeightGrams * blendElement['weight'] / 100).toFixed(0)
                        }
                        if ('supply_id' in blendElement) proposedWaxBlend['supply_id'] = blendElement['supply_id']
                        waxBlends.push(proposedWaxBlend)
                        baseWaxBlends.push(proposedWaxBlend)
                    }
                } else if (recipe.wax) {
                    waxBlends = [ {name: recipe.wax, weight_grams: totalWaxWeightGrams }]
                    baseWaxBlends = [ {name: recipe.wax, weight_grams: totalWaxWeightGrams }]
                }
            }
            baseWaxMap = { blends: baseWaxBlends }
            if (!hasExceptions) exceptionWaxMap = { blends: waxBlends }
            if (hasExceptions) exceptionWaxMap = { blends: batch.exception_wax_blend_map.blends }

            // Init fragrance oils
            let exceptionFragranceMap = { blends: [] }
            let baseFragranceMap = { blends: [] }
            const totalFoWeightGrams = parseFloat(batch.weight_fragrance_grams * (1 + (bufferPercent/100))).toFixed(0)
            let foBlends = []
            let baseFoBlends = []
            if (!recipe.fragrance_oil_blend_map || recipe.schema === 1 || parseFloat(recipe.fragrance_load) === 0) {
                foBlends = [ { name: recipe.fragrance_oil, weight_grams: totalFoWeightGrams }]
                baseFoBlends = [ { name: recipe.fragrance_oil, weight_grams: totalFoWeightGrams }]
            } else if (recipe.fragrance_oil_blend_map && parseFloat(recipe.fragrance_load) > 0) {
                if (recipe.fragrance_oil_blend_map.blends) {
                    const currentRecipeBlends = recipe.fragrance_oil_blend_map.blends
                    for (let i = 0; i < currentRecipeBlends.length; i++) {
                        const blendElement = currentRecipeBlends[i];
                        let proposedFoBlends = {
                            name: blendElement['name'],
                            weight_grams: parseFloat(totalFoWeightGrams * blendElement['weight'] / 100).toFixed(0)
                        }
                        if ('supply_id' in blendElement) proposedFoBlends['supply_id'] = blendElement.supply_id
                        foBlends.push(proposedFoBlends)
                        baseFoBlends.push(proposedFoBlends)
                    }
                }
            }
            baseFragranceMap = { blends: baseFoBlends }
            if (!hasExceptions && recipe.fragrance_load > 0) exceptionFragranceMap = { blends: foBlends }
            if (hasExceptions && recipe.fragrance_load > 0) exceptionFragranceMap = { blends: batch.exception_fragrance_oil_blend_map.blends }
        
            // Init wicks
            let wickName = false
            let wickId = ""
            if (recipe.wick_in_recipe) {
                if (hasExceptions && batch.exception_wick_name) wickName = batch.exception_wick_name
                if (hasExceptions && !batch.exception_wick_name) wickName = recipe.wick_name
                if (!hasExceptions) wickName = recipe.wick_name

                if (hasExceptions && batch.exception_wick_id) wickId = batch.exception_wick_id
                if (hasExceptions && !batch.exception_wick_id) wickId = recipe.wick_id
                if (!hasExceptions) wickId = recipe.wick_id
            }

            return {
                ...state,
                loaded: true,
                hasExceptions: hasExceptions,
                exception_fragrance_oil_blend_map: exceptionFragranceMap,
                exception_wax_blend_map: exceptionWaxMap,
                base_wax_blend_map: baseWaxMap,
                base_fo_blend_map: baseFragranceMap,
                wick_name: wickName,
                wick_id: wickId
            }
        }
        if (action.type === "update") {
            let update = {}
            update[action.prop] = action.value
            return { ...state, ...update }
        }
        if (action.type === "update_wax_weight") {
            let waxBlendMap = Array.from(state.exception_wax_blend_map.blends)
            waxBlendMap[action.index]['weight_grams'] = action.grams
            return { ...state, exception_wax_blend_map: { blends: waxBlendMap } }
        }
        if (action.type === "update_fo_weight") {
            let foBlendMap = Array.from(state.exception_fragrance_oil_blend_map.blends)
            foBlendMap[action.index]['weight_grams'] = action.grams
            return { ...state, exception_fragrance_oil_blend_map: { blends: foBlendMap } }
        }
        return state
    }, batch)
    const [isSaving, setIsSaving] = useState(() => { return false })
    const [inventoryWicks, setInventoryWicks] = useState(() => { return [] })
    const submitChanges = () => {
        setIsSaving(true)
        saveBatchUpdates(updatedBatch)
    }
    const updateAvailableSupplies = () => {
        try {
            if (!globalState) return
            if (!globalState.supplies) return
            if (globalState.supplies.length === 0) return
            const copyOfSupplies = Array.from(globalState.supplies)
            const onlyWicks = copyOfSupplies.filter(s => s.type === "wick")
            const orderedWicks = sortByProperty(onlyWicks, "supply_name")
            setInventoryWicks(orderedWicks)
        } catch (error) {
            return
        }
    }
    useEffect(() => {
        if (!batch) return
        updatedBatchDispatch({type: "init"})
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        updateAvailableSupplies()
    // eslint-disable-next-line
    }, [globalState])
    // if batch.has_exception is false then init with the recipe-driven items
    // if changes are made, everything displayed is based on exception_fragrance_oil_blend_map and exception_wax_blend_map 
    return (
        <div className="modal-boundary">
            <div className="modal-backdrop">
                <div className="modal-holder">
                    <div className="modal-overlay">
                        
                        <div className="modal-header">
                            <h2>Batch Exceptions</h2>
                            {!isSaving && <MdClear onClick={hideModal}/>}
                        </div>
                        {isSaving &&
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "0px"}}>
                                <div style={{display: "flex", justifyContent: "center"}}><LoadingSymbol/></div>
                                <p>Saving changes...</p>
                            </div>
                        }
                        {!updatedBatch.loaded && <div style={{display: "flex", justifyContent: "center"}}><LoadingSymbol/></div> }
                        {updatedBatch.loaded && !isSaving && 
                        <>
                        <div className="modal-edit-section" style={{marginBottom: "12px", borderBottom: "1px solid var(--ml-color-border-dark)", paddingBottom: "4px"}}>
                            <h3 style={{fontSize: "20px", marginBottom: 0}}>{updatedBatch.name}</h3>
                            <p style={{margin: 0}}>Enter changes to materials and supplies used</p>
                        </div>
                        <div className="modal-edit-section" style={{borderBottom: "1px solid var(--ml-color-border-dark)", paddingBottom: "12px", marginBottom: "12px"}}>
                            <h3>Wax</h3>
                            <div className="modal-edit-section-multi-row-inputs">
                                {updatedBatch.exception_wax_blend_map.blends.map((waxBlend, index) => (
                                   <div className="modal-edit-section-multi-row-input" key={waxBlend.name}>
                                        <div className="modal-edit-section-multi-row-name">
                                            {waxBlend.supply_id && getSupplyNameById(globalState.supplies, waxBlend.supply_id)}
                                            {!waxBlend.supply_id && waxBlend.name}
                                            <span>{updatedBatch.base_wax_blend_map.blends[index].weight_grams} g</span>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center", gap: "4px", justifyContent: "flex-end"}}>
                                            <input type="number" min={0} value={waxBlend.weight_grams} onChange={(e) => updatedBatchDispatch({type: "update_wax_weight", name: waxBlend.name, index: index, grams: e.target.value})} />
                                            <span>g</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {recipe.fragrance_load > 0 &&
                        <div className="modal-edit-section" style={{borderBottom: "1px solid var(--ml-color-border-dark)", paddingBottom: "12px", marginBottom: "12px"}}>
                            <h3>Fragrance Oil</h3>
                            <div className="modal-edit-section-multi-row-inputs">
                                {updatedBatch.exception_fragrance_oil_blend_map.blends.map((foBlend, index) => (
                                   <div className="modal-edit-section-multi-row-input" key={foBlend.name}>
                                        <div className="modal-edit-section-multi-row-name">
                                            {foBlend.supply_id && getSupplyNameById(globalState.supplies, foBlend.supply_id)}
                                            {!foBlend.supply_id && foBlend.name}
                                            <span>{updatedBatch.base_fo_blend_map.blends[index].weight_grams} g</span>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center", gap: "4px", justifyContent: "flex-end"}}>
                                            <input type="number" min={0} value={foBlend.weight_grams} onChange={(e) => updatedBatchDispatch({type: "update_fo_weight", name: foBlend.name, index: index, grams: e.target.value})} />
                                            <span>g</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        }
                        {recipe.wick_in_recipe && updatedBatch.wick_name &&
                        <div className="modal-edit-section" style={{borderBottom: "1px solid var(--ml-color-border-dark)", paddingBottom: "12px", marginBottom: "12px"}}>
                            <h3>Wick</h3>
                            <div className="modal-edit-section-multi-row-inputs">
                                <div className="modal-edit-section-multi-row-input">
                                    <div className="modal-edit-section-multi-row-name">{recipe.wick_name} →</div>
                                    <div style={{display: "flex", alignItems: "center", gap: "4px", justifyContent: "flex-end"}}>
                                        <input type="text" value={updatedBatch.wick_name} onChange={(e) => updatedBatchDispatch({type: "update", prop: "wick_name", value: e.target.value})} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        }

                        {recipe.wick_in_recipe && updatedBatch.wick_id && inventoryWicks.length > 0 &&
                        <div className="modal-edit-section" style={{borderBottom: "1px solid var(--ml-color-border-dark)", paddingBottom: "12px", marginBottom: "12px"}}>
                            <h3>Wick</h3>
                            <div className="modal-edit-section-multi-row-inputs">
                                <div className="modal-edit-section-multi-row-input">
                                    <div className="modal-edit-section-multi-row-name">{getSupplyNameById(globalState.supplies, recipe.wick_id)} →</div>
                                    <div style={{display: "flex", alignItems: "center", gap: "4px", justifyContent: "flex-end", width: "100%"}}>
                                        <div className="general-page-create-input-text" style={{width: "100%", marginTop: "0px"}}>
                                            <select value={updatedBatch.wick_id} onChange={(e) => updatedBatchDispatch({type: "update", prop: "wick_id", value: e.target.value})}>
                                                {inventoryWicks.map((inventoryWick) => (
                                                    <option key={inventoryWick._id} value={inventoryWick._id}>{inventoryWick.supply_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        <div className="modal-buttons">
                            {!isSaving && <span onClick={submitChanges}>Save</span>}
                        </div>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
