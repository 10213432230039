import { IoMdCloudy } from 'react-icons/io'
import { FaPaintBrush } from 'react-icons/fa'
import { MdOutlineAttachMoney, MdWaves } from 'react-icons/md'
import { BsFillHandThumbsDownFill,BsFillEmojiDizzyFill,  BsFillPatchExclamationFill, BsFillHandThumbsUpFill } from 'react-icons/bs'
import { parseISO, subDays, startOfDay, endOfDay, isWithinInterval } from 'date-fns'

export function numberToLetter(n) {
  let result = '';
  while (n > 0) {
    const remainder = (n - 1) % 26;
    result = String.fromCharCode(65 + remainder) + result;
    n = Math.floor((n - 1) / 26);
  }
  return result;
}

export function getUniqueWaxesFromRecipeList(recipes, supplyCloset) {
  try {
    const recipesWithSoloWax = recipes.filter(r => r.schema === 1)
    const recipesWithMaps = recipes.filter(r => r.schema !== 1)
    let soloWaxes = recipesWithSoloWax.map(obj => obj.wax);
    let waxMaps = recipesWithMaps.map(recipe => recipe.wax_blend_map.blends[0])
    let waxMapWaxNames = []
    for (let i = 0; i < waxMaps.length; i++) {
      const waxD = waxMaps[i];
      if (waxD.supply_id) {
        waxMapWaxNames.push(getSupplyNameById(supplyCloset, waxD.supply_id))
      } else if (waxD.name) {
        waxMapWaxNames.push(waxD.name)
      }
    }
    // const waxMapWaxNames = waxMaps.map(r => r.name )
    let allWaxes = soloWaxes.concat(waxMapWaxNames)
    let uniqueWaxes = allWaxes.filter((wax, index, self) => {
        return self.indexOf(wax) === index;
    });
    return uniqueWaxes.sort();
  } catch (error) {
    return []
  }

}

export function formatCurrencyUSD(totalPennies) {
  try {
    if (typeof totalPennies !== 'number') {
      throw new Error('Invalid input. The argument must be a number.');
    }
  
    // Round the total pennies to the nearest integer
    const roundedPennies = Math.round(totalPennies);
  
    // Convert the rounded pennies to dollars and cents
    const dollars = Math.floor(roundedPennies / 100);
    const cents = roundedPennies % 100;
  
    // Format the currency string
    const formattedDollars = dollars.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  
    const formattedCents = cents.toString().padStart(2, '0');
  
    return `${formattedDollars}.${formattedCents}`;
  } catch (error) {
    return 0
  }
}

export function getUniqueFragranceOilsFromRecipeList(recipes) {
  try {
    const recipesWithSoloFragrance = recipes.filter((recipe) => {
      if (recipe.fragrance_oil && recipe.fragrance_oil !== "") return true
      return false 
    })
    const recipesWithMaps = recipes.filter((recipe) => {
      if (!recipe.fragrance_oil_blend_map) return false
      if (!recipe.fragrance_oil_blend_map.blends) return false
      if (recipe.fragrance_oil_blend_map.blends[0]) return true
      return false
    })
    let soloFragrances = recipesWithSoloFragrance.map(obj => obj.fragrance_oil);
    let fragranceMaps = recipesWithMaps.map(recipe => recipe.fragrance_oil_blend_map.blends[0])
    let fragranceMapFragranceNames = []
    if (fragranceMaps) fragranceMapFragranceNames = fragranceMaps.map(r => r.name )
    let allFOs = soloFragrances.concat(fragranceMapFragranceNames)
    let uniqueFOs = allFOs.filter((fo, index, self) => {
        return self.indexOf(fo) === index;
    });
    let finalFilterFos = uniqueFOs.filter((fragranceName) => {
      if (!fragranceName) return false
      if (fragranceName === "false") return false
      return true
    })
    return finalFilterFos.sort();
  } catch (error) {
    return []
  }

  // return uniqueFOs.sort();
}

export function convertCandleTypeRawToDisplay(rawType)  {
  try {
    const typeConversion = {
      container: "Container",
      containerless: "Container-less",
      wax_melt: "Wax Melt"
    }
    return typeConversion[rawType]
  } catch (error) {
    console.error(error)
    return "General"
  }
}

export function getTotalWeightsOfBlend(blendItems) {
  // blendItems is an array of objects: [ { name: "name", weight: number } ]
  try {
      const blendsWithValidWeights = blendItems.filter((blend) => {
          if (isNaN(parseFloat(blend.weight))) return false
          return true
      })
      const allNumbers = blendsWithValidWeights.map(a => parseFloat(a.weight))
      const sum = allNumbers.reduce((partialSum, a) => partialSum + a, 0)
      return Math.round(sum)
  } catch (error) {
      return 0
  }
}

export function getRecipeWaxes (recipe, batch, supplies) {
  try {
      if (!recipe) return ""
      if (recipe.schema === 1) return recipe.wax
      if (!recipe.wax_blend_map)  return recipe.wax
      let waxBlends = recipe.wax_blend_map.blends
      if (batch) {
        if (batch.has_exceptions) {
          waxBlends = batch.exception_wax_blend_map.blends
        }
      }
      let namesArray = []
      for (let i = 0; i < waxBlends.length; i++) {
        const wax = waxBlends[i];
        if (wax.supply_id) {
          namesArray.push(getSupplyNameById(supplies, wax.supply_id))
        } else {
          namesArray.push(wax.name)
        }
      }
      return namesArray.toString() 
  } catch (error) {
    console.log(error)
      return ""
  }
}

export function getFragranceOilsFromRecipe (recipe, batch) {
  try {
    if (!recipe) return ""
    if (recipe.fragrance_oil)
    if (recipe.fragrance_oil && recipe.fragrance_oil !== "false") return recipe.fragrance_oil
    let foBlends = recipe.fragrance_oil_blend_map.blends
    if (batch.has_exceptions) {
      foBlends = batch.exception_fragrance_oil_blend_map.blends
    }
    const foNameArray = foBlends.map(w => w.name)
    return foNameArray.toString() 
  } catch (error) {
      return ""
  }
}

export function getBatchWicks(batch, recipe) {
  try {
    if (recipe.wick_in_recipe && recipe.wick_name && !batch.has_exceptions) return recipe.wick_name
    if (!recipe.wick_in_recipe) return batch.wicks.toString()
    if (batch.has_exceptions && batch.exception_wick_name) return batch.exception_wick_name
    return recipe.wick_name
  } catch (error) {
    return ""
  }
}

export function getPrimaryDomain(url) {
  try {
    const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)(\/.*)?$/;
    const match = url.match(regex);
    if (match) return match[3]
    return false
  } catch (error) {
    return false
  }
}

export function getSupplyNameById(supplyCloset, supplyId) {
  try {
    const supply = supplyCloset.find(s => s._id === supplyId)
    if (!supply) return false
    if (!supply.supply_name) return false
    return supply.supply_name
  } catch (error) {
    return false
  }
}

export function gramsToOuncesString(grams) {
  const ounces = grams * 0.03527396;
  const pounds = Math.floor(ounces / 16);
  const remainingOunces = ounces % 16;

  if (ounces > 20) {
    return `${pounds} lb ${remainingOunces.toFixed(2)} oz`;
  } else {
    return `${ounces.toFixed(2)} oz`;
  }
}

export function convertRawOuncesToRawGrams(ounces) {
  try {
    const grams = parseFloat(ounces * 28.3495).toFixed(0)
    return grams
  } catch (error) {
    return ounces
  }
}

export function capitalizeFirstWord(word) {
  try {
    const asLowerCase = word.toLowerCase()
    return word.charAt(0).toUpperCase() + asLowerCase.slice(1)
  } catch (error) {
    return word
  }
}

export function getBadgesStringArray() {
  return [
    "pass",
    "fail",
    "sold",
    "good_color",
    "hot_throw",
    "tunneling",
    "unsafe",
  ]
}

export function sortByProperty(arr, propName) {
  try {
      return arr.sort((a, b) => {
      if (a[propName] < b[propName]) return -1;
      if (a[propName] > b[propName]) return 1;
      return 0;
  });
  } catch (error) {
    return arr
  }

}

export const pageOptions = {
  wax: [
    {id: "Paraffin", display: "Paraffin"},
    {id: "Soy", display: "Soy"},
    {id: "Parasoy", display: "Parasoy"},
    {id: "Palm", display: "Palm"},
    {id: "Coconut", display: "Coconut"},
    {id: "Rapeseed", display: "Rapeseed"},
  ],
  types: [
      {id: "container_supply", display: "Select from inventory"},
      {id: "container", display: "New vessel"},
      // {id: "containerless", display: "Containerless"},
      // {id: "wax_melt", display: "Wax melt"},
  ],
  wick_in_recipe: [true, false]
}

const badgeData = {
  hot_throw: {
      symbol: <MdWaves/>,
      color: "#2ea7bf",
  },
  unsafe: { 
      symbol: <BsFillEmojiDizzyFill/>,
      color: "#FFC107",
  },
  good_color: {
      symbol: <FaPaintBrush/>,
      color: "#c86ad5",
  },
  tunneling: {
      symbol: <BsFillPatchExclamationFill/>,
      color: "#bf9b9b",
  },
  fail: {
      symbol: <BsFillHandThumbsDownFill/>,
      color: "#c13030",
  },
  pass: {
      symbol: <BsFillHandThumbsUpFill/>,
      color: "#114469",
  },
  sold: {
      symbol: <MdOutlineAttachMoney/>,
      color: "#116921",
  }
}

export function getBadgeIcon(badgeName) {
  if (badgeName in badgeData) return badgeData[badgeName].symbol
  return <IoMdCloudy/>
}

export function formatNumber(num) {
  try {
    if (num === 0) {
      return 0;
    } else if (num < 100) {
        return num.toString();
    } else if (num < 1000) {
        return num.toLocaleString();
    } else if (num < 1000000) {
        return (Math.round(num / 100) / 10).toFixed(1) + 'k';
    } else if (num < 1000000000) {
        return (Math.round(num / 100000) / 10).toFixed(1) + 'M';
    }
    return false
  } catch (error) {
    return false
  }
}

export function convertBadgeName(badgeName) {
  if (badgeName === "hot_throw") return "Strong Hot Throw"
  if (badgeName === "unsafe") return "Unsafe"
  if (badgeName === "good_color") return "Good Color"
  if (badgeName === "tunneling") return "Bad Behavior"
  if (badgeName === "fail") return "Fail"
  if (badgeName === "pass") return "Pass"
  if (badgeName === "sold") return "Sold"
  return "Happy"
}

export function getBadgeColorFromName(badgeName) {
  try {
    return badgeData[badgeName].color
  } catch (error) {
    return "grey"
  }
}

export function capitalizeFirstLetter(string) {
  try {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } catch (error) {
    return string
  }
}

export function getWaxSchema2 (recipe, supplyCloset) {
  try {
    // Schema 1 recipes
    if (!recipe.wax_blend_map && recipe.wax) return <div className="recipe-ingredient-value">{recipe.wax}</div>

    // Recipes without a supply closet
    if (!supplyCloset || supplyCloset.length === 0) {
      return (
        <div className="recipe-ingredient-value recipe-ingredient-value-multi">
            {recipe.wax_blend_map.blends.map((blend) => (
                <span key={blend.name}>{blend.name} {recipe.wax_blend_map.blends.length > 1 && <span style={{color: "rgb(58, 58, 58)", fontSize: "14px", marginLeft: "8px"}}>{blend.weight}%</span>}</span>
            ))}
        </div>
        )
    }
    return (
      <div className="recipe-ingredient-value recipe-ingredient-value-multi">
        {recipe.wax_blend_map.blends.map((blend) => (
          <div key={blend.name}>
          {blend.supply_id && <span key={blend.supply_id} className="util-row util-justify-end util-align-center">  <a href={`/candles/inventory/supplies/${blend.supply_id}`} style={{textDecoration: "none"}}><div className="recipe-ingredient-value general-supply-clickable">{getSupplyNameById(supplyCloset, blend.supply_id)}</div></a>   {recipe.wax_blend_map.blends.length > 1 && <span style={{color: "rgb(58, 58, 58)", fontSize: "14px", marginLeft: "8px"}}>{blend.weight}%</span>}</span> }
          {!blend.supply_id && <span key={blend.name}>{blend.name} {recipe.wax_blend_map.blends.length > 1 && <span style={{color: "rgb(58, 58, 58)", fontSize: "14px", marginLeft: "8px"}}>{blend.weight}%</span>}</span> }
          </div>
        ))}
      </div>
    )
    
  } catch (error) {
    return <div className="recipe-ingredient-value">Loading...</div>
  }        
}

export function getRecipeWaxArray(recipe) {
  try {
    if (recipe.wax_blend_map) {
      const blends = recipe.wax_blend_map.blends
      let waxArray = []
      for (let i = 0; i < blends.length; i++) {
        let proposedWaxData = { name: blends[i].name, weight: blends[i].weight }
        if ('supply_id' in blends[i]) proposedWaxData['supply_id'] = blends[i]['supply_id']
        waxArray.push(proposedWaxData)
        // waxArray.push({ name: blends[i].name, weight: blends[i].weight })
      }
      return waxArray
    }
    return [{name: recipe.wax, weight: 100, supply_id: false }]
  } catch (error) {
    return []
  }
}

export function getRecipeFOArray(recipe) {
  try {
    if (recipe.fragrance_oil_blend_map) {
      const blends = recipe.fragrance_oil_blend_map.blends
      let foArray = []
      for (let i = 0; i < blends.length; i++) {
        let proposedWaxData = { name: blends[i].name, weight: blends[i].weight }
        if ('supply_id' in blends[i]) proposedWaxData['supply_id'] = blends[i]['supply_id']
        foArray.push(proposedWaxData)
        // foArray.push({ name: blends[i].name, weight: blends[i].weight })
      }
      return foArray
    }
    return [{name: recipe.fragrance_oil, weight: 100, supply_id: false}]
  } catch (error) {
    return []
  }
}

export function getFOSchema2 (recipe, supplyCloset) {
  try {
    // Legacy, non-blend map recipe
    if (!recipe.fragrance_oil_blend_map && recipe.fragrance_oil) return <div className="recipe-ingredient-value">{recipe.fragrance_oil}</div>
    if (!supplyCloset || supplyCloset.length === 0) {
      return (
          <div className="recipe-ingredient-value recipe-ingredient-value-multi">
              {recipe.fragrance_oil_blend_map.blends.map((blend) => (
                  <span key={blend.name}>{blend.name} {recipe.fragrance_oil_blend_map.blends.length > 1 && <span style={{color: "rgb(58, 58, 58)", fontSize: "14px", marginLeft: "8px"}}>{blend.weight}%</span>}</span>
              ))}
          </div>
          )
    }
    return (
      <div className="recipe-ingredient-value recipe-ingredient-value-multi">
        {recipe.fragrance_oil_blend_map.blends.map((blend) => (
          <div key={blend.name}>
          {blend.supply_id && <span key={blend.supply_id} className="util-row util-justify-end util-align-center">  <a href={`/candles/inventory/supplies/${blend.supply_id}`} style={{textDecoration: "none"}}><div className="recipe-ingredient-value general-supply-clickable">{getSupplyNameById(supplyCloset, blend.supply_id)}</div></a>   {recipe.fragrance_oil_blend_map.blends.length > 1 && <span style={{color: "rgb(58, 58, 58)", fontSize: "14px", marginLeft: "8px"}}>{blend.weight}%</span>}</span> }
          {!blend.supply_id && <span key={blend.name}>{blend.name} {recipe.fragrance_oil_blend_map.blends.length > 1 && <span style={{color: "rgb(58, 58, 58)", fontSize: "14px", marginLeft: "8px"}}>{blend.weight}%</span>}</span> }
          </div>
        ))}
      </div>
    )
  } catch (error) {
      return <div className="recipe-ingredient-value">Loading...</div>
  }        
}

export function getColorObject() {
  return {
    default: "#FFFFFF",
    antique_white: "#FFEDD8",
    light_orange: "#F3D5B5",
    tan: "#E7BC91",
    buff: "#D4A276",
    platinum: "#D8E2DC",
    champagne_pink: "#FFE5D9",
    pink: "#FFCAD4",
    cherry_blossom: "#F4ACB7",
    french_gray: "#CABABE",
    pink_lavender: "#F1C0E8",
    mauve: "#CFBAF0",
    jordy_blue: "#A3C4F3",
    non_photo_blue: "#90DBF4",
    electric_blue: "#8EECF5",
    aquamarine: "#98F5E1",
    celadon: "#B9FBC0",
    lemon_chiffon: "#FBF8CC",
    honeydew: "#CFDED0",
    ash_gray: "#ACC3AF",
    green_celadon: "#A1CCA5",
    cambridge_green: "#8FB996",
  }
}

export function getBackgroundHexFromName(colorName, isShaded) {
  try {
    const colorObject = getColorObject()
    let color = colorObject['default']
    if (colorName in colorObject) color = colorObject[colorName]
    if (!isShaded) return color
    return `${color}75`
  } catch (error) {
    return getColorObject()['default']
  }
}

export function getAdditionalCardListStyles(length) {
  try {
      if (length < 3) return { flexDirection: "column", padding: "0" }
      return { padding: "0" }
  } catch (error) {
      return { padding: "0" }
  }
}

export const standardCardSections = [
  {
      name: "Today",
      subDaysFromTodayStart: 0, // picks the beginning of the day that comes from this calculation
      subDaysFromTodayEnd: 0, // picks the end of the day that comes from this calculation
  },
  {
      name: "Yesterday",
      subDaysFromTodayStart: 1, 
      subDaysFromTodayEnd: 1,
  },
  {
      name: "Previous 7 Days",
      subDaysFromTodayStart: 7, 
      subDaysFromTodayEnd: 2,
  },
  {
      name: "Previous 2 Weeks",
      subDaysFromTodayStart: 14, 
      subDaysFromTodayEnd: 8,
  },
  {
      name: "Previous 30 Days",
      subDaysFromTodayStart: 30, 
      subDaysFromTodayEnd: 15,
  },
  {
      name: "Older",
      subDaysFromTodayStart: 1000, 
      subDaysFromTodayEnd: 31,
  }
]

export function getCardsBySection(cardsData, section, dateAttributeName) {
  try {
    if (!cardsData || cardsData.length === 0) return []
      const calcStartDay = subDays(new Date(), section.subDaysFromTodayStart)
      const startPeriod = startOfDay(calcStartDay)
      const calcEndDay = subDays(new Date(), section.subDaysFromTodayEnd)
      const endPeriod = endOfDay(calcEndDay)
      const filteredCards = cardsData.filter((cardData) => {
          if (isWithinInterval(parseISO(cardData[dateAttributeName]), {
              start: startPeriod,
              end: endPeriod
          })) {
              return true
          }
          return false
      })
      return filteredCards
  } catch (error) {
    return []
  }
}

export function getSupplyById(supplyId, supplyCloset) {
  try {
    const supply = supplyCloset.find(s => s._id === supplyId)
    if (!supply) return false
    return supply
  } catch (error) {
    return false
  }
}

/**
 * Updates a specific key in the user settings stored in localStorage
 * Only updates the specified setting without overwriting the entire object
 * 
 * @param {string} key - The key of the setting to update
 * @param {any} value - The new value for the setting
 * @returns {Object} - The updated complete user settings object
 */
export function updateUserSettingInLocalStorage(key, value) {
  try {
    // Get current settings from localStorage
    const currentSettingsStr = localStorage.getItem('moonliteUserSettings');
    let currentSettings = {};
    
    // Parse existing settings if they exist
    if (currentSettingsStr) {
      currentSettings = JSON.parse(currentSettingsStr);
    }
    
    // Update only the specified key
    const updatedSettings = {
      ...currentSettings,
      [key]: value
    };
    
    // Save back to localStorage
    localStorage.setItem('moonliteUserSettings', JSON.stringify(updatedSettings));
    
    return updatedSettings;
  } catch (error) {
    console.error('Error updating user setting in localStorage:', error);
    return null;
  }
}

/**
 * Formats a monetary value based on the specified currency type with proper locale formatting
 * 
 * @param {number} totalPennies - The monetary value in pennies/cents
 * @param {string} currencyType - The currency type ('dollar', 'euro', or 'pound')
 * @returns {string} - The formatted currency string
 */
export function formatCurrencyByType(totalPennies, currencyType = 'dollar') {
  try {
    if (typeof totalPennies !== 'number') {
      return '0.00';
    }
    
    // Map currency type to format options
    const currencyFormats = {
      'dollar': { currency: 'USD', locale: 'en-US' },
      'euro': { currency: 'EUR', locale: 'de-DE' }, // Using German locale for Euro (comma as decimal separator)
      'pound': { currency: 'GBP', locale: 'en-GB' }
    };
    
    // Get format for the selected currency type, defaulting to USD
    const format = currencyFormats[currencyType] || currencyFormats.dollar;
    
    // Convert pennies to decimal value (e.g., 1234 pennies → 12.34)
    const decimalValue = totalPennies / 100;
    
    // Format the value using locale-specific formatting
    return new Intl.NumberFormat(format.locale, {
      style: 'currency',
      currency: format.currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(decimalValue);
  } catch (error) {
    console.error('Error formatting currency:', error);
    return '0.00';
  }
}

/**
 * Gets a user-friendly label for the specified currency type
 * 
 * @param {string} currencyType - The currency type ('dollar', 'euro', or 'pound')
 * @returns {string} - The currency label (e.g., 'USD', 'EUR', 'GBP')
 */
export function getCurrencyLabel(currencyType = 'dollar') {
  const labels = {
    'dollar': 'USD',
    'euro': 'EUR',
    'pound': 'GBP'
  };
  
  return labels[currencyType] || 'USD';
}

/**
 * Gets the currency symbol for the specified currency type
 * 
 * @param {string} currencyType - The currency type ('dollar', 'euro', or 'pound')
 * @returns {string} - The currency symbol
 */
export function getCurrencySymbol(currencyType = 'dollar') {
  const symbols = {
    'dollar': '$',
    'euro': '€',
    'pound': '£'
  };
  
  return symbols[currencyType] || '$';
}