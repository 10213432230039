import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Landings.css';
import { FaRegClock, FaChartLine, FaRegObjectGroup, FaPlay } from 'react-icons/fa';
import { BsCalculator, BsListCheck, BsCheck2 } from 'react-icons/bs';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { loginWithGoogle } from '../../common/Armstrong';

// Import images
const moonliteBatchPage = "https://moonlite-public.s3.us-east-2.amazonaws.com/landing/2025+03+05+MoonLite+Batch+Screen.png";

// Feature section images from Login.jsx
const featureImages = {
  recipes: "https://moonlite-public.s3.us-east-2.amazonaws.com/landing/2025+02+17+MoonLite+Recipes+Page.png",
  calculator: "https://moonlite-public.s3.us-east-2.amazonaws.com/landing/2025+02+17+MoonLite+Batch+Calculations.png",
  production: "https://moonlite-public.s3.us-east-2.amazonaws.com/landing/2025+02+17+MoonLite+Production+Notes.png",
  cureTimer: "https://moonlite-public.s3.us-east-2.amazonaws.com/landing/2025+02+17+MoonLite+Cure+Email+Notification.png",
  tasks: "https://moonlite-public.s3.us-east-2.amazonaws.com/landing/2025+02+17+MoonLite+Tasks.png"
};

// Reusable Components
const CTAButton = ({ primary, text, onClick, icon, large, disabled }) => {
  return (
    <button 
      className={`landing-cta-button ${primary ? 'landing-cta-primary' : 'landing-cta-secondary'} ${large ? 'landing-cta-large' : ''}`} 
      onClick={onClick}
      disabled={disabled}
    >
      {icon && icon}
      {text}
    </button>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="landing-feature-card">
      <div className="landing-feature-icon">{icon}</div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

const TestimonialCard = ({ quote, name, role, image }) => {
  return (
    <div className="landing-testimonial">
      <p>"{quote}"</p>
      <div className="landing-testimonial-author">
        {image && <img src={image} alt={name} />}
        <span>{name}{role && `, ${role}`}</span>
      </div>
    </div>
  );
};

const PricingCard = ({ title, price, description, features, featured, badge, onClick }) => {
  return (
    <div className={`landing-pricing-card ${featured ? 'landing-pricing-card-featured' : ''}`}>
      {badge && <div className="landing-pricing-card-badge">{badge}</div>}
      <h3>{title}</h3>
      <div className="landing-pricing-amount">
        <span className="landing-pricing-currency">$</span>
        <span className="landing-pricing-number">{price}</span>
        <span className="landing-pricing-period">/mo</span>
      </div>
      <p className="landing-pricing-description">{description}</p>
      <ul className="landing-pricing-features">
        {features.map((feature, index) => (
          <li key={index}>
            <BsCheck2 /> {feature}
          </li>
        ))}
      </ul>
      <div className="landing-pricing-button-container">
        {onClick ? (
          <CTAButton primary={featured} text="Get Started" onClick={onClick} />
        ) : (
          <CTAButton primary={featured} text="Coming Soon" onClick={null} disabled={true} />
        )}
      </div>
    </div>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  
    return (
    <div className="landing-faq-item">
      <div className="landing-faq-question" onClick={() => setIsOpen(!isOpen)}>
        <h4>{question}</h4>
        <div className="landing-faq-toggle">
          {isOpen ? <MdExpandLess /> : <MdExpandMore />}
        </div>
      </div>
      {isOpen && (
        <div className="landing-faq-answer">
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

// Main Landing Component
function LandingMain({globalState, dispatch}) {
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  useEffect(() => {
    if (globalState.authenticated) return navigate('/home')
    // eslint-disable-next-line
    }, [globalState])

  const handleSubscribe = () => {
    loginWithGoogle()
  };
  
  const handleWatchVideo = () => {
    setShowVideo(true);
  };
  
  const handleImageClick = (imageSrc) => {
    setCurrentImage(imageSrc);
    setShowImageModal(true);
  };
  
  return (
    <div className="landing-container">
      {/* Hero Section */}
      <section className="landing-hero">
        <div className="landing-hero-content">
          <h1>Organize your candle business</h1>
          <p className="landing-hero-subtitle">
            Easily manage your candle recipes, batches, tests, and supplies. Spend less time on the boring stuff, and more time pouring. 
          </p>
          <div className="landing-hero-cta">
            <CTAButton 
              primary 
              text="Get started" 
              onClick={handleSubscribe} 
              large
            />
            <CTAButton 
              text="Watch demo" 
              onClick={handleWatchVideo}
            />
          </div>
          <p className="landing-hero-note">A Google account is required to use MoonLite</p>
        </div>
        <div className="landing-hero-image" onClick={handleWatchVideo}>
          <img src={moonliteBatchPage} alt="MoonLite candle recipe dashboard" />
          <div className="landing-hero-image-overlay">
            <div className="landing-hero-play-button">
              <div className="landing-hero-play-icon">
                <FaPlay />
              </div>
              <span>See how it works</span>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="landing-testimonials">
        <div className="landing-testimonials-content">
          <h2>Trusted by successful candle makers everywhere</h2>
          <div className="landing-testimonials-grid">
            <TestimonialCard 
              quote="MoonLite is absolutely a gift, thank you so much. It made my candle work much easier and safer. I don't get wastage now."
              name="Usha Sivan"
              role="Candle Maker"
            />
            <TestimonialCard 
              quote="This is exactly what I have been looking for and I know it will be extremely beneficial to my business and other candle makers too!"
              name="Pour & Penchant"
              role="Artisan Business"
            />
            <TestimonialCard 
              quote="Thank you for such a great product. It will make life a lot easier."
              name="Melonie Evans"
              role="Candle Enthusiast"
            />
          </div>
        </div>
        <div className="landing-social-proof-content">
          <div className="landing-stats-grid">
            <div className="landing-stat-item">
              <div className="landing-stat-number">2,800+</div>
              <div className="landing-stat-label">Candle Recipes</div>
            </div>
            <div className="landing-stat-item">
              <div className="landing-stat-number">3,000+</div>
              <div className="landing-stat-label">Batches Deployed</div>
            </div>
            <div className="landing-stat-item">
              <div className="landing-stat-number">20,000+</div>
              <div className="landing-stat-label">Candles Created</div>
            </div>
          </div>
        </div>
      </section>

      {/* Pain Points Section */}
      <section className="landing-pain-points">
        <div className="landing-pain-points-content">
          <h2>Automate the busy work</h2>
          <div className="landing-pain-grid">
            <div className="landing-pain-item">
              <h3>Without MoonLite</h3>
              <ul>
                <li>Recipes scattered across spreadsheets and notebooks</li>
                <li>Forgetting which batches need attention on which days</li>
                <li>Manually calculating supplies needed for each batch</li>
                <li>Struggling to keep track of testing results</li>
              </ul>
            </div>
            <div className="landing-pain-item landing-solution">
              <h3>With MoonLite</h3>
              <ul>
                <li>All your recipes and designs in one organized place</li>
                <li>Automatic reminders for cure times and batch readiness</li>
                <li>Built-in calculator for precise supply requirements</li>
                <li>Structured production & batch notes for quality control</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="landing-features">
        <div className="landing-features-content">
          <h2>Everything you need for professional candle making</h2>
          <div className="landing-features-grid">
            <FeatureCard 
              icon={<FaRegObjectGroup />}
              title="Recipe Manager"
              description="Create and save your candle recipes in one place. Track waxes, wicks, fragrances, colors, and containers."
            />
            <FeatureCard 
              icon={<BsCalculator />}
              title="Batch Management"
              description="Automatically calculate the exact amounts of each ingredient needed for any batch size."
            />
            <FeatureCard 
              icon={<FaRegClock />}
              title="Cure Timers"
              description="Track curing times for each batch and receive notifications when candles are ready."
            />
            <FeatureCard 
              icon={<BsListCheck />}
              title="Task Manager"
              description="Stay organized with built-in to-do lists specifically designed for candle production workflows."
            />
            <FeatureCard 
              icon={<FaChartLine />}
              title="Test Suite"
              description="Coming soon! Record burn tests and track which recipes perform best with customers."
            />
            <FeatureCard 
              icon={<IoMdInformationCircleOutline />}
              title="Candle History"
              description="Easily organize every batch and track the full history from the poured to packaged."
            />
          </div>
          <p style={{textAlign: "center", marginTop: "20px", fontWeight: 600, marginBottom: 0}}>And so much more!</p>
        </div>
      </section>

      {/* Feature Showcase Sections */}
      <div className="landing-feature-section">
        <div className="landing-feature-section-content">
          <h3>One Place for Your Designs</h3>
          <p>Store all your candle recipes in a single organized location. Never lose track of your best-selling formulas again.</p>
        </div>
        <div className="landing-feature-section-image">
          <img 
            src={featureImages.recipes} 
            alt="Candle recipe management interface" 
            onClick={() => handleImageClick(featureImages.recipes)}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>

      <div className="landing-feature-section">
        <div className="landing-feature-section-content">
          <h3>Built-in Calculator</h3>
          <p>Our smart calculator determines exactly how much of each material you need for any batch size, eliminating waste and saving money.</p>
        </div>
        <div className="landing-feature-section-image">
          <img 
            src={featureImages.calculator} 
            alt="Supply calculation interface" 
            onClick={() => handleImageClick(featureImages.calculator)}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>

      <div className="landing-feature-section">
        <div className="landing-feature-section-content">
          <h3>Run an Efficient Workshop</h3>
          <p>Track every aspect of your production with detailed notes and logs. Create consistent, high-quality candles every time.</p>
        </div>
        <div className="landing-feature-section-image">
          <img 
            src={featureImages.production} 
            alt="Production notes interface" 
            onClick={() => handleImageClick(featureImages.production)}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>

      <div className="landing-feature-section">
        <div className="landing-feature-section-content">
          <h3>Cure Timers</h3>
          <p>Set cure time reminders for any batch and get an email when it's ready. Choose a set of days or a custom notification.</p>
        </div>
        <div className="landing-feature-section-image">
          <img 
            src={featureImages.cureTimer} 
            alt="Cure timer notification interface" 
            onClick={() => handleImageClick(featureImages.cureTimer)}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>

      <div className="landing-feature-section">
        <div className="landing-feature-section-content">
          <h3>Manage Your To-Do List</h3>
          <p>Never forget when a test is over or when to re-order supplies. Run your candle business with extreme efficiency in a single place.</p>
        </div>
        <div className="landing-feature-section-image">
          <img 
            src={featureImages.tasks} 
            alt="Task management interface" 
            onClick={() => handleImageClick(featureImages.tasks)}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>

      {/* Pricing Section */}
      <section className="landing-pricing">
        <div className="landing-pricing-content">
          <h2>Simple, transparent pricing</h2>
          <p>Choose the plan that works best for your candle making business</p>
          <div className="landing-pricing-grid">
            <PricingCard 
              title="Basic"
              price={12}
              description="Perfect for hobbyists and small-batch makers"
              features={[
                "Up to 50 recipes",
                "Basic inventory tracking",
                "Recipe management",
                "Candle making calculator",
                "COGS reporting",
                "Batch stage tracking",
                "Mobile-ready access",
              ]}
              onClick={handleSubscribe}
            />
            <PricingCard 
              title="Pro"
              price={29}
              description="For serious candle makers and growing businesses"
              features={[
                "Everything in Basic",
                "Unlimited recipes",
                "Advanced inventory management",
                "Attach images to candle tests",
                "Candle testing lab",
                "Priority email support"
              ]}
              featured={true}
              badge="Coming Soon"
              onClick={null}
            />
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="landing-faq">
        <div className="landing-faq-content">
          <div className="landing-faq-two-column">
            <div className="landing-faq-heading">
              <h2>Frequently Asked Questions</h2>
              <p>Got questions about MoonLite? We've got answers to help you get started.</p>
            </div>
            <div className="landing-faq-questions">
              <div className="landing-faq-grid">
                <FAQItem 
                  question="What does MoonLite do?"
                  answer="MoonLite helps busy candle makers record and organize every design, batch, candle, and collection. It automatically creates IDs and gives you a simple place to take notes and test new designs."
                />
                <FAQItem 
                  question="Why should I use it?"
                  answer="MoonLite cuts down on complex spreadsheets and assorted notes everywhere for your designs and tests by making it easy to create, test, and record your candle making operations."
                />
                <FAQItem 
                  question="Does MoonLite calculate how much materials I need for a batch?"
                  answer="Yes! MoonLite's built-in calculator automatically determines the exact amounts of wax, fragrance oil, dye, and other materials needed for any batch size you specify."
                />
                <FAQItem 
                  question="What kind of waxes and fragrance oils does MoonLite have?"
                  answer="Technically, all of them! You get to name the wax or fragrance oil you're using."
                />
                <FAQItem 
                  question="Can I mix multiple waxes or fragrance oils in one recipe?"
                  answer="Yes! Mix and match your swanky designs as much as your heart desires to find that perfect blend."
                />
                <FAQItem 
                  question="Where can I use MoonLite?"
                  answer="Anywhere with internet! MoonLite is a mobile-friendly website so you can bring your workshop on the go. Or, open the site on your personal computer."
                />
                <FAQItem 
                  question="Will there be an app?"
                  answer="Right now MoonLite is only available as a mobile and desktop-friendly website, but we plan on building apps for iOS and Android someday."
                />
                <FAQItem 
                  question="How much does MoonLite cost?"
                  answer="MoonLite is a monthly or annual subscription that you can cancel at any time."
                />
                <FAQItem 
                  question="Is there a free version available?"
                  answer="Currently, MoonLite is a premium product available through our subscription plans only. We focus on delivering professional-grade tools that provide real value to candle makers."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section className="landing-final-cta">
        <div className="landing-final-cta-content">
          <h2>Ready to transform your candle business?</h2>
          <p>Join candle makers worldwide who are streamlining their production work with MoonLite</p>
          <CTAButton 
            primary 
            text="Get Started Today" 
            onClick={handleSubscribe} 
            large
          />
        </div>
      </section>

      {/* Video Popup */}
      {showVideo && (
        <div className="landing-video-popup-overlay" onClick={() => setShowVideo(false)}>
          <div className="landing-video-popup-content" onClick={e => e.stopPropagation()}>
            <button className="landing-video-popup-close" onClick={() => setShowVideo(false)}>
              <AiOutlineClose />
            </button>
            <iframe 
              width="800" 
              height="450" 
              src="https://www.youtube.com/embed/7LjY-vyMiEQ?si=lY1JPADr6XWQy-GW&autoplay=1" 
              title="MoonLite Demo Video" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}

      {/* Image Modal */}
      {showImageModal && (
        <div className="landing-image-modal-overlay" onClick={() => setShowImageModal(false)}>
          <div className="landing-image-modal-content" onClick={e => e.stopPropagation()}>
            <button className="landing-image-modal-close" onClick={() => setShowImageModal(false)}>
              <AiOutlineClose />
            </button>
            <img 
              src={currentImage} 
              alt="Feature detail" 
              className="landing-image-modal-img"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default LandingMain;
