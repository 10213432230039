import React from 'react'
import { useState, useEffect } from 'react'
import { MdDocumentScanner, MdFilterList } from 'react-icons/md'
import { HiOutlinePlus } from 'react-icons/hi2'
import PrimaryRecipeCard from './PrimaryRecipeCard'
import PreviewRecipeCard from './PreviewRecipeCard'
import RecipePreviewPane from './RecipePreviewPane'
import CollectionCard from '../collections/CollectionCard'
import RecipesTable from './RecipesTable'
import { useNavigate, useSearchParams  } from 'react-router-dom'
import { parseISO } from 'date-fns'
import GeneralPageSearchBar from '../gadgets/GeneralPageSearchBar'
import LoadingSymbol from '../misc/LoadingSymbol'
import InternalNavigationSections from '../navigation/InternalNavigationSections'
import CollapsibleListSection from '../gadgets/CollapsibleListSection'
import ModalNewCollection from '../modals/ModalNewCollection'
import ModalFilter from '../modals/ModalFilter'
import Api from '../../common/APIUtils'
import { getRecipeWaxes, standardCardSections, getCardsBySection, getSupplyNameById } from '../../common/Helpers'
import { useSubscription } from '../../context/SubscriptionContext'

// Constants
const ITEMS_PER_PAGE = 50;

export default function Recipes({globalState, dispatch, fetchUserLibrary}) {
    const api = new Api()
    const { planName } = useSubscription()
    const [query, setQuery] = useState(() => { return "" })
    const [recipes, setRecipes] = useState(() => { return [] })
    const [favoriteRecipes, setFavoriteRecipes] = useState(() => { return [] })
    const [filter, setFilter] = useState(() => { return {} })
    const [selectedPreviewRecipe, setSelectedPreviewRecipe] = useState(() => { return false })
    const [selectedPreviewRecipeFavorite, setSelectedPreviewRecipeFavorite] = useState(() => { return false })
    const [collectionsQuery, setCollectionsQuery] = useState(() => { return "" })
    const [favoritesQuery, setFavoritesQuery] = useState(() => { return "" })
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const [isShowingFilterModal, setIsShowingFilterModal] = useState(() => { return false })
    const [isShowingNewCollectionModal, setIsShowingNewCollectionModal] = useState(() => { return false })
    const [view, setView] = useState(() => { return "all" })
    
    // New state variables for direct API fetching
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pagination, setPagination] = useState({
        total: 0,
        current_page: 1,
        total_pages: 1,
        has_next: false,
        has_prev: false
    });
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [allRecipes, setAllRecipes] = useState([]); // Store all recipes for local operations
    const [apiFilters, setApiFilters] = useState({
        search: '',
        sortBy: 'updatedAt',
        sortOrder: 'desc'
    });
    
    const navigate = useNavigate()
    
    // Fetch recipes with filters
    const fetchRecipes = async () => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams({
                page,
                limit: ITEMS_PER_PAGE,
                ...(apiFilters.search && { search: apiFilters.search }),
                sort_by: apiFilters.sortBy || 'updatedAt',
                sort_order: apiFilters.sortOrder || 'desc',
                ...(selectedCollections.length > 0 && { collection_ids: selectedCollections.join(',') }),
                ...(apiFilters.waxes && { waxes: apiFilters.waxes }),
                ...(apiFilters.fragrances && { fragrances: apiFilters.fragrances }),
                ...(apiFilters.wick_counts && { wick_counts: apiFilters.wick_counts }),
                ...(apiFilters.min_fragrance_load && { min_fragrance_load: apiFilters.min_fragrance_load })
            }).toString();

            console.log('Fetching recipes with params:', queryParams);
            const response = await api.getRecipesWithFilters(queryParams);
            
            if (!response || !response.data) {
                throw new Error('Invalid response format');
            }

            const totalRecords = response.data.total_records || 0;
            setTotalRecords(totalRecords);

            // If total records <= 50, store all recipes for local operations
            if (totalRecords <= ITEMS_PER_PAGE) {
                setAllRecipes(response.data.recipes || []);
            } else {
                setAllRecipes([]); // Clear local cache for large datasets
            }

            setRecipes(response.data.recipes || []);
            setPagination(response.data.pagination || {
                total: 0,
                current_page: 1,
                total_pages: 1,
                has_next: false,
                has_prev: false
            });
            setLoading(false);
        } catch (err) {
            console.error('Error fetching recipes:', err);
            setLoading(false);
            setRecipes([]);
        }
    };

    const getCurrentRecipes = () => {
        try {
            if (!globalState.recipes) return []
            let filteredRecipes = globalState.recipes
            if (Object.keys(filter).length > 0) {
                filteredRecipes = globalState.recipes.filter((recipe) => {
                    // waxes, fragrances, wicks, fragranceLoad --- can match ANY of them
                    if ('waxes' in filter) {
                        const waxNames = filter.waxes
                        if (waxNames.includes(recipe.wax)) return true
                        if (recipe.wax) {
                            if (recipe.wax_blend_map) {
                                if (recipe.wax_blend_map.blends) {
                                    if (recipe.wax_blend_map.blends[0]) {
                                        const recipeWaxes = recipe.wax_blend_map.blends.map(r => r.name)
                                        let filterWaxSet = new Set(waxNames)
                                        if (recipeWaxes.some(item => filterWaxSet.has(item))) return true
                                    }
                                }
                            }
                            
                        }
                    }

                    if ('fragrances' in filter) {
                        const fragranceNames = filter.fragrances
                        if (fragranceNames.includes(recipe.fragrance_oil)) return true
                        if (!recipe.fragrance_oil) {
                            if (recipe.fragrance_oil_blend_map) {
                                if (recipe.fragrance_oil_blend_map.blends) {
                                    if (recipe.fragrance_oil_blend_map.blends[0]) {
                                        const recipeFragrances = recipe.fragrance_oil_blend_map.blends.map(r => r.name)
                                        let filterFragranceSet = new Set(fragranceNames)
                                        if (recipeFragrances.some(item => filterFragranceSet.has(item))) return true
                                    }
                                }
                            }
                        }
                    }

                    if ('wicks' in filter) {
                        // wicks is actually wick count
                        if (filter.wicks.includes(recipe.wick_count)) return true
                    }

                    if ('fragranceLoad' in filter) {
                        if (filter.fragranceLoad === 0) {
                            if (recipe.fragrance_load === 0) return true
                        }
                        if (filter.fragranceLoad > 0) {
                            if (parseFloat(recipe.fragrance_load) >= parseFloat(filter.fragrance_load)) return true
                        }
                    }
                    return false
                })
            }
            const sortedRecipes = filteredRecipes.sort((a, b) => {
                return parseISO(b.updatedAt) - parseISO(a.updatedAt)
            })
            if (!query.trim()) return sortedRecipes
            return sortedRecipes.filter((recipe) => {
                let recipeWaxes = getRecipeWaxes(recipe, false, globalState.supplies)
                let recipeNotes = ""
                let recipeWick = ""
                if (recipe.wick_in_recipe && recipe.wick_name) recipeWick = recipe.wick_name
                if (recipe.wick_in_recipe && recipe.wick_id) recipeWick = getSupplyNameById(globalState.supplies, recipe.wick_id)
                if (recipe.notes) recipeNotes = recipe.notes
                const recipeTextToSearch = `${recipe.name}${recipeNotes}${recipe.fragrance_oil}${recipe.description}${recipe.color_name}${recipeWick}${recipe.visual_id}${recipeWaxes}${recipe.container_name}${recipe.recipe_type}`
                if (recipeTextToSearch.toLowerCase().indexOf(query.trim().toLowerCase()) !== -1) return true
                return false
            })
        } catch (error) {
            return []
        }
    }
    const getFilterClass = () => {
        try {
            if (Object.keys(filter).length === 0) return "general-category-page-utility-button"
            return "general-category-page-utility-button general-category-page-utility-button-active"
        } catch (error) {
            return "general-category-page-utility-button"
        }
    }
    const getFavoriteRecipes = () => {
        try {
            if (!globalState.recipes) return []
            const filteredRecipes = globalState.recipes.filter((recipe) => {
                if (recipe.is_favorite) return true
                return false
            })
            const sortedRecipes = filteredRecipes.sort((a, b) => {
                return parseISO(b.updatedAt) - parseISO(a.updatedAt)
            })
            if (!favoritesQuery.trim()) return sortedRecipes
            return sortedRecipes.filter((recipe) => {
                let recipeWaxes = getRecipeWaxes(recipe, false, globalState.supplies)
                const recipeTextToSearch = `${recipe.name}${recipe.fragrance_oil}${recipe.description}${recipe.color_name}${recipe.visual_id}${recipeWaxes}${recipe.container_name}${recipe.recipe_type}`
                if (recipeTextToSearch.toLowerCase().indexOf(favoritesQuery.trim().toLowerCase()) !== -1) return true
                return false
            })
        } catch (error) {
            return []
        }
    }
    const getCollectionsByRecent = () => {
        try {
            if (!globalState.collections) return []
            const sortedCollections = globalState.collections.sort((a, b) => {
                return parseISO(b.updatedAt) - parseISO(a.updatedAt)
            })
            if (!collectionsQuery.trim()) return sortedCollections
            return sortedCollections.filter((collection) => {
                let collectionDescription = ""
                if (collection.description) collectionDescription = collection.description
                if (`${collection.name}${collectionDescription}`.toLowerCase().indexOf(collectionsQuery.trim().toLowerCase()) !== -1) return true
                return false
            })
        } catch (error) {
            return []
        }
    }
    const goToPath = (path) => {
        navigate(path)
    }
    const additionalRecipeListStyles = () => {
        try {
            if (recipes.length < 3) return { flexDirection: "column" }
            return {}
        } catch (error) {
            return {}
        }
    }
    const createCollection = (payload) => {
        setIsShowingNewCollectionModal(false)
        api.createNewCollection(payload)
        .then((res) => {
            fetchUserLibrary()
        })
        .catch((err) => {
            fetchUserLibrary()
        })        
    }
    const restoreSpecificRecipe = (recipeId) => {
        try {
            api.restoreRecipe({recipe_id: recipeId})
            .then((res) => {
                fetchUserLibrary()
            })
            .catch((err) => {
                fetchUserLibrary()
            })
        } catch (error) {
            return
        }
    }
    const updateSelectedFavoritesRecipe = (recipeId) => {
        setSelectedPreviewRecipeFavorite(recipeId)
    }
    useEffect(() => {
        if (!globalState.library_loaded) fetchUserLibrary()
        setRecipes(getCurrentRecipes())
    // eslint-disable-next-line
    }, [globalState])
    useEffect(() => {
        setRecipes(getCurrentRecipes())
        setFavoriteRecipes(getFavoriteRecipes())
    // eslint-disable-next-line
    }, [globalState, filter, query])
    useEffect(() => {
        dispatch({type: "nav", payload: {view: "recipes"}})
        fetchUserLibrary()
        if (localStorage.getItem("mlRecipeFilter") !== null) setFilter(JSON.parse(localStorage.getItem("mlRecipeFilter")))
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        const stringFilter = JSON.stringify(filter)
        localStorage.setItem("mlRecipeFilter", stringFilter)
    }, [filter])
    useEffect(() => {
        if(searchParams.get("v")) {
            if (searchParams.get("v") === "collections") return setView("collections")
        }
    // eslint-disable-next-line
    }, [searchParams])
    useEffect(() => {
        if (selectedPreviewRecipe) return 
        if (globalState.recipes.length === 0) return
        setSelectedPreviewRecipe(globalState.recipes[0]._id)
    // eslint-disable-next-line
    }, [])

    // Handle search input
    const handleSearch = (value) => {
        setQuery(value);
    };

    // Submit search query
    const handleSearchSubmit = () => {
        const trimmedSearch = query.trim();
        setApiFilters(prev => ({
            ...prev,
            search: trimmedSearch
        }));
        setPage(1); // Reset to first page on new search
    };

    // Clear search
    const handleClearSearch = () => {
        setQuery('');
        setApiFilters(prev => ({
            ...prev,
            search: ''
        }));
    };
    
    // Handle filter changes
    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
        
        // Convert UI filter format to API filter parameters
        const apiFilterParams = {};
        
        // Convert wax filters if present
        if ('waxes' in newFilter && newFilter.waxes.length > 0) {
            apiFilterParams.waxes = newFilter.waxes.join(',');
        }
        
        // Convert fragrance filters if present
        if ('fragrances' in newFilter && newFilter.fragrances.length > 0) {
            apiFilterParams.fragrances = newFilter.fragrances.join(',');
        }
        
        // Convert wick count filters if present
        if ('wicks' in newFilter && newFilter.wicks.length > 0) {
            apiFilterParams.wick_counts = newFilter.wicks.join(',');
        }
        
        // Convert fragrance load filter if present
        if ('fragranceLoad' in newFilter) {
            apiFilterParams.min_fragrance_load = newFilter.fragranceLoad;
        }
        
        // Update API filters with these new parameters
        setApiFilters(prev => ({
            ...prev,
            ...apiFilterParams
        }));
        
        // Reset to first page when filters change
        setPage(1);
    };
    
    // Toggle collection selection
    const toggleCollection = (collectionId) => {
        setSelectedCollections(prev => {
            if (prev.includes(collectionId)) {
                return prev.filter(id => id !== collectionId);
            }
            return [...prev, collectionId];
        });
    };
    
    useEffect(() => {
        // Fetch recipes when page, filters or selected collections change
        if (view === "all") {
            fetchRecipes();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, apiFilters, selectedCollections, view]);
    
    // When user submits search
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Enter' && document.activeElement.classList.contains('general-page-search-bar-input')) {
                handleSearchSubmit();
            }
        };
        
        document.addEventListener('keydown', handleKeyPress);
        return () => document.removeEventListener('keydown', handleKeyPress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);
    
    // Other useEffects for handling different views
    
    useEffect(() => {
        // This still relies on globalState for favorites and collections
        setFavoriteRecipes(getFavoriteRecipes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalState, favoritesQuery]);
    
    useEffect(() => {
        dispatch({type: "nav", payload: {view: "recipes"}});
        // Still need to fetch library for collections, archived recipes, etc.
        fetchUserLibrary();
        if (localStorage.getItem("mlRecipeFilter") !== null) {
            setFilter(JSON.parse(localStorage.getItem("mlRecipeFilter")));
        }
        // Initial fetch of recipes
        fetchRecipes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        const stringFilter = JSON.stringify(filter);
        localStorage.setItem("mlRecipeFilter", stringFilter);
    }, [filter]);
    
    useEffect(() => {
        if(searchParams.get("v")) {
            if (searchParams.get("v") === "collections") return setView("collections");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);
    
    useEffect(() => {
        if (selectedPreviewRecipe) return;
        if (recipes.length > 0) {
            setSelectedPreviewRecipe(recipes[0]._id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipes]);

    return (
        <div>
            {isShowingNewCollectionModal && <ModalNewCollection globalState={globalState} hideModal={() => setIsShowingNewCollectionModal(false)} createCollection={createCollection} />}
            {isShowingFilterModal && 
                <ModalFilter 
                    filter={filter} 
                    globalState={globalState} 
                    setFilter={(newFilter) => {
                        setFilter(newFilter);
                        handleFilterChange(newFilter);
                    }} 
                    hideModal={() => setIsShowingFilterModal(false)}
                />
            }
            {globalState.library_loaded &&
            <div>
                {globalState.recipes.length > 0 &&
                <div className="general-category-page-main"> 
                    <div className="general-category-page-header">
                        <h2>Recipes</h2>
                        {view !== "collections" && <div className="general-category-page-create" onClick={() => navigate('/create/recipe')}><HiOutlinePlus/> New Recipe</div>}
                        {view === "collections" && <div className="general-category-page-create" onClick={() => setIsShowingNewCollectionModal(true)}><HiOutlinePlus/> New Collection</div>}
                    </div>
                    <InternalNavigationSections sections={["all", "collections", "archive"]} view={view} setView={setView} />
                    <div className="responsive-recipe-container">
                        {view === "all" &&
                        <>
                        <div className="general-category-page-utilities">
                            <GeneralPageSearchBar 
                                searchTerm={query} 
                                setSearchTerm={handleSearch} 
                                onEnterPress={handleSearchSubmit}
                            />
                            <div className="general-category-page-utility-buttons">
                                <div className={getFilterClass()} onClick={() => setIsShowingFilterModal(true)}>
                                    <MdFilterList/>
                                    Filter
                                </div>
                                {view !== "collections" && <div className="general-category-page-create responsive-recipe-new-recipe-button" onClick={() => navigate('/create/recipe')}><HiOutlinePlus/> New Recipe</div>}
                            </div>
                        </div>

                        {/* Recipe count indicator */}
                        <div className="recipes-table-plan-allowance-container" style={{margin: "5px 12px 15px"}}>
                            {totalRecords > 0 && (
                                <>
                                    {planName === "pro" && `${totalRecords} recipes`}
                                    {planName === "legacy" && `${totalRecords} / 500 recipes`}
                                    {planName === "basic" && `${totalRecords} / 50 recipes`}
                                </>
                            )}
                        </div>

                        {loading ? (
                            <div className="batch-table-loading">Loading...</div>
                        ) : recipes.length === 0 ? (
                            <div className="general-empty-state-container">
                                <div className="general-empty-state-description">
                                    <div className="general-empty-state-icon-container">
                                        <MdDocumentScanner/>
                                    </div>
                                    <h3>No recipes found</h3>
                                    {apiFilters.search ? (
                                        <p>Try adjusting your search</p>
                                    ) : (
                                        <p>Create your first recipe to get started</p>
                                    )}
                                    {apiFilters.search && (
                                        <button 
                                            onClick={handleClearSearch}
                                            className="batch-table-clear-filters"
                                        >
                                            Clear Search
                                        </button>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div style={{margin: "0px 12px"}}>
                                {standardCardSections.map((batchSection) => (
                                    <div key={batchSection.name}>
                                        {getCardsBySection(recipes, batchSection, 'updatedAt').length > 0 && (
                                            <CollapsibleListSection title={batchSection.name}>
                                                <div className="general-card-list-recipes" style={{paddingLeft: "0px"}}>
                                                    {getCardsBySection(recipes, batchSection, 'updatedAt').map((recipe) => (
                                                        <PrimaryRecipeCard recipe={recipe} key={recipe._id} navLocation={goToPath} globalState={globalState}/>
                                                    ))}
                                                </div>
                                            </CollapsibleListSection>
                                        )}
                                    </div>
                                ))}
                                
                                {/* Pagination controls for mobile */}
                                {pagination.total_pages > 1 && (
                                    <div className="batch-table-pagination" style={{margin: "20px 0"}}>
                                        <button 
                                            className="batch-table-pagination-button"
                                            disabled={!pagination.has_prev}
                                            onClick={() => setPage(page - 1)}
                                        >
                                            Previous
                                        </button>
                                        <span className="batch-table-pagination-info">
                                            Page {pagination.current_page} of {pagination.total_pages}
                                        </span>
                                        <button 
                                            className="batch-table-pagination-button"
                                            disabled={!pagination.has_next}
                                            onClick={() => setPage(page + 1)}
                                        >
                                            Next
                                        </button>
                                    </div>
                                )}
                                
                                <div style={{textAlign: "center", fontSize: "12px", margin: "20px 0", color: "#666"}}>
                                    Recipes older than 1 year are only available on desktop
                                </div>
                            </div>
                        )}

                        {recipes.length === 0 && query && !loading &&
                        <div className="general-card-list-recipes" style={additionalRecipeListStyles()}>                        
                            <div className="general-empty-state-container">
                                <div className="general-empty-state-description">
                                    <div className="general-empty-state-icon-container">
                                        <MdDocumentScanner/>
                                    </div>
                                    <h3>No results found!</h3>
                                    <div className="general-empty-state-instructions">
                                        "{query}" did not match any recipes.  Please try again.
                                    </div>
                                </div>
                                
                                <div className="general-empty-state-button-clear" onClick={handleClearSearch}>Clear search</div>
                
                            </div>
                            
                        </div>
                        }

                        </>
                        }

                        {view === "favorites" && !favoritesQuery && globalState.library_loaded && favoriteRecipes.length === 0 &&
                        <div className="general-empty-state-container">
                            <div className="general-empty-state-description">
                                <h3>No favorite recipes</h3>
                                <div className="general-empty-state-instructions">
                                    Mark recipes as your favorite to see them here
                                </div>
                            </div>
                        </div>
                        }

                        {globalState.collections.length === 0 && view === "collections" && globalState.library_loaded &&
                        <div className="general-empty-state-container">
                            <div className="general-empty-state-description">
                                <h3>You don't have any collections</h3>
                                <div className="general-empty-state-instructions">
                                    Collections let you group recipes together
                                </div>
                            </div>
                        </div>
                        }

                        {globalState.collections.length > 0 && view === "collections" && globalState.library_loaded &&
                        <>
                        <div className="general-category-page-utilities">
                            <GeneralPageSearchBar searchTerm={collectionsQuery} setSearchTerm={setCollectionsQuery} />
                        </div>
                        <div className="general-card-list-collections" >
                            {getCollectionsByRecent().map((collection) => (
                                <CollectionCard key={collection._id} collection={collection} navLocation={goToPath} globalState={globalState}/>
                            ))}
                        </div>
                        </>
                        }
                    </div>
                    <div className="recipe-container-desktop">
                        {view === "all" &&
                            <RecipesTable 
                                globalState={globalState}
                                dispatch={dispatch}
                                fetchUserLibrary={fetchUserLibrary}
                            />
                        }
                        {view === "favorites" &&
                        <div className="general-preview-list-page-container">

                            <div className="general-preview-list-list-container">
                                <div className="general-category-page-utilities">
                                    <GeneralPageSearchBar searchTerm={favoritesQuery} setSearchTerm={setFavoritesQuery} />
                                </div>
                                
                                <div className="general-card-list-recipes-surrogate">
                                    <div className="general-card-list-recipe-positions" style={{top: "260px", paddingRight: "12px"}}>
                                        <div className="general-card-list-recipes" style={{paddingLeft: "0px"}}>
                                            {favoriteRecipes.map((recipe) => (
                                                <PreviewRecipeCard recipe={recipe} key={recipe._id} onSelect={updateSelectedFavoritesRecipe} selectedPreviewRecipeId={selectedPreviewRecipeFavorite}/>
                                            ))}
                                            {favoriteRecipes.length === 0 && <div>No favorites yet</div> }
                                        </div>
                                    </div>
                                </div> 
                            </div>

                            <div className="general-preview-list-pane-container">
                                {selectedPreviewRecipeFavorite &&
                                    <RecipePreviewPane globalState={globalState} dispatch={dispatch} fetchUserLibrary={fetchUserLibrary} recipeId={selectedPreviewRecipeFavorite} setRecipeId={setSelectedPreviewRecipeFavorite} />
                                }
                                {!selectedPreviewRecipeFavorite && favoriteRecipes.length > 0 && <div style={{display: "flex", justifyContent: "center", marginTop: "40px", color: "grey"}}>Select a recipe to view more information</div> }
                                {!selectedPreviewRecipeFavorite && favoriteRecipes.length === 0 && <div style={{display: "flex", justifyContent: "center", marginTop: "40px", color: "grey"}}>You don't have any favorite recipes</div> }
                            </div>

                        </div>
                        }
                        
                        {globalState.collections.length > 0 && view === "collections" && globalState.library_loaded &&
                        <>
                        <div className="general-category-page-utilities">
                            <GeneralPageSearchBar searchTerm={collectionsQuery} setSearchTerm={setCollectionsQuery} />
                        </div>
                        <div className="general-card-list-collections" >
                            {getCollectionsByRecent().map((collection) => (
                                <CollectionCard key={collection._id} collection={collection} navLocation={goToPath} globalState={globalState}/>
                            ))}
                            <div style={{flexBasis: "30%"}}></div>
                            <div style={{flexBasis: "30%"}}></div>
                        </div>
                        </>
                        }
                        
                        {globalState.collections.length === 0 && view === "collections" && globalState.library_loaded &&
                        <div className="general-empty-state-container">
                            <div className="general-empty-state-description">
                                <h3>You don't have any collections</h3>
                                <div className="general-empty-state-instructions">
                                    Collections let you group recipes together
                                </div>
                            </div>
                        </div>
                        }
                        
                        {view === "archive" && globalState.archived_recipes.length > 0 &&
                        <div style={{transform: "translateY(-1px)"}}>
                            <p style={{margin: "16px 12px", marginBottom: "24px", textAlign: "center"}}>Selecting a recipe will restore it</p>
                            <div className="general-card-list-recipes general-card-list-recipes-archived-desktop">
                                {globalState.archived_recipes.map((recipe) => (
                                    <PrimaryRecipeCard recipe={recipe} key={recipe._id} navLocation={false} altAction={() => restoreSpecificRecipe(recipe._id)} globalState={globalState}/>
                                ))}
                                <div style={{flexBasis: "30%"}}></div>
                                <div style={{flexBasis: "30%"}}></div>
                            </div>
                        </div>
                        }

                        {globalState.archived_recipes.length === 0 && view === "archive" && globalState.library_loaded &&
                        <div className="general-empty-state-container">
                            <div className="general-empty-state-description">
                                <h3>The archive is empty</h3>
                                <div className="general-empty-state-instructions">
                                    Archived recipes will appear here
                                </div>
                            </div>
                        </div>
                        }

                    </div>
                </div>
                }

                {globalState.recipes.length === 0 && view === "all" &&
                <div className="general-empty-state-container">
                    <div className="general-empty-state-description">
                        <div className="general-empty-state-icon-container">
                            <MdDocumentScanner/>
                        </div>
                        <h3>You don't have any recipes</h3>
                        <div className="general-empty-state-instructions">
                            Recipes allow you to create candle batches with specific ingredients like fragrance oils, vessels, wax and more.
                        </div>
                    </div>
                    
                    <button onClick={() => navigate('/create/recipe')}>Create new recipe</button>

                </div>
                }
                
                <div className="responsive-recipe-container">
                    {/* {globalState.recipes.length === 0 && view === "all" &&
                    <div className="general-empty-state-container">
                        <div className="general-empty-state-description">
                            <div className="general-empty-state-icon-container">
                                <MdDocumentScanner/>
                            </div>
                            <h3>You don't have any recipes</h3>
                            <div className="general-empty-state-instructions">
                                Recipes allow you to create candle batches with specific ingredients like fragrance oils, vessels, wax and more.
                            </div>
                        </div>
                        
                        <button onClick={() => navigate('/create/recipe')}>Create new recipe</button>

                    </div>
                    } */}
                    
                    
                    {view === "archive" && globalState.archived_recipes.length > 0 &&
                    <div style={{transform: "translateY(-88px)"}}>
                        <p style={{margin: "16px 12px", marginBottom: "24px", textAlign: "center"}}>Tapping a recipe will restore it</p>
                        <div className="general-card-list-recipes" style={additionalRecipeListStyles()}>
                            {globalState.archived_recipes.map((recipe) => (
                                <PrimaryRecipeCard recipe={recipe} key={recipe._id} navLocation={false} altAction={() => restoreSpecificRecipe(recipe._id)} globalState={globalState}/>
                            ))}
                        </div>
                    </div>
                    }

                    {view === "archive" && globalState.library_loaded && globalState.archived_recipes.length === 0 &&
                    <div className="general-empty-state-container">
                        <div className="general-empty-state-description">
                            <h3>The archive is empty</h3>
                            <div className="general-empty-state-instructions">
                                Archived recipes will appear here
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
            }
            {!globalState.library_loaded &&
                <div style={{display: "flex", justifyContent: "center"}}><LoadingSymbol/></div>
            }
        </div>
    )
}
