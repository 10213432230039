import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation  } from 'react-router-dom'
import { logout, loginWithGoogle } from '../common/Armstrong';
import { ReactComponent as Logo } from '../static/assets/moonlite-logo-black.svg'
import { ReactComponent as LogoText } from '../static/assets/moonlite-logo-black-text.svg'
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi'
import { useSubscription } from '../context/SubscriptionContext'
import InventorySettingsModal from './inventory/InventorySettingsModal'
import Api from '../common/APIUtils'

export default function Header({globalState, dispatch}) {
    const api = new Api()
    const location = useLocation();
    const profileMenuRef = useRef(null)
    const { subscriptionStatus } = useSubscription();
    const PUBLIC_PATHS = ['/privacy-policy', '/plans']
    const [isShowingMenu, setIsShowingMenu] = useState(() => { return false })
    const [isShowingSettings, setIsShowingSettings] = useState(() => { return false })
    const navigate = useNavigate();
    const changeLoginState = (loginState) => {
        logout()
        dispatch({type: 'login', payload: {success: loginState}})
        navigate('/')
    }
    const navigateHome = () => {
        if (globalState.authenticated) return navigate('/home')
        return navigate('/')
    }
    const getAdditionalHeaderStyle = () => {
        if (globalState.authenticated) return { justifyContent: "space-between"}
        return { justifyContent: "center", marginTop: "40px" }
    }
    const goToSubscriptionManager = () => {
        api.createPortalSession()
            .then((res) => {
                if (res.data.url) {
                    window.location.href = res.data.url;
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    const openSupportLink = () => {
        window.open('mailto:support@moonlite.app?subject=MoonLite%20Support%20Question');
    }
    const openPrivacyPolicy = () => {
        navigate('/privacy-policy')
    }
    const openSettings = () => {
        setIsShowingMenu(false); // Close the dropdown menu
        setIsShowingSettings(true); // Open the settings modal
    }
    const closeSettingsModal = () => {
        setIsShowingSettings(false);
    }
    const getNavigationClasses = (viewName) => {
        if (viewName === globalState.view) return "header-navigation-bar-selected"
        return ""
    }
    const updateView = (viewName) => {
        dispatch({type: "nav", payload: {view: viewName}})
        if (viewName === "home") return navigate("/home")
        return navigate(`/candles/${viewName}`);
    }
    const shouldShowNavigation = () => {
        return globalState.authenticated && 
               subscriptionStatus?.isActive && 
               !PUBLIC_PATHS.includes(location.pathname);
    }
    useEffect(() => {
        if (PUBLIC_PATHS.includes(location.pathname)) {
            if (subscriptionStatus?.isActive && location.pathname === '/plans') {
                navigate('/')
                return
            }
            if (!globalState.authenticated && location.pathname === '/plans') {
                navigate('/')
                return
            }
            return
        }
        if (!globalState.authenticated && globalState.loaded) return navigate('/')
    // eslint-disable-next-line
    }, [globalState, location.pathname, subscriptionStatus])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
                setIsShowingMenu(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [profileMenuRef])
    return (
        <div className="header-container" style={getAdditionalHeaderStyle()}>
            {!globalState.authenticated &&
            <div className="header-unauth-heading-row">
                <div className="header-logo-main" onClick={navigateHome}><Logo/></div>
                <div className="header-unauth-login-button" onClick={loginWithGoogle}>Log In</div>
            </div>
            
            }
            {globalState.authenticated &&
            <>
            <div className="header-logo-main header-logo-icon" onClick={navigateHome}><LogoText/></div>
            {shouldShowNavigation() && (
                <div className="header-navigation-container">
                    <span onClick={() => updateView("recipes")} className={getNavigationClasses("recipes")}>Recipes</span>
                    <span onClick={() => updateView("batches")} className={getNavigationClasses("batches")}>Batches</span>
                    <span onClick={() => updateView("inventory")} className={getNavigationClasses("inventory")}>Inventory</span>
                </div>
            )}
            <div className="header-profile-menu-container" ref={profileMenuRef}>
                <div className="util-row util-align-center" style={{gap: "12px", cursor: "pointer", fontSize: "16px"}} onClick={() => setIsShowingMenu(!isShowingMenu)}>
                    {globalState.user.picture &&
                    <div className="header-profile-picture">
                        <img src={globalState.user.picture} alt={`Profile for ${globalState.user.first_name}`} />
                    </div>
                    }
                    <div>{globalState.user.first_name}</div>
                    {!isShowingMenu && <HiOutlineChevronDown/>}
                    {isShowingMenu && <HiOutlineChevronUp/>}
                </div>
                {isShowingMenu &&
                <div className="header-profile-menu">
                    <span className="header-auth-button" onClick={goToSubscriptionManager}>My Plan</span>
                    <span className="header-auth-button" onClick={openSettings}>Settings</span>
                    <span className="header-auth-button" onClick={openSupportLink}>Support</span>
                    <span className="header-auth-button" onClick={openPrivacyPolicy}>Privacy Policy</span>
                    <span className="header-auth-button" onClick={() => changeLoginState(false)}>Log Out</span>
                </div>
                }
                
            </div>
            </>
            }
            {isShowingSettings && <InventorySettingsModal hideModal={closeSettingsModal} />}
        </div>
    )
}
