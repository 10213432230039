import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Api from '../common/APIUtils';
import { updateUserSettingInLocalStorage } from '../common/Helpers';

const defaultUserSettings = {
    inventory_units_currency: "dollar",
    inventory_units_wax: "pound"
}

const SubscriptionContext = createContext();

// Cache TTL in milliseconds (20 minutes)
const CACHE_TTL = 20 * 60 * 1000;

export function useSubscription() {
    return useContext(SubscriptionContext);
}

export function SubscriptionProvider({ children }) {
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [planName, setPlanName] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [userSettings, setUserSettings] = useState(() => {
        // Try to load from localStorage first
        const storedSettings = localStorage.getItem('moonliteUserSettings');
        if (storedSettings) {
            try {
                return JSON.parse(storedSettings);
            } catch (e) {
                console.error("Failed to parse user settings from localStorage", e);
                return defaultUserSettings;
            }
        }
        return defaultUserSettings;
    });
    const [error, setError] = useState(null);
    const [lastChecked, setLastChecked] = useState(null);
    const navigate = useNavigate();
    const api = new Api();

    const isCacheValid = () => {
        if (!lastChecked) return false;
        return (Date.now() - lastChecked) < CACHE_TTL;
    };

    const checkSubscriptionStatus = async (force = false) => {
        try {
            // Return cached value if valid and not forced
            if (!force && isCacheValid() && subscriptionStatus) {
                return subscriptionStatus;
            }

            setError(null);
            const response = await api.isAuthenticated();
            
            if (response.data.user) {
                const status = {
                    isActive: response.data.active_subscription,
                    status: response.data.subscription_status,
                    subscriptionId: response.data.subscription_id,
                    needsPlanSelection: response.data.needsPlanSelection,
                    planType: response.data.plan_type,
                    currentPeriodEnd: response.data.current_period_end,
                    cancelAtPeriodEnd: response.data.cancel_at_period_end,
                    planName: response.data.planName
                };

                if (response.data.user.user_settings) {
                    setUserSettings(response.data.user.user_settings);
                    // Save to localStorage
                    localStorage.setItem('moonliteUserSettings', JSON.stringify(response.data.user.user_settings));
                } else {
                    setUserSettings(defaultUserSettings);
                    localStorage.setItem('moonliteUserSettings', JSON.stringify(defaultUserSettings));
                }

                setSubscriptionStatus(status);
                setPlanName(status.planName)
                setLastChecked(Date.now());

                // Redirect to plans if needed
                if (status.needsPlanSelection && 
                    window.location.pathname !== '/plans' && 
                    window.location.pathname !== '/privacy-policy') {
                    navigate('/plans');
                }

                return status;
            }
            
            return null;
        } catch (error) {
            console.error('Error checking subscription:', error);
            setError('Failed to verify subscription status');
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const clearCache = () => {
        setLastChecked(null);
        setSubscriptionStatus(null);
    };

    /**
     * Updates user settings both in the context and persists them to backend
     * @param {Object} newSettings - Object containing settings to update
     * @returns {Promise<boolean>} - Success/failure of the update
     */
    const updateSettings = async (newSettings) => {
        try {
            // Update settings in the database
            const api = new Api();
            const response = await api.updateUserSettings(newSettings);
            
            if (response.data && response.data.user_settings) {
                // Update each setting in the state and localStorage
                const updatedSettings = { ...userSettings };
                
                for (const [key, value] of Object.entries(newSettings)) {
                    updatedSettings[key] = value;
                    updateUserSettingInLocalStorage(key, value);
                }
                
                // Update context state
                setUserSettings(updatedSettings);
                return true;
            }
            
            return false;
        } catch (error) {
            console.error('Error updating settings:', error);
            return false;
        }
    };

    // Check subscription status on mount and setup periodic checks
    useEffect(() => {
        checkSubscriptionStatus();
        
        // Set up periodic checks (every 5 minutes)
        const interval = setInterval(() => {
            checkSubscriptionStatus(true); // Force check on interval
        }, 5 * 60 * 1000);
        
        return () => {
            clearInterval(interval);
            clearCache();
        };
    // eslint-disable-next-line
    }, []);

    // Check subscription status on route changes
    useEffect(() => {
        const handleRouteChange = () => {
            if (!isCacheValid()) {
                checkSubscriptionStatus();
            }
        };

        window.addEventListener('popstate', handleRouteChange);
        return () => window.removeEventListener('popstate', handleRouteChange);
    // eslint-disable-next-line
    }, []);

    // Check subscription status on initial load
    useEffect(() => {
        checkSubscriptionStatus();
        
        // ... existing code ...
    }, [navigate]); // eslint-disable-line react-hooks/exhaustive-deps

    // Value object to provide through context
    const value = {
        subscriptionStatus,
        planName,
        isLoading,
        error,
        userSettings,
        setUserSettings,
        clearCache,
        checkSubscriptionStatus,
        updateSettings
    };

    return (
        <SubscriptionContext.Provider value={value}>
            {children}
        </SubscriptionContext.Provider>
    );
} 