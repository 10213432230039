import React from 'react'
import { useState } from 'react'
import { useNavigate  } from 'react-router-dom'
import { gramsToOuncesString, getSupplyNameById } from '../../common/Helpers'
import ModalBatchException from '../modals/ModalBatchException'
import Api from '../../common/APIUtils'

export default function BatchSuppliesPane({ recipe, batch, fetchUserLibrary, fetchBatch, recipeContainer, globalState }) {
    const api = new Api()
    const navigate = useNavigate();
    const [showBatchException, setShowBatchException] = useState(() => { return false })
    const [isOunces, setIsOunces] = useState(() => { return false })

    // Early return if required props are missing
    if (!batch || !recipe || !batch.recipe) {
        return (
            <div className="general-page-create-section general-page-create-section-margin-remove">
                <div className="recipe-ingredients-list recipe-ingredients-list-detailed">
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Loading...</div>
                        <div className="recipe-ingredient-value"></div>
                    </div>
                </div>
            </div>
        );
    }

    const getColorDropsAsString = (drops) => {
        try {
            let bufferPercentage = 0
            if (recipe.buffer_percentage) bufferPercentage = recipe.buffer_percentage
            if (!recipe.color_in_recipe || !drops) return `0 ${recipe.color_units.toLowerCase()}`
            let totalWeightGramsPure = batch.weight_wax_grams + batch.weight_fragrance_grams
            let totalWeightGrams = totalWeightGramsPure * (1 + (bufferPercentage/100))
            let totalWeightOunces = totalWeightGrams /28.3495
            let dropsRaw = drops * totalWeightOunces / 16 // 16 ounces in a pound
            if (recipe.color_units === "Ounces") {
                return `${dropsRaw.toFixed(2)} Ounces`
            }
            if (recipe.color_units === "Grams") {
                return `${dropsRaw.toFixed(0)} Grams`
            }
            let dropsLowEnd = Math.floor(dropsRaw)
            let dropsHighEnd = Math.ceil(dropsRaw)
            return `${dropsLowEnd} - ${dropsHighEnd} Drops`
        } catch (error) {
            return `0`
        }        
    }
    const getAdditionalUnitsClass = (unitIsOunces) => {
        if (unitIsOunces && isOunces) return "general-page-item-unit-selection-selected"
        if (!unitIsOunces && !isOunces) return "general-page-item-unit-selection-selected"
        return ""
    }
    const hasWicks = () => {
        if (recipe.recipe_type === "wax_melt") return false
        return true
    }
    const goToPath = (path) => {
        navigate(path)
    }
    const getWickDisplayValues = () => {
        try {
            let arr = []
            if (batch.wicks) arr = batch.wicks
            if (batch.wick_ids) {
                arr = []
                for (let i = 0; i < batch.wick_ids.length; i++) {
                    const wickId = batch.wick_ids[i];
                    const wickName = getSupplyNameById(globalState.supplies, wickId)
                    if (wickName) arr.push(wickName)
                }
            }
            const uniqueValues = {}
            const result = []
            let wickCount = 1
            if (recipe.wick_count) wickCount = recipe.wick_count
            for (const value of arr) {
                if (uniqueValues.hasOwnProperty(value)) {
                    uniqueValues[value]++
                } else {
                    uniqueValues[value] = 1
                }
            }
            for (const [value, count] of Object.entries(uniqueValues)) {
                result.push(`${value} x ${count * wickCount}`)
            }
            return result 
        } catch (error) {
            return []
        }
    }
    const getBatchBufferPill = () => {
        try {
            if (!batch.buffer_percentage) return false
            if (batch.buffer_percentage === "0" || batch.buffer_percentage === 0) return false
            return (
                <div className="util-row util-align-center util-justify-center" style={{marginTop: "16px"}}>
                    <span className="batch-buffer-pill">+{batch.buffer_percentage}% Buffer</span>
                </div>
            )
        } catch (error) {
            return false
        }
    }
    const getUnitsLabel = () => {
        if (isOunces) return "oz"
        return "grams"
    }
    const getWicksSection = () => {
        if (!recipe) return <></>
        if (recipe.wick_in_recipe && recipe.wick_id && batch.has_exceptions && batch.exception_wick_id) {
            if (batch.exception_wick_name) return `${getSupplyNameById(globalState.supplies, batch.exception_wick_id)} x ${batch.batch_size * recipe.wick_count}`
        }
        if (recipe.wick_in_recipe && recipe.wick_name && batch.has_exceptions) {
            if (batch.exception_wick_name) return `${batch.exception_wick_name} x ${batch.batch_size * recipe.wick_count}`
        }
        if (recipe.wick_in_recipe && recipe.wick_id && batch.has_exceptions && batch.exception_wick_id) {
            return `${getSupplyNameById(globalState.supplies, batch.exception_wick_id)} x ${batch.batch_size * recipe.wick_count}`
        }
        if (recipe.wick_in_recipe && recipe.wick_id) return `${getSupplyNameById(globalState.supplies, recipe.wick_id)} x ${batch.batch_size * recipe.wick_count}`
        if (recipe.wick_in_recipe && recipe.wick_name) return `${recipe.wick_name} x ${batch.batch_size * recipe.wick_count}`
        const currentWicks = getWickDisplayValues()
        return (
            <div style={{display: "flex", flexDirection: "column", gap: "4px"}}>
                {currentWicks.map((wick) => (
                    <span key={wick}>{wick}</span>
                ))}
            </div>
        )
    }
    const getWaxValue = (asGrams) => {
        if (batch.has_exceptions) {
            const waxBlendMap = batch.exception_wax_blend_map['blends']
            const waxWeights = waxBlendMap.map(b => parseFloat(b.weight_grams))
            const totalWaxWeight = Math.round(waxWeights.reduce((partialSum, a) => partialSum + a, 0)).toFixed(0)
            return totalWaxWeight
        }
        let bufferPercent = 0
        if (batch.buffer_percentage) bufferPercent = batch.buffer_percentage
        const waxWeightGrams = batch.weight_wax_grams * (1 + (bufferPercent/100))
        if (!isOunces || asGrams) return parseFloat(waxWeightGrams).toFixed(0)
        return parseFloat(waxWeightGrams/28.3495).toFixed(2)
    }
    const calculatePartialWaxLoadGrams = (weight) => {
        let waxWeightGrams = getWaxValue(true)
        if (!waxWeightGrams) return false
        return parseFloat(waxWeightGrams * weight / 100).toFixed(0)
    }
    const calculatePartialFOLoadGrams = (weight) => {
        let foWeightGrams = getFragranceValue(true)
        if (!foWeightGrams) return false
        return parseFloat(foWeightGrams * weight / 100).toFixed(0)
    }
    const getFragranceValue = (asGrams) => {
        try {
            if (batch.has_exceptions) {
                const foBlendMap = batch.exception_fragrance_oil_blend_map['blends']
                const foWeights = foBlendMap.map(b => parseFloat(b.weight_grams))
                const totalFragranceWeight = Math.round(foWeights.reduce((partialSum, a) => partialSum + a, 0)).toFixed(0)
                return totalFragranceWeight
            }
            let bufferPercent = 0
            if (batch.buffer_percentage) bufferPercent = batch.buffer_percentage
            const fragranceWeightGrams = batch.weight_fragrance_grams * (1 + (bufferPercent/100))
            if (!isOunces || asGrams) return parseFloat(fragranceWeightGrams).toFixed(0)
            return parseFloat(fragranceWeightGrams/28.3495).toFixed(2)
        } catch (error) {
            return 0
        }

    }
    const createBatchException = (updatedBatchInformation) => {
        // creates an exception then closes the modal
        api.createBatchException(updatedBatchInformation)
        .then((res) => {
            fetchBatch()
            setShowBatchException(false)
        }).catch((err) => {
            fetchBatch()
            setShowBatchException(false)
        })
    }
    return (
        <div className="general-page-create-section general-page-create-section-margin-remove">
            {showBatchException && batch && <ModalBatchException globalState={globalState} hideModal={() => setShowBatchException(false)} batch={batch} recipe={recipe} saveBatchUpdates={createBatchException} /> }            
            <div className="recipe-ingredients-list recipe-ingredients-list-detailed">
                <div className="recipe-ingredient-row">
                    <div className="recipe-ingredient-name">Batch Size</div>
                    <div className="recipe-ingredient-value">{batch.batch_size}</div>
                </div>

                {(!recipe.schema || recipe.schema === 1) &&
                <div className="recipe-ingredient-row">
                    <div className="recipe-ingredient-name">
                        {recipe.wax}
                        <span>Wax Weight</span>
                    </div>
                    {!isOunces && <div className="recipe-ingredient-value">{getWaxValue()} {getUnitsLabel()}</div>}
                    {isOunces && <div className="recipe-ingredient-value">{gramsToOuncesString(getWaxValue(true))}</div>}
                </div>
                }

                {recipe.schema === 2 &&
                <div className="recipe-ingredient-row-multi">
                    <div className="recipe-ingredient-row recipe-ingredient-row-primary">
                        <div className="recipe-ingredient-name">
                            Wax
                        </div>
                        {!isOunces && <div className="recipe-ingredient-value">{getWaxValue()} {getUnitsLabel()}</div>}
                        {isOunces && <div className="recipe-ingredient-value">{gramsToOuncesString(getWaxValue(true))}</div>}
                    </div>
                    {!batch.has_exceptions &&
                        <>
                        {recipe.wax_blend_map.blends.map((blend) => (
                            <div className="recipe-ingredient-row" key={blend.name}>
                                {blend.supply_id &&
                                <div className="recipe-ingredient-name general-supply-clickable-title" onClick={() => goToPath(`/candles/inventory/supplies/${blend.supply_id}`)}>
                                    {getSupplyNameById(globalState.supplies, blend.supply_id)}
                                    {recipe.wax_blend_map.blends.length > 1 && <span>{blend.weight}%</span>}
                                </div>
                                }
                                {!blend.supply_id &&
                                <div className="recipe-ingredient-name">
                                    {blend.name}
                                    {recipe.wax_blend_map.blends.length > 1 && <span>{blend.weight}%</span>}
                                </div>
                                }
                                {!isOunces && <div className="recipe-ingredient-value">{calculatePartialWaxLoadGrams(blend.weight)} grams</div>}
                                {isOunces && <div className="recipe-ingredient-value">{gramsToOuncesString(calculatePartialWaxLoadGrams(blend.weight))}</div> }
                            </div>
                        ))}
                        </>
                    }
                    {batch.has_exceptions &&
                    <>
                        {batch.exception_wax_blend_map.blends.map((blend) => (
                            <div className="recipe-ingredient-row" key={blend.name}>
                                {blend.supply_id &&
                                <div className="recipe-ingredient-name general-supply-clickable-title" onClick={() => goToPath(`/candles/inventory/supplies/${blend.supply_id}`)}>
                                    {getSupplyNameById(globalState.supplies, blend.supply_id)}*
                                    <span>Adjusted</span>
                                </div>
                                }
                                {!blend.supply_id &&
                                <div className="recipe-ingredient-name">
                                    {blend.name}*
                                    <span>Adjusted</span>
                                </div>
                                }
                                {!isOunces && <div className="recipe-ingredient-value">{blend.weight_grams} grams</div>}
                                {isOunces && <div className="recipe-ingredient-value">{gramsToOuncesString(blend.weight_grams)}</div> }
                            </div>
                        ))}
                    </>
                    }
                </div>
                }

                {(!recipe.schema || recipe.schema === 1 || !recipe.fragrance_oil_blend_map) && parseFloat(recipe.fragrance_load) > 0 &&
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            {recipe.fragrance_load}% {recipe.fragrance_oil}
                            <span>Fragrance Oil Weight</span>
                        </div>
                        {!isOunces && <div className="recipe-ingredient-value">{getFragranceValue()} {getUnitsLabel()}</div>}
                        {isOunces && <div className="recipe-ingredient-value">{gramsToOuncesString(getFragranceValue(true))}</div>}
                    </div>
                }

                {parseFloat(recipe.fragrance_load) === 0 &&
                <div className="recipe-ingredient-row">
                    <div className="recipe-ingredient-name">
                        Fragrance Oil
                    </div>
                    <div className="recipe-ingredient-value">None</div>
                </div>
                }

                {recipe.schema === 2 && recipe.fragrance_oil_blend_map && parseFloat(recipe.fragrance_load) > 0 &&
                <div className="recipe-ingredient-row-multi">
                    <div className="recipe-ingredient-row recipe-ingredient-row-primary">
                        <div className="recipe-ingredient-name">
                            Fragrance
                        </div>
                        {!isOunces && <div className="recipe-ingredient-value">{getFragranceValue()} {getUnitsLabel()}</div>}
                        {isOunces && <div className="recipe-ingredient-value">{gramsToOuncesString(getFragranceValue(true))}</div>}
                    </div>
                    {!batch.has_exceptions && recipe.fragrance_oil_blend_map && 
                    <>
                    {recipe.fragrance_oil_blend_map.blends.map((blend) => (
                        <div className="recipe-ingredient-row" key={blend.name}>
                            {blend.supply_id &&
                                <div className="recipe-ingredient-name general-supply-clickable-title" onClick={() => goToPath(`/candles/inventory/supplies/${blend.supply_id}`)}>
                                    {getSupplyNameById(globalState.supplies, blend.supply_id)}
                                    {recipe.fragrance_oil_blend_map.blends.length > 1 && <span>{blend.weight}%</span>}
                                </div>
                                }
                            {!blend.supply_id &&
                                <div className="recipe-ingredient-name">
                                    {blend.name}
                                    {recipe.fragrance_oil_blend_map.blends.length > 1 && <span>{blend.weight}%</span>}
                                </div>
                            }
                            {!isOunces && <div className="recipe-ingredient-value">{calculatePartialFOLoadGrams(blend.weight)} grams</div>}
                            {isOunces && <div className="recipe-ingredient-value">{gramsToOuncesString(calculatePartialFOLoadGrams(blend.weight))}</div> }
                        </div>
                    ))}
                    </>
                    }
                    {batch.has_exceptions && recipe.fragrance_oil_blend_map && batch.exception_fragrance_oil_blend_map &&
                    <>
                        {batch.exception_fragrance_oil_blend_map.blends && batch.exception_fragrance_oil_blend_map.blends.map((blend) => (
                            <div className="recipe-ingredient-row" key={blend.name}>

                            {blend.supply_id &&
                                <div className="recipe-ingredient-name general-supply-clickable-title" onClick={() => goToPath(`/candles/inventory/supplies/${blend.supply_id}`)}>
                                    {getSupplyNameById(globalState.supplies, blend.supply_id)}*
                                    <span>Adjusted</span>
                                    {recipe.fragrance_oil_blend_map.blends.length > 1 && <span>{blend.weight}%</span>}
                                </div>
                                }
                            {!blend.supply_id &&
                                <div className="recipe-ingredient-name">
                                    {blend.name}*
                                    <span>Adjusted</span>
                                    {recipe.fragrance_oil_blend_map.blends.length > 1 && <span>{blend.weight}%</span>}
                                </div>
                            }
                                {/* <div className="recipe-ingredient-name">
                                    {blend.name}*
                                    <span>Adjusted</span>
                                </div> */}
                                {!isOunces && <div className="recipe-ingredient-value">{blend.weight_grams} grams</div>}
                                {isOunces && <div className="recipe-ingredient-value">{gramsToOuncesString(blend.weight_grams)}</div> }
                            </div>
                        ))}
                    </>
                    }
                    
                </div>
                }
                
                {hasWicks() &&
                <div className="recipe-ingredient-row">
                    <div>
                        <div className="recipe-ingredient-name">Wicks</div> 
                        {recipe.wick_count === 2 &&
                        <div className="util-row util-align-center">
                            <div className="recipe-badge-decoration" style={{margin: 0, marginTop: "4px"}}>Double Wick</div>
                        </div>
                        }
                        {recipe.wick_count === 3 &&
                        <div className="util-row util-align-center">
                            <div className="recipe-badge-decoration" style={{margin: 0, marginTop: "4px"}}>Triple Wick</div>
                        </div>
                        }
                        {recipe.wick_count === 4 &&
                        <div className="util-row util-align-center">
                            <div className="recipe-badge-decoration" style={{margin: 0, marginTop: "4px"}}>Quad Wick</div>
                        </div>
                        }
                    </div>
                    
                    <div className="recipe-ingredient-value">{getWicksSection()}</div>
                </div>
                }
                {!hasWicks() &&
                <div className="recipe-ingredient-row">
                    <div className="recipe-ingredient-name">
                        Clamshell Molds
                        <span>Wax Melts</span>
                    </div>
                    <div className="recipe-ingredient-value">{batch.batch_size}</div>
                </div>
                }

                {recipe.container_name && recipe.recipe_type === "container" &&
                <div className="recipe-ingredient-row">
                    <div className="recipe-ingredient-name">
                        {recipe.container_name}
                        <span>Container</span>
                    </div>
                    <div className="recipe-ingredient-value">{batch.batch_size}</div>
                </div>
                }

                {recipeContainer &&
                <div className="recipe-ingredient-row">
                    <div className="recipe-ingredient-name general-supply-clickable-title" onClick={() => goToPath(`/candles/inventory/supplies/${recipeContainer._id}`)}>
                        {recipeContainer.supply_name}
                        <span>Container</span>
                    </div>
                    <div className="recipe-ingredient-value">{batch.batch_size}</div>
                </div>
                }

                {!batch.recipe.color_in_recipe &&
                <div className="recipe-ingredient-row">
                    <div className="recipe-ingredient-name">Color</div>
                    <div className="recipe-ingredient-value">None</div>
                </div>
                }
                {batch.recipe.color_in_recipe && !batch.recipe.color_map && 
                <>
                <div className="recipe-ingredient-row">
                    <div className="recipe-ingredient-name">Color</div>
                    <div className="recipe-ingredient-value">{batch.recipe.color_name}</div>
                </div>
                <div className="recipe-ingredient-row">
                    <div className="recipe-ingredient-name">
                        {recipe.color_name}
                        <span>Color</span>
                    </div>
                    <div className="recipe-ingredient-value">{getColorDropsAsString(batch.recipe.color_drops_per_pound)}</div>
                </div>
                </>
                }
                {batch.recipe.color_in_recipe && batch.recipe.color_map &&
                <>
                {batch.recipe.color_map.colors.map((color, index) => (
                <div className="recipe-ingredient-row" key={index}>
                    <div className="recipe-ingredient-name">
                        {color.color_name}
                        <span>Color</span>
                    </div>
                    <div className="recipe-ingredient-value">{getColorDropsAsString(color.color_drops_per_pound)}</div>
                </div>   
                ))}
                </>
            }
            <div className="util-row util-align-center util-space-between" style={{marginBottom: "20px"}}>
                <div className="general-page-item-unit-selection" style={{marginLeft: 0, marginRight: 0, marginTop: 0, marginBottom: 0}}>
                    <div className="util-row util-align-center" style={{gap: "12px"}}>
                        <span onClick={() => setIsOunces(false)} className={getAdditionalUnitsClass(false)}>grams</span>
                        <span onClick={() => setIsOunces(true)} className={getAdditionalUnitsClass(true)}>ounces</span>
                    </div>
                </div>
                <div className="batch-update-button" onClick={() => setShowBatchException(true)}>Edit Measurements</div>
            </div>
            </div>
            {getBatchBufferPill()}
        </div>
    )
}
