import React, { useState, useEffect } from 'react';
import './BatchPlanEditor.css';

const BatchPlanEditor = ({ 
    isOpen, 
    onClose, 
    onSave, 
    initialValue, 
    fieldKey, 
    label, 
    type = 'text',
    isMetric = false 
}) => {
    const [value, setValue] = useState(initialValue);

    // Reset value when initialValue or fieldKey changes
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue, fieldKey]);

    if (!isOpen) return null;

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(fieldKey, value);
        onClose();
    };

    return (
        <div className="batch-plan-editor-overlay" onClick={onClose}>
            <div className="batch-plan-editor" onClick={e => e.stopPropagation()}>
                <h2>Edit {label}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="batch-plan-editor-input">
                        <input
                            type={type}
                            value={value}
                            onChange={(e) => setValue(type === 'number' ? Number(e.target.value) : e.target.value)}
                            autoFocus
                            placeholder={`Enter ${label.toLowerCase()}`}
                        />
                        {type === 'number' && (
                            <span className="batch-plan-editor-unit">
                                {fieldKey.includes('temp') ? (isMetric ? '°C' : '°F') : ''}
                            </span>
                        )}
                    </div>
                    <div className="batch-plan-editor-buttons">
                        <button 
                            type="button" 
                            onClick={onClose}
                            className="batch-plan-editor-button keep"
                        >
                            Cancel
                        </button>
                        <button 
                            type="submit"
                            className="batch-plan-editor-button reset"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BatchPlanEditor; 