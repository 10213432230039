import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiSort, BiSortUp, BiSortDown, BiX, BiFilter, BiDotsVerticalRounded } from 'react-icons/bi';
import { MdDocumentScanner, MdEdit, MdArchive, MdDelete, MdAdd } from 'react-icons/md';
import Api from '../../common/APIUtils';
import CollectionFilterModal from './CollectionFilterModal';
import ModalConfirmAction from '../modals/ModalConfirmAction';
import { getBackgroundHexFromName } from '../../common/Helpers';
import '../batches/BatchTable.css';
import './RecipesTable.css';
import { useSubscription } from '../../context/SubscriptionContext'

const api = new Api();
const ITEMS_PER_PAGE = 50;
const MAX_VISIBLE_COLLECTIONS = 4;

const RecipeMenu = ({ recipe, onClose, style, onArchive, onDelete }) => {
    const navigate = useNavigate();
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    const menuItems = [
        {
            label: 'Edit',
            icon: <MdEdit size={18} />,
            onClick: () => navigate(`/recipe/${recipe._id}`)
        },
        {
            label: 'Create Batch',
            icon: <MdAdd size={18} />,
            onClick: () => navigate(`/create/batch/${recipe._id}`)
        },
        {
            label: 'Archive',
            icon: <MdArchive size={18} />,
            onClick: () => onArchive(recipe)
        },
        {
            label: 'Delete',
            icon: <MdDelete size={18} />,
            onClick: () => onDelete(recipe),
            className: 'recipe-menu-item-delete'
        }
    ];

    return (
        <div className="recipe-menu" ref={menuRef} style={style}>
            {menuItems.map((item, index) => (
                <button
                    key={index}
                    className={`recipe-menu-item ${item.className || ''}`}
                    onClick={() => {
                        item.onClick();
                        onClose();
                    }}
                >
                    {item.icon}
                    <span>{item.label}</span>
                </button>
            ))}
        </div>
    );
};

const RecipesTable = ({ globalState, dispatch, fetchUserLibrary }) => {
    const navigate = useNavigate();
    const { planName } = useSubscription()
    const [recipes, setRecipes] = useState([]);
    const [allRecipes, setAllRecipes] = useState([]); // Store all recipes for local operations
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [pagination, setPagination] = useState({
        total: 0,
        current_page: 1,
        total_pages: 1,
        has_next: false,
        has_prev: false
    });
    const [filters, setFilters] = useState({
        search: '',
        sortBy: 'updatedAt',
        sortOrder: 'desc'
    });
    const [searchInput, setSearchInput] = useState('');
    const [openMenuId, setOpenMenuId] = useState(null);
    const [showConfirmArchiveModal, setShowConfirmArchiveModal] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [selectedRecipe, setSelectedRecipe] = useState(null);

    // Get active collections from globalState
    const collections = globalState.collections || [];
    const showFilterButton = collections.length > MAX_VISIBLE_COLLECTIONS;

    const toggleCollection = (collectionId) => {
        setSelectedCollections(prev => {
            if (prev.includes(collectionId)) {
                return prev.filter(id => id !== collectionId);
            }
            return [...prev, collectionId];
        });
    };

    // Fetch recipes with filters
    const fetchRecipes = async () => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams({
                page,
                limit: ITEMS_PER_PAGE,
                ...(filters.search && { search: filters.search }),
                sort_by: filters.sortBy,
                sort_order: filters.sortOrder,
                ...(selectedCollections.length > 0 && { collection_ids: selectedCollections.join(',') })
            }).toString();

            const response = await api.getRecipesWithFilters(queryParams);
            
            if (!response || !response.data) {
                throw new Error('Invalid response format');
            }

            const totalRecords = response.data.total_records || 0;
            setTotalRecords(totalRecords);

            // If total records <= 50, store all recipes for local operations
            if (totalRecords <= ITEMS_PER_PAGE) {
                setAllRecipes(response.data.recipes || []);
            } else {
                setAllRecipes([]); // Clear local cache for large datasets
            }

            setRecipes(response.data.recipes || []);
            setPagination(response.data.pagination || {
                total: 0,
                current_page: 1,
                total_pages: 1,
                has_next: false,
                has_prev: false
            });
            setLoading(false);
        } catch (err) {
            console.error('Error fetching recipes:', err);
            setLoading(false);
        }
    };

    useEffect(() => {
        // If total records <= 50, use local operations
        if (totalRecords <= ITEMS_PER_PAGE && allRecipes.length > 0) {
            // Apply filters locally
            let filteredRecipes = [...allRecipes];
            
            // Apply search filter
            if (filters.search) {
                const searchTerm = filters.search.toLowerCase();
                filteredRecipes = filteredRecipes.filter(recipe => 
                    recipe.name.toLowerCase().includes(searchTerm) ||
                    (recipe.visual_id.toString() === searchTerm)
                );
            }

            // Apply collection filter
            if (selectedCollections.length > 0) {
                filteredRecipes = filteredRecipes.filter(recipe => {
                    const recipeCollectionIds = recipe.collections?.map(c => c._id.toString()) || [];
                    return selectedCollections.some(id => recipeCollectionIds.includes(id));
                });
            }

            // Apply sorting
            filteredRecipes.sort((a, b) => {
                let aValue = a[filters.sortBy];
                let bValue = b[filters.sortBy];

                // Handle special cases for sorting
                if (filters.sortBy === 'visual_id') {
                    aValue = parseInt(aValue) || 0;
                    bValue = parseInt(bValue) || 0;
                }

                const order = filters.sortOrder === 'asc' ? 1 : -1;
                if (aValue < bValue) return -1 * order;
                if (aValue > bValue) return 1 * order;
                return 0;
            });

            setRecipes(filteredRecipes);
        } else {
            // Use server-side operations
            fetchRecipes();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filters, selectedCollections, totalRecords <= ITEMS_PER_PAGE && allRecipes.length > 0]);

    const handleSort = (field) => {
        setFilters(prev => {
            if (prev.sortBy === field) {
                return {
                    ...prev,
                    sortOrder: prev.sortOrder === 'asc' ? 'desc' : 'asc'
                };
            }
            return {
                ...prev,
                sortBy: field,
                sortOrder: 'desc'
            };
        });
        setPage(1);
    };

    const handleSearch = (e) => {
        setSearchInput(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        if (e.key === 'Enter') {
            const trimmedSearch = searchInput.trim();
            if (trimmedSearch) {
                setPage(1);
                setFilters(prev => ({
                    ...prev,
                    search: trimmedSearch
                }));
            }
        }
    };

    const handleClearSearch = () => {
        setSearchInput('');
        setFilters(prev => ({
            ...prev,
            search: ''
        }));
    };

    const renderSortIcon = (field) => {
        if (filters.sortBy !== field) {
            return <BiSort className="sort-icon" />;
        }
        return filters.sortOrder === 'asc' ? 
            <BiSortUp className="sort-icon active" /> : 
            <BiSortDown className="sort-icon active" />;
    };

    const renderColumnHeader = (field, label) => {
        const isActive = filters.sortBy === field;
        return (
            <div 
                className={`table-header-content ${isActive ? 'active' : ''}`}
                onClick={() => handleSort(field)}
            >
                {label}
                {renderSortIcon(field)}
            </div>
        );
    };

    const RecipeTableRow = ({ recipe }) => {
        const buttonRef = useRef(null);
        const recipeCollections = recipe.collections?.map(collection => {
            const fullCollection = collections.find(c => c._id.toString() === collection._id.toString());
            return {
                ...collection,
                color_name: fullCollection?.color_name || 'default'
            };
        }) || [];

        const handleMenuClick = (e) => {
            e.stopPropagation();
            setOpenMenuId(openMenuId === recipe._id ? null : recipe._id);
        };

        const getMenuPosition = () => {
            if (!buttonRef.current) return {};
            const rect = buttonRef.current.getBoundingClientRect();
            return {
                position: 'fixed',
                top: `${rect.bottom + 4}px`,
                left: `${rect.left - 180}px` // Menu width is 180px
            };
        };

        return (
            <tr key={recipe._id}>
                <td>
                    <span 
                        className="batch-table-link" 
                        onClick={() => navigate(`/recipe/${recipe._id}`)}
                    >
                        {recipe.name}
                    </span>
                </td>
                <td>{recipe.visual_id}</td>
                <td>
                    {recipeCollections.length > 0 ? (
                        <div className="recipe-table-cell-collection">
                            {recipeCollections.map(collection => (
                                <span
                                    key={collection._id}
                                    className="recipe-table-cell-collection-pill"
                                    style={{ backgroundColor: getBackgroundHexFromName(collection.color_name) }}
                                >
                                    {collection.name}
                                </span>
                            ))}
                        </div>
                    ) : '-'}
                </td>
                <td>{recipe.total_batches || 0}</td>
                <td className="recipe-table-manage-cell">
                    <button 
                        ref={buttonRef}
                        className="recipe-table-manage-button"
                        onClick={handleMenuClick}
                    >
                        <BiDotsVerticalRounded size={20} />
                    </button>
                    {openMenuId === recipe._id && (
                        <RecipeMenu
                            recipe={recipe}
                            onClose={() => setOpenMenuId(null)}
                            style={getMenuPosition()}
                            onArchive={handleArchive}
                            onDelete={handleDelete}
                        />
                    )}
                </td>
            </tr>
        );
    };

    const recipePlanAllowance = () => {
        try {
            const totalRecipes = totalRecords + globalState.archived_recipes.length
            if (planName === "pro") return `${totalRecipes} recipes`
            if (planName === "legacy") return `${totalRecipes} / 500 recipes`
            if (planName === "basic") return `${totalRecipes} / 50 recipes`
        } catch (error) {
            return ""
        }
    }

    const renderPagination = () => {
        if (pagination.total_pages <= 1) return null;

        return (
            <div className="batch-table-pagination">
                <button 
                    className="batch-table-pagination-button"
                    disabled={!pagination.has_prev}
                    onClick={() => setPage(page - 1)}
                >
                    Previous
                </button>
                <span className="batch-table-pagination-info">
                    Page {pagination.current_page} of {pagination.total_pages}
                </span>
                <button 
                    className="batch-table-pagination-button"
                    disabled={!pagination.has_next}
                    onClick={() => setPage(page + 1)}
                >
                    Next
                </button>
            </div>
        );
    };

    const handleArchive = (recipe) => {
        setSelectedRecipe(recipe);
        setShowConfirmArchiveModal(true);
    };

    const handleDelete = (recipe) => {
        setSelectedRecipe(recipe);
        setShowConfirmDeleteModal(true);
    };

    const attemptRecipeArchive = async () => {
        if (!selectedRecipe) return;
        try {
            await api.archiveRecipe({ recipe_id: selectedRecipe._id });
            await fetchUserLibrary();
            fetchRecipes(); // Refresh the recipes table
            setShowConfirmArchiveModal(false);
        } catch (error) {
            console.error('Error archiving recipe:', error);
        }
    };

    const attemptRecipeDelete = async () => {
        if (!selectedRecipe) return;
        try {
            await api.deleteRecipe(selectedRecipe._id);
            await fetchUserLibrary();
            fetchRecipes(); // Refresh the recipes table
            setShowConfirmDeleteModal(false);
        } catch (error) {
            console.error('Error deleting recipe:', error);
        }
    };

    const planWarningText = () => {
        try {
            if (planName === "pro") return
            if (planName === "legacy" && totalRecords <= 200) return
            if (planName === "basic" && totalRecords <= 50) return
            return (
                <div className="recipes-table-plan-allowance-warning-container">
                    <p>Hey, you have more recipes than your current plan is allowed to show! <span>Upgrade to Pro.</span></p>
                </div>
            )
        } catch (error) {
            return null
        }
    }

    return (
        <div className="batch-table-container" style={{paddingTop: 0}}>
            {showConfirmArchiveModal && selectedRecipe && (
                <ModalConfirmAction 
                    hideModal={() => setShowConfirmArchiveModal(false)}
                    confirmButtonText="Archive recipe"
                    text={`Archive ${selectedRecipe.name}?\n\nThis will hide it from the main recipe list, but permanently remove it from any collections it is part of.`}
                    heading="⚠️ Archive Recipe"
                    takeActionFunction={attemptRecipeArchive}
                />
            )}

            {showConfirmDeleteModal && selectedRecipe && (
                <ModalConfirmAction 
                    hideModal={() => setShowConfirmDeleteModal(false)}
                    confirmButtonText="Delete recipe"
                    text={`Delete ${selectedRecipe.name}?\n\nThis will remove all batches and candles associated with this recipe too.`}
                    heading="⚠️ Delete Recipe"
                    takeActionFunction={attemptRecipeDelete}
                />
            )}
            
            {showFilterModal && (
                <CollectionFilterModal
                    collections={collections}
                    selectedCollections={selectedCollections}
                    onToggleCollection={toggleCollection}
                    onClose={() => setShowFilterModal(false)}
                />
            )}
            
            {/* Collection Pills or Filter Button */}
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "2px"}}>
                <div className="recipe-table-collections">
                    {showFilterButton ? (
                        <>
                            <button 
                                className="recipe-table-filter-button" 
                                onClick={() => setShowFilterModal(true)}
                            >
                                <BiFilter /> Filter by Collection
                            </button>
                            {selectedCollections.length > 0 && (
                                <div className="recipe-table-selected-collections">
                                    {collections
                                        .filter(c => selectedCollections.includes(c._id.toString()))
                                        .map(collection => (
                                            <span
                                                key={collection._id}
                                                className="recipe-table-collection-pill"
                                                style={{ backgroundColor: getBackgroundHexFromName(collection.color_name) }}
                                                onClick={() => toggleCollection(collection._id.toString())}
                                            >
                                                {collection.name}
                                                <BiX />
                                            </span>
                                        ))
                                    }
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="recipe-table-collection-pills">
                            {collections.map(collection => (
                                <span
                                    key={collection._id}
                                    className={`recipe-table-collection-pill ${
                                        selectedCollections.includes(collection._id.toString()) ? 'active' : ''
                                    }`}
                                    style={{ backgroundColor: getBackgroundHexFromName(collection.color_name) }}
                                    onClick={() => toggleCollection(collection._id.toString())}
                                >
                                    {collection.name}
                                </span>
                            ))}
                        </div>
                    )}
                </div>
                <div className="batch-table-search-container">
                    <input
                        type="text"
                        placeholder="Search by recipe name or ID"
                        value={searchInput}
                        onChange={handleSearch}
                        onKeyDown={handleSearchSubmit}
                        className="batch-table-search"
                    />
                    {searchInput && (
                        <div className="batch-table-search-clear" onClick={handleClearSearch}>
                            <BiX size={20} />
                        </div>
                    )}
                </div>
            </div>

            

            <div className="recipes-table-plan-allowance-container">
                {recipePlanAllowance()}
            </div>
            
            {loading ? (
                <div className="batch-table-loading">Loading...</div>
            ) : recipes.length === 0 ? (
                <div className="batch-table-empty">
                    <MdDocumentScanner className="batch-table-empty-icon"/>
                    <h3>No recipes found</h3>
                    {filters.search ? (
                        <p>Try adjusting your search</p>
                    ) : (
                        <p>Create your first recipe to get started</p>
                    )}
                    {filters.search && (
                        <button 
                            onClick={() => {
                                setSearchInput('');
                                setFilters({
                                    search: '',
                                    sortBy: 'updatedAt',
                                    sortOrder: 'desc'
                                });
                                setPage(1);
                            }}
                            className="batch-table-clear-filters"
                        >
                            Clear Filters
                        </button>
                    )}
                </div>
            ) : (
                <>
                    <div className="batch-table-wrapper">
                        <table className="batch-table">
                            <thead>
                                <tr>
                                    <th>{renderColumnHeader('name', 'Name')}</th>
                                    <th>{renderColumnHeader('visual_id', 'ID')}</th>
                                    <th>Collections</th>
                                    <th>Total Batches</th>
                                    <th className="recipe-table-manage-header">
                                        <div className="table-header-content">Manage</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {recipes.map((recipe) => (
                                    <RecipeTableRow key={recipe._id} recipe={recipe} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {renderPagination()}
                    {planWarningText()}
                </>
            )}
        </div>
    );
};

export default RecipesTable; 