import React from 'react';
import './CollectionFilterModal.css';
import { BiX } from 'react-icons/bi';
import { getBackgroundHexFromName } from '../../common/Helpers';

const CollectionFilterModal = ({ collections, selectedCollections, onToggleCollection, onClose }) => {
    return (
        <div className="collection-filter-modal-overlay" onClick={onClose}>
            <div className="collection-filter-modal" onClick={e => e.stopPropagation()}>
                <div className="collection-filter-modal-header">
                    <h3>Filter by Collection</h3>
                    <button className="collection-filter-modal-close" onClick={onClose}>
                        <BiX size={24} />
                    </button>
                </div>
                <div className="collection-filter-modal-content">
                    {collections.map(collection => (
                        <div
                            key={collection._id}
                            className={`collection-filter-item ${
                                selectedCollections.includes(collection._id.toString()) ? 'active' : ''
                            }`}
                            onClick={() => onToggleCollection(collection._id.toString())}
                        >
                            <span
                                className="collection-filter-color"
                                style={{ backgroundColor: getBackgroundHexFromName(collection.color_name) }}
                            />
                            <span className="collection-filter-name">{collection.name}</span>
                        </div>
                    ))}
                    {collections.length === 0 && (
                        <p className="collection-filter-empty">No collections found</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CollectionFilterModal; 