import React from 'react'
import Api from '../../common/APIUtils'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { useParams } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { convertCandleTypeRawToDisplay, getWaxSchema2, getFOSchema2, getSupplyById } from '../../common/Helpers'
import { useNavigate  } from 'react-router-dom'
import InternalNavigationSections from '../navigation/InternalNavigationSections'
import { HiOutlineDotsHorizontal } from 'react-icons/hi'
import CollectionCard from '../collections/CollectionCard'
import Notes from '../misc/Notes'
import SlimCandleCard from '../candles/SlimCandleCard'
import ModalConfirmAction from '../modals/ModalConfirmAction'
import ModalEditRecipe from '../modals/ModalEditRecipe'

export default function Recipe({globalState, dispatch, fetchUserLibrary}) {
    const api = new Api()
    const navigate = useNavigate();
    const [view, setView] = useState(() => { return "details" })
    const [recipe, setRecipe] = useState(() => { return false })
    const [recipeBatches, setRecipeBatches] = useState(() => { return [] })
    const [recipeCandles, setRecipeCandles] = useState(() => { return [] })
    const [recipeContainer, setRecipeContainer] = useState(() => { return false })
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(() => { return false })
    const [showConfirmArchiveModal, setShowConfirmArchiveModal] = useState(() => { return false })
    const [showEditModal, setShowEditModal] = useState(() => { return false })
    const [batchPreviewSize, setBatchPreviewSize] = useState(() => { return 10 })
    const [candlePreviewSize, setCandlePreviewSize] = useState(() => { return 10 })
    const { recipeId } = useParams()
    const getBatches = () => {
        try {
            // First try to use batches from direct API response
            if (recipeBatches && recipeBatches.length > 0) {
                const sortedBatches = recipeBatches.sort((a, b) => {
                    return parseISO(b.pour_date) - parseISO(a.pour_date)
                })
                return sortedBatches
            }
            
            // Fallback to globalState if no batches from API
            let allBatches = Array.from(globalState.batches)
            let relatedBatches = allBatches.filter((batch) => {
                if (batch.recipe_id.toString() === recipeId) return true
                return false
            })
            const sortedBatches = relatedBatches.sort((a, b) => {
                return parseISO(b.pour_date) - parseISO(a.pour_date)
            })
            return sortedBatches
        } catch (error) {
            return []
        }
    }
    const goToPath = (path) => {
        navigate(path)
    }
    const showMoreBatches = () => {
        setBatchPreviewSize(batchPreviewSize + 3)
    }
    const showMoreCandles = () => {
        setCandlePreviewSize(candlePreviewSize + 5)
    }
    const getCollectionsByRecent = () => {
        try {
            if (!globalState.collections) return []
            return globalState.collections.filter((collection) => {
                if (collection.recipe_ids.includes(recipeId)) return true
                return false
            })
        } catch (error) {
            return []
        }
    }
    const getAdditionalWickNameTextIfDouble = () => {
        try {
            if (recipe.wick_count > 1) return <span style={{color: "grey", letterSpacing: "2px"}}> x{recipe.wick_count}</span>
            if (recipe.wick_count === 2) return <span style={{color: "grey", letterSpacing: "2px"}}> x2</span>
            return false
        } catch (error) {
            return false
        }
    }
    const getWickBadge = () => {
        try {
            let additionalBadge = false
            if (recipe.wick_count === 2) additionalBadge = <div className="recipe-badge-decoration">Double Wick</div>
            if (recipe.wick_count === 3) additionalBadge = <div className="recipe-badge-decoration">Triple Wick</div>
            if (recipe.wick_count === 4) additionalBadge = <div className="recipe-badge-decoration">Quad Wick</div>
            return (
                <div className="util-row util-align-center">
                    <div className="recipe-badge-decoration">Recipe</div>
                    {additionalBadge}
                </div>
            )
        } catch (error) {
            return false
        }
    }
    const closeAllModals = () => {
        setShowEditModal(false)
    }
    const editRecipe = (payload) => {
        closeAllModals()
        api.updateRecipe(payload)
        .then((res) => {
            fetchUserLibrary()
        })
        .catch((err) => {
            console.log(err)
            fetchUserLibrary()
        })
        
    }
    const updateRecipeWithNotes = (notes) => {
        if (!recipeId) return
        api.updateRecipe({recipe_id: recipeId, notes: notes})
        .then((res) => {
            fetchUserLibrary()
        })
        .catch((err) => {
            console.error(err)
        })
    }
    const getCandles = () => {
        try {
            // First check if we have candles from direct API response
            if (recipeCandles && recipeCandles.length > 0) {
                const sortedCandles = recipeCandles.sort((a, b) => {
                    return parseISO(b.updatedAt) - parseISO(a.updatedAt)
                })
                return sortedCandles
            }
            
            // Fallback to globalState if no candles from API
            const allBatches = getBatches()
            const batchIds = allBatches.map(b => b._id)
            let allCandles = Array.from(globalState.candles)
            let relatedCandles = allCandles.filter((candle) => {
                if (batchIds.includes(candle.batch_id)) return true
                return false
            })
            const sortedCandles = relatedCandles.sort((a, b) => {
                return parseISO(b.updatedAt) - parseISO(a.updatedAt)
            })
            return sortedCandles
        } catch (error) {
            return []
        }
    }
    const goToBatchPage = (batchId) => {
        return navigate(`/candles/batch/${batchId}`)
    }
    const goToCandlePage = (candleId) => {
        return navigate(`/candles/candle/${candleId}`)
    }
    const createNewBatch = () => {
        return navigate(`/create/batch/${recipeId}`)
    }
    const attemptRecipeDelete = () => {
        if (!recipeId) return
        api.deleteRecipe(recipeId)
        .then((res) => { return navigate("/candles/recipes")})
        .catch((err) => { return })
    }
    const attemptRecipeArchive = () => {
        if (!recipeId) return
        api.archiveRecipe({recipe_id: recipeId})
        .then((res) => { return navigate("/candles/recipes")})
        .catch((err) => { return })
    }
    const handleAction = (actionName) => {
        if (actionName === "delete") return setShowConfirmDeleteModal(true)
        if (actionName === "archive") return setShowConfirmArchiveModal(true)
        if (actionName === "edit") return setShowEditModal(true)
        if (actionName === "duplicate") return navigate(`/create/recipe?based_on=${recipe._id}`)
    }
    const getRecipeFOBlends = () => {
        try {
            return recipe.fragrance_oil_blend_map.blends
        } catch (error) {
            return []
        }
    }
    const getWaxBlendLength = () => {
        try {
            if (!recipe) return 0
            return recipe.wax_blend_map.blends.length
        } catch (error) {
            return 0
        }
    }
    useEffect(() => {
        if (!recipe) return setRecipeContainer(false)
        if (!recipe.container_id) return setRecipeContainer(false)
        if (!globalState.supplies.length === 0) return setRecipeContainer(false)
        const containerSupply = getSupplyById(recipe.container_id, globalState.supplies)
        if (!containerSupply) return setRecipeContainer(false)
        setRecipeContainer(containerSupply)
    // eslint-disable-next-line
    }, [recipe])
    useEffect(() => {
        if (!recipeId) return

        const fetchRecipeFromApi = async () => {
            try {
                const api = new Api()
                const response = await api.getRecipeById(recipeId)
                if (response.data) {
                    // Handle the new response structure
                    if (response.data.recipe) {
                        setRecipe(response.data.recipe)
                        
                        // Set batches and candles if available
                        if (response.data.batches) {
                            setRecipeBatches(response.data.batches)
                        }
                        
                        if (response.data.candles) {
                            setRecipeCandles(response.data.candles)
                        }
                        
                        return true
                    } else {
                        // Handle legacy response format
                        setRecipe(response.data)
                        return true
                    }
                }
                return false
            } catch (error) {
                console.error("Error fetching recipe from API:", error)
                return false
            }
        }

        const fetchRecipeFromGlobalState = () => {
            try {
                const allRecipes = Array.from(globalState.recipes)
                const selectedRecipe = allRecipes.find(r => r._id.toString() === recipeId)
                if (selectedRecipe) {
                    setRecipe(selectedRecipe)
                    return true
                }
                return false
            } catch (error) {
                console.error("Error getting recipe from global state:", error)
                return false
            }
        }

        // First try to fetch directly from API
        fetchRecipeFromApi().then(success => {
            // If API fetch failed, fallback to global state
            if (!success) {
                fetchRecipeFromGlobalState()
            }
        })
    // eslint-disable-next-line
    }, [recipeId, globalState])
    useEffect(() => {
        if (!globalState.library_loaded) fetchUserLibrary()
    // eslint-disable-next-line
    }, [globalState])
    useEffect(() => {
        window.scrollTo(0,0);
        dispatch({type: "nav", payload: {view: "recipes"}})
    // eslint-disable-next-line
    }, [])
    return (
        <div className="general-page-create general-page-recipe">
            {recipe &&
            <>
            {showConfirmDeleteModal && <ModalConfirmAction hideModal={() => setShowConfirmDeleteModal(false)} confirmButtonText="Delete recipe" text={`Delete ${recipe.name}?  \n\n This will remove all batches and candles associated with this recipe too.`} heading="⚠️ Delete Recipe" takeActionFunction={attemptRecipeDelete} /> }
            {showConfirmArchiveModal && <ModalConfirmAction hideModal={() => setShowConfirmArchiveModal(false)} confirmButtonText="Archive recipe" text={`Archive ${recipe.name}?  \n\n This will hide it from the main recipe list, but permanently remove it from any collections it is part of.`} heading="⚠️ Archive Recipe" takeActionFunction={attemptRecipeArchive} /> }
            {showEditModal && <ModalEditRecipe globalState={globalState} hideModal={closeAllModals} recipe={recipe} sendEdits={editRecipe} batches={getBatches()}/>}
            <div className="category-page-header">
                <div className="category-page-heading-titles">
                    <h1>{recipe.name}</h1>
                    {getWickBadge()}
                </div>
                <div className="category-page-heading-actions" style={{gap: "20px"}}>
                    <div className="general-category-page-create" onClick={createNewBatch}>
                        + New batch
                    </div>
                    <EditRecipeMenu handleAction={handleAction}/>
                </div>
            </div>

            <InternalNavigationSections sections={["details", "notes", "batches", "candles"]} view={view} setView={setView} />

            {view === "details" &&
            <div className="general-page-create-section general-page-create-section-margin-remove">
                <div className="recipe-ingredients-list recipe-ingredients-list-detailed">
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Recipe Number</div>
                        <div className="recipe-ingredient-value">{recipe.visual_id}</div>
                    </div>
                    <div className="recipe-ingredient-row">

                        {(!recipe.schema || recipe.schema === 1) && <div className="recipe-ingredient-name">Wax</div>}

                        {recipe.schema === 2 &&
                        <div className="recipe-ingredient-name">
                            Wax
                            {getWaxBlendLength() > 1 && <span>Blend</span>}
                        </div>
                        }

                        
                        {(!recipe.schema || recipe.schema === 1) && <div className="recipe-ingredient-value">{recipe.wax}</div>}
                        {recipe.schema === 2 && getWaxSchema2(recipe, globalState.supplies)}
                    </div>

                    {(!recipe.schema || recipe.schema === 1 || !recipe.fragrance_oil_blend_map) &&
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Fragrance Oil</div>
                        <div className="recipe-ingredient-value">{recipe.fragrance_oil}</div>
                    </div>
                    }

                    {recipe.schema === 2 && recipe.fragrance_oil_blend_map && parseFloat(recipe.fragrance_load) > 0 &&
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            Fragrance Oil
                            {recipe.fragrance_oil_blend_map.blends.length > 1 && <span>Blend</span>}
                        </div>
                        {getFOSchema2(recipe, globalState.supplies)}
                    </div>
                     }

                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            Fragrance Load
                            {getRecipeFOBlends().length > 1 && <span>Total</span>}
                        </div>
                        <div className="recipe-ingredient-value">{recipe.fragrance_load}%</div>
                    </div>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Type</div>
                        <div className="recipe-ingredient-value">{convertCandleTypeRawToDisplay(recipe.recipe_type)}</div>
                    </div>

                    {recipe.wick_in_recipe && recipe.wick_name && 
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Wick</div>
                        <div className="recipe-ingredient-value">{recipe.wick_name}{getAdditionalWickNameTextIfDouble()}</div>
                    </div>
                    }

                    {recipe.wick_in_recipe && recipe.wick_id && 
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Wick</div>
                        <div className="recipe-ingredient-value general-supply-clickable" onClick={() => goToPath(`/candles/inventory/supplies/${recipe.wick_id}`)}>{getSupplyById(recipe.wick_id, globalState.supplies).supply_name}{getAdditionalWickNameTextIfDouble()}</div>
                    </div>
                    }

                    {/* Non-supplyitem Vessels */}
                    {recipe.recipe_type === "container" && recipe.container_name &&
                    <>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Vessel</div>
                        <div className="recipe-ingredient-value">{recipe.container_name}</div>
                    </div>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Vessel Capacity</div>
                        <div className="recipe-ingredient-value">{parseFloat(recipe.vessel_capacity_grams).toFixed(0)} g ({parseFloat(recipe.vessel_capacity_grams/28.3495).toFixed(2)} oz)</div>
                    </div>
                    </>
                    }

                    {/* Supplyitem Vessels */}
                    {recipeContainer && 
                    <>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Vessel</div>
                        <div className="recipe-ingredient-value general-supply-clickable" onClick={() => goToPath(`/candles/inventory/supplies/${recipeContainer._id}`)}>{recipeContainer.supply_name}</div>
                    </div>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Vessel Capacity</div>
                        <div className="recipe-ingredient-value">{parseFloat(recipeContainer.properties.vessel_capacity_grams).toFixed(0)} g ({parseFloat(recipeContainer.properties.vessel_capacity_grams/28.3495).toFixed(2)} oz)</div>
                    </div>
                    </>
                    }

                    {!recipe.color_in_recipe &&
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Color</div>
                        <div className="recipe-ingredient-value">None</div>
                    </div>
                    }
                    {recipe.color_in_recipe && !recipe.color_map &&
                    <>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Color</div>
                        <div className="recipe-ingredient-value">{recipe.color_name}</div>
                    </div>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            Color Density
                            <span>{recipe.color_units} per Pound</span>
                        </div>
                        <div className="recipe-ingredient-value">{recipe.color_drops_per_pound}</div>
                    </div>
                    </>
                    }
                    {recipe.color_in_recipe && recipe.color_map &&
                    <>
                    <div className="recipe-ingredient-row" style={{borderBottom: "0px solid transparent", paddingBottom: "0px"}}>
                        <div className="recipe-ingredient-name">Color Design</div>
                        <div className="recipe-ingredient-value"></div>
                    </div>
                    <div>
                        <div className="general-page-create-option-small-headings" style={{margin: "0px"}}>
                            <span>Name</span>
                            <span>{recipe.color_units} per Pound</span>
                        </div>
                        {recipe.color_map.colors.map((color, index) => (
                            <div className="recipe-ingredient-row" key={index} style={{margin: "4px 0px"}}>
                                <div className="recipe-ingredient-value">{color.color_name}</div>
                                <div className="recipe-ingredient-value">{color.color_drops_per_pound}</div>
                            </div>
                        ))}
                        
                    </div>
                    </>
                    }
                </div>

                {getCollectionsByRecent().length > 0 &&
                <div className="recipe-collections-list">
                    <h3>Collections</h3>
                    <div className="general-card-list-collections" style={{marginTop: "16px"}}>
                        {getCollectionsByRecent().map((collection) => (
                            <CollectionCard key={collection._id} collection={collection} navLocation={goToPath} globalState={globalState}/>
                        ))}
                    </div>
                </div>
                }
            </div>
            }

            {view === "batches" &&
            <div className="general-page-create-section">
                <div className="slim-batch-items">
                    {getBatches().slice(0, batchPreviewSize).map((batch) => (
                        <SlimBatchItem key={batch._id} batch={batch} goToBatchPage={goToBatchPage}/>
                    ))}
                    {batchPreviewSize < getBatches().length && 
                    <div className="general-list-show-more-button" onClick={showMoreBatches}>Show More</div>
                    }
                    {getBatches().length === 0 &&
                    <div className="general-card-list-text-empty">Create a batch to use this recipe</div>
                    }
                </div>
            </div>
            }

            {view === "candles" &&
            <div className="general-page-create-section">
                <div className="general-card-list" style={{gap: 0}}>
                    {getCandles().slice(0, candlePreviewSize).map((candle) => (
                        <SlimCandleCard key={candle._id} candle={candle} recipe={recipe} goToCandlePage={goToCandlePage} globalState={globalState} />
                    ))}
                    {candlePreviewSize < getCandles().length && 
                    <div className="general-list-show-more-button" onClick={showMoreCandles}>Show More</div>
                    }
                    {getCandles().length === 0 &&
                    <div className="general-card-list-text-empty">You haven't made any candles with this recipe yet</div>
                    }
                </div>
            </div>
            }

            {view === "notes" &&
            <div className="general-page-create-section">
                <div>
                    <Notes notes={recipe.notes} updateNote={updateRecipeWithNotes} baseId={recipe._id}/>
                </div>
            </div>
            }
            </>}
        </div>
    )
}

function SlimBatchItem({batch, goToBatchPage}) {
    const getDateAsString = () => {
        try {
            return formatDistanceToNow(parseISO(batch.pour_date))
        } catch (error) {
            return ""
        }
    }
    return (
        <div className="slim-batch-item-container" onClick={() => goToBatchPage(batch._id)}>
            <div className="slim-batch-item">
                <p>{batch.name}</p>
                <span>Poured {getDateAsString()} ago</span>
            </div>
            <HiArrowNarrowRight/>
        </div>
    )
}

function EditRecipeMenu({handleAction}) {
    const editMenuRef = useRef(null)
    const [isShowingMenu, setIsShowingMenu] = useState(() => { return false })
    const takeAction = (actionName) => {
        setIsShowingMenu(false)
        handleAction(actionName)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (editMenuRef.current && !editMenuRef.current.contains(event.target)) {
                setIsShowingMenu(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [editMenuRef])
    return (
        <div ref={editMenuRef}>
            <div className="edit-menu-container">
                <span className="general-top-right-button-options" onClick={() => setIsShowingMenu(!isShowingMenu)}><HiOutlineDotsHorizontal/></span>
                {isShowingMenu && 
                <div className="edit-menu-options">
                    <div onClick={() => takeAction("edit")}>Edit</div>
                    <div onClick={() => takeAction("duplicate")}>Duplicate</div>
                    <div onClick={() => takeAction("archive")}>Archive</div>
                    <div onClick={() => takeAction("delete")}>Delete</div>
                </div>
                }
            </div>
            
        </div>
        
    )
}