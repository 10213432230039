import React from 'react'
import Api from '../../common/APIUtils'
import { parseISO, differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, formatDistanceToNow, format } from 'date-fns'
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getWaxSchema2, getFOSchema2, getSupplyById, formatCurrencyByType } from '../../common/Helpers'
import { useSubscription } from '../../context/SubscriptionContext'
import Notes from '../misc/Notes'
import InternalNavigationSections from '../navigation/InternalNavigationSections'
import ModalConfirmAction from '../modals/ModalConfirmAction'
import NotificationSetupModal from '../modals/NotificationSetupModal'
import BatchSuppliesPane from './BatchSuppliesPane'
import BatchStageSelector from './BatchStageSelector'
import BatchPlanSection from './BatchPlanSection'
import BatchRenameModal from './BatchRenameModal'
import './Batch.css'
import LoadingSymbol from '../misc/LoadingSymbol'
import { GrTest, GrCompliance, GrNotes, GrTrash } from "react-icons/gr";
import { IoNotifications } from "react-icons/io5";
import BatchPlanDuplicateModal from './BatchPlanDuplicateModal'
import { MdOutlineClear } from "react-icons/md";
import BatchTimeline from './BatchTimeline'

// Custom hook to manage batch logic and state
function useBatchLogic({ globalState, dispatch, fetchUserLibrary }) {
    const api = new Api()
    const navigate = useNavigate()
    const { batchId } = useParams()
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
    const [showRenameModal, setShowRenameModal] = useState(false)
    const [view, setView] = useState('overview')
    const [batch, setBatch] = useState(false)
    const [candles, setCandles] = useState([])
    const [recipe, setRecipe] = useState(false)
    const [recipeContainer, setRecipeContainer] = useState(false)
    const [candlePreviewSize, setCandlePreviewSize] = useState(8)
    const [cureTime, setCureTime] = useState('')
    const [batchPlan, setBatchPlan] = useState(false)
    const [isCreatingBatchPlan, setIsCreatingBatchPlan] = useState(false)
    const [recentBatchPlans, setRecentBatchPlans] = useState([])
    const [showDuplicateModal, setShowDuplicateModal] = useState(false)
    const [showNotificationModal, setShowNotificationModal] = useState(false)
    const [isSettingNotification, setIsSettingNotification] = useState(false)

    const showMoreCandles = () => {
        setCandlePreviewSize(candlePreviewSize + 5)
    }

    const getCandles = () => {
        try {
            return candles
        } catch (error) {
            return []
        }
    }

    const goToPath = (path) => {
        navigate(path)
    }

    const fetchBatch = () => {
        try {
            if (!batchId) return
            api.getBatchById(batchId)
            .then((res) => {
                if (res.data) {
                    if (res.data._id) {
                        setBatch(res.data)
                    }
                }
            })
        } catch (error) {
            return
        }
    }

    const attemptRecipeDelete = () => {
        if (!batchId) return
        api.deleteBatch(batchId)
        .then((res) => {
            fetchUserLibrary()
            navigate('/candles/batches')
        })
        .catch((err) => {
            console.error(err)
        })
    }

    const getAdditionalWickNameTextIfDouble = () => {
        try {
            if (recipe.wick_count > 1) return <span style={{color: "grey", letterSpacing: "2px"}}> x{recipe.wick_count}</span>
            if (recipe.wick_count === 2) return <span style={{color: "grey", letterSpacing: "2px"}}> x2</span>
            return false
        } catch (error) {
            return false
        }
    }

    const getContainer = () => {
        try {
            const theRecipe = batch.recipe
            if (theRecipe.recipe_type === "wax_melt") return "Clamshell Mold"
            if (theRecipe.container_name) return theRecipe.container_name
            return "Mold"
        } catch (error) {
            return false
        }
    }

    const updateBatchWithNotes = (notes) => {
        api.updateBatch({batch_id: batchId, notes: notes})
        .then((res) => {
            fetchUserLibrary()
        })
        .catch((err) => {
            console.error(err)
        })
    }

    const handleStageChange = async (newStage) => {
        const updatedBatch = { ...batch, stage: newStage };
        try {
            await api.updateBatch({
                batch_id: batch._id,
                stage: newStage
            });
            setBatch(updatedBatch);
            fetchBatch();
        } catch (err) {
            console.error('Error in handleStageChange:', {
                error: err,
                errorMessage: err.message,
                errorResponse: err.response?.data
            });
        }
    }

    const formatCureTime = (start) => {
        const now = new Date();
        const days = differenceInDays(now, start);
        const hours = differenceInHours(now, start) % 24;
        const minutes = differenceInMinutes(now, start) % 60;
        const seconds = differenceInSeconds(now, start) % 60;
        const totalHours = differenceInHours(now, start);
        
        if (totalHours < 1) {
            return `${minutes}m ${seconds}s`;
        }
        
        if (days < 1) {
            return `${hours}h ${minutes}m`;
        }
        
        return `${days}d ${hours}h ${minutes}m`;
    }

    const fetchBatchPlan = async () => {
        try {
            const response = await api.getBatchPlan(batchId)
            if (response.data) {
                if (response.data._id) {
                    setBatchPlan(response.data)
                } else {
                    await fetchRecentBatchPlans()
                }
            }
        } catch (error) {
            console.log('[fetchBatchPlan] Error:', error)
        }
    }

    const fetchRecentBatchPlans = async () => {
        try {
            const response = await api.getRecentBatchPlans()
            if (response.data) {
                setRecentBatchPlans(response.data)
            }
        } catch (error) {
            console.log('[fetchRecentBatchPlans] Error:', error)
            setRecentBatchPlans([])
        }
    }

    const createNewBatchPlan = async () => {
        if (!batchId || isCreatingBatchPlan) return
        
        setIsCreatingBatchPlan(true)
        try {
            const response = await api.createBatchPlan(batchId)
            if (response.data) {
                setBatchPlan(response.data)
            } else {
                await fetchBatchPlan()
            }
        } catch (error) {
            console.log('[createNewBatchPlan] Error:', error)
        } finally {
            setIsCreatingBatchPlan(false)
        }
    }

    const handleDuplicateSelect = async (sourceBatchId) => {
        if (!batchId || isCreatingBatchPlan) return
        
        setIsCreatingBatchPlan(true)
        setShowDuplicateModal(false)
        try {
            const response = await api.duplicateBatchPlan(batchId)
            if (response.data) {
                setBatchPlan(response.data)
            } else {
                await fetchBatchPlan()
            }
        } catch (error) {
            console.log('[handleDuplicateSelect] Error:', error)
        } finally {
            setIsCreatingBatchPlan(false)
        }
    }

    const handleSetupNotification = () => {
        setShowNotificationModal(true);
    }

    const handleNotificationConfirm = async (notificationData) => {
        if (!batch || isSettingNotification) return;
        
        setIsSettingNotification(true);
        try {
            const payload = {
                batch_id: batch._id,
                notification_set: true,
                notification_not_before_timestamp: notificationData.date,
                notification_type: notificationData.type,
                notification_length_days: notificationData.type === 'length' ? notificationData.lengthDays : undefined
            };

            const response = await api.updateBatch(payload);
            if (response.data) {
                // Preserve existing batch data while updating notification fields
                setBatch({
                    ...batch,
                    notification_set: response.data.notification_set,
                    notification_not_before_timestamp: response.data.notification_not_before_timestamp,
                    notification_type: response.data.notification_type,
                    notification_length_days: response.data.notification_length_days
                });
                // await fetchUserLibrary();
            }
        } catch (error) {
            console.error('Error setting notification:', error);
        } finally {
            setIsSettingNotification(false);
            setShowNotificationModal(false);
        }
    }

    const handleCancelNotification = async () => {
        if (!batch || isSettingNotification) return;
        
        setIsSettingNotification(true);
        try {
            const payload = {
                batch_id: batch._id,
                notification_set: false,
                notification_not_before_timestamp: null,
                notification_type: null,
                notification_length_days: null
            };

            const response = await api.updateBatch(payload);
            if (response.data) {
                // Preserve existing batch data while updating notification fields
                setBatch({
                    ...batch,
                    notification_set: false,
                    notification_not_before_timestamp: null,
                    notification_type: null,
                    notification_length_days: null
                });
                await fetchUserLibrary();
            }
        } catch (error) {
            console.error('Error canceling notification:', error);
            // We'll handle error UI in a future task
        } finally {
            setIsSettingNotification(false);
        }
    }

    const getNotificationDisplay = () => {
        if (!batch?.notification_set) return null;

        const notificationDate = new Date(batch.notification_not_before_timestamp);
        const dateStr = format(notificationDate, 'MMM d');
        
        if (batch.notification_type === 'length') {
            return `Notify after ${batch.notification_length_days} days of curing (${dateStr})`;
        }
        return `Custom notification on ${dateStr}`;
    }

    const handleRenameBatch = async (newName) => {
        if (!batch || !batchId) return;
        
        // Optimistically update the UI
        const updatedBatch = { ...batch, name: newName };
        setBatch(updatedBatch);
        setShowRenameModal(false);
        
        try {
            // Send the update to the backend
            await api.updateBatch({
                batch_id: batchId,
                name: newName
            });
            
            // Refresh user library to update any references to this batch
            fetchUserLibrary();
        } catch (err) {
            console.error('Error in handleRenameBatch:', {
                error: err,
                errorMessage: err.message,
                errorResponse: err.response?.data
            });
            
            // If there's an error, revert to the original name
            setBatch(batch);
            // Could also show an error toast/notification here
        }
    };

    useEffect(() => {
        let timer;
        if (batch?.stage === 'CURING' && batch.cure_start_timestamp) {
            const updateTimer = () => {
                const start = new Date(batch.cure_start_timestamp);
                const totalHours = differenceInHours(new Date(), start);
                const interval = totalHours < 1 ? 1000 : 60000;
                
                setCureTime(formatCureTime(start));
                timer = setTimeout(updateTimer, interval);
            };
            
            updateTimer();
        }
        
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [batch?.stage, batch?.cure_start_timestamp]);

    useEffect(() => {
        if (!recipe) return setRecipeContainer(false)
        if (!recipe.container_id) return setRecipeContainer(false)
        if (!globalState.supplies.length === 0) return setRecipeContainer(false)
        const containerSupply = getSupplyById(recipe.container_id, globalState.supplies)
        if (!containerSupply) return setRecipeContainer(false)
        setRecipeContainer(containerSupply)
    }, [recipe, globalState.supplies])

    useEffect(() => {
        if (!globalState.library_loaded) fetchUserLibrary()
    }, [globalState.library_loaded, fetchUserLibrary])

    useEffect(() => {
        if (!batch) return
        if (!batch.recipe) return
        if (batch.candles) {
            if (Array.isArray(batch.candles)) setCandles(batch.candles)
        }
        setRecipe(batch.recipe)
    }, [batch])

    useEffect(() => {
        if (batchId) {
            fetchBatch()
            fetchBatchPlan()
        }
    // eslint-disable-next-line
    }, [batchId])

    useEffect(() => {
        window.scrollTo(0,0);
        dispatch({type: "nav", payload: {view: "batches"}})
    }, [dispatch])

    const getSubtitleText = () => {
        try {
            if (!batch) return '';
        
            if (batch.stage === 'CURING' && batch.cure_start_timestamp) {
                return `Cure Timer: ${cureTime}`;
            }
            if (batch.updatedAt) {
                return `Last updated ${formatDistanceToNow(parseISO(batch.updatedAt))} ago`
            }
        } catch (error) {
            return ""
        }
    };

    return {
        batch,
        recipe,
        recipeContainer,
        view,
        setView,
        showConfirmDeleteModal,
        setShowConfirmDeleteModal,
        showRenameModal,
        setShowRenameModal,
        candlePreviewSize,
        cureTime,
        batchPlan,
        setBatchPlan,
        isCreatingBatchPlan,
        handleStageChange,
        handleRenameBatch,
        attemptRecipeDelete,
        getCandles,
        showMoreCandles,
        getContainer,
        getAdditionalWickNameTextIfDouble,
        updateBatchWithNotes,
        goToPath,
        getSubtitleText,
        globalState,
        fetchUserLibrary,
        createNewBatchPlan,
        recentBatchPlans,
        showDuplicateModal,
        setShowDuplicateModal,
        handleDuplicateSelect,
        showNotificationModal,
        setShowNotificationModal,
        isSettingNotification,
        handleSetupNotification,
        handleNotificationConfirm,
        handleCancelNotification,
        getNotificationDisplay,
        fetchBatch
    }
}

// Add this new component for displaying COGS breakdown
function COGSBreakdownTable({ cogsSchedule, totalCostPennies }) {
    const { userSettings } = useSubscription();
    const currency = userSettings?.inventory_units_currency || 'dollar';
    
    if (!cogsSchedule || cogsSchedule.length === 0 || !totalCostPennies || totalCostPennies <= 0) {
        return null;
    }

    return (
        <div className="batch-cogs-breakdown">
            <table className="batch-cogs-breakdown-table">
                <tbody>
                    <tr className="batch-cogs-total-row">
                        <td><strong>Total</strong></td>
                        <td><strong>{formatCurrencyByType(totalCostPennies, currency)}</strong></td>
                    </tr>
                    {cogsSchedule.map((item, index) => (
                        <tr key={index}>
                            <td>{item.component_name}</td>
                            <td>{formatCurrencyByType(item.component_cogs_pennies, currency)}</td>
                        </tr>
                    ))}
                    
                </tbody>
            </table>
        </div>
    );
}

export default function Batch({globalState, dispatch, fetchUserLibrary}) {
    const batchLogic = useBatchLogic({ globalState, dispatch, fetchUserLibrary })
    
    return (
        <div className="general-page-create general-page-recipe general-page-batch-container" style={{marginBottom: "60px"}}>
            <MobileBatchPage {...batchLogic} />
            <DesktopBatchPage {...batchLogic} />
        </div>
    )
}

function MobileBatchPage(props) {
    const {
        batch,
        recipe,
        recipeContainer,
        view,
        setView,
        showConfirmDeleteModal,
        setShowConfirmDeleteModal,
        showRenameModal,
        setShowRenameModal,
        batchPlan,
        setBatchPlan,
        candlePreviewSize,
        isCreatingBatchPlan,
        handleStageChange,
        handleRenameBatch,
        attemptRecipeDelete,
        getCandles,
        showMoreCandles,
        getContainer,
        getAdditionalWickNameTextIfDouble,
        updateBatchWithNotes,
        goToPath,
        getSubtitleText,
        globalState,
        fetchUserLibrary,
        createNewBatchPlan,
        recentBatchPlans,
        showDuplicateModal,
        setShowDuplicateModal,
        handleDuplicateSelect,
        showNotificationModal,
        setShowNotificationModal,
        handleSetupNotification,
        handleNotificationConfirm,
        handleCancelNotification,
        fetchBatch
    } = props;
    return (
        <div className="batch-mobile-container batch-page-container-mobile">
            {batch &&
            <>
            {showConfirmDeleteModal && <ModalConfirmAction hideModal={() => setShowConfirmDeleteModal(false)} confirmButtonText="Delete batch" text={`Delete batch ${batch.name}?  \n\n This will also permanently remove all candles associated with this batch.`} heading="⚠️ Delete Batch" takeActionFunction={attemptRecipeDelete} /> }
            {showNotificationModal && (
                <NotificationSetupModal
                    hideModal={() => setShowNotificationModal(false)}
                    onConfirm={handleNotificationConfirm}
                    onCancel={handleCancelNotification}
                    initialDate={batch.notification_not_before_timestamp}
                    cureStartTimestamp={batch.cure_start_timestamp}
                    isStageChange={batch.stage === 'CURING' && !batch.notification_set}
                />
            )}
            {showRenameModal && (
                <BatchRenameModal
                    hideModal={() => setShowRenameModal(false)}
                    currentName={batch.name}
                    onConfirm={handleRenameBatch}
                    onCancel={() => setShowRenameModal(false)}
                />
            )}
            <div className="general-page-create-section general-page-create-section-borderless general-page-create-section-heading">
                <div className="batch-header-container">              
                    <div className="batch-title-row">
                        <h1 
                            style={{marginTop: 0, cursor: 'pointer'}} 
                            onClick={() => setShowRenameModal(true)}
                            title="Tap to rename batch"
                        >
                            {batch.name}
                        </h1>
                        <BatchStageSelector batch={batch} onStageChange={handleStageChange} />
                    </div>
                    <div className="batch-subtitle" style={{marginTop: "4px", marginBottom: "4px"}}>
                        <div className="batch-subtitle-row">
                            <p style={{fontSize: "12px", margin: 0}}>{getSubtitleText()}</p>
                            <div 
                                className="batch-mobile-notification-link" 
                                onClick={handleSetupNotification}
                            >
                                {batch.notification_set 
                                    ? `Notification set for ${format(new Date(batch.notification_not_before_timestamp), 'MMMM d, yyyy')}` 
                                    : 'Notify me'
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
            }
            <div style={{borderBottom: "1px solid var(--ml-color-borders)", marginBottom: "20px"}}>
                <InternalNavigationSections view={view} setView={setView} sections={["overview", "production", "tests", "notes"]} />
            </div>
            
            {batch &&
            <div>
                {view === "recipe" && recipe &&
                <div className="general-page-create-section general-page-create-section-margin-remove">
                    <div className="recipe-ingredients-list recipe-ingredients-list-detailed">
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Name</div>
                            <div className="recipe-ingredient-value">{batch.recipe.name}</div>
                        </div>
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Recipe Number</div>
                            <div className="recipe-ingredient-value">{batch.recipe.visual_id}</div>
                        </div>
                        <div className="recipe-ingredient-row">
                            {(!batch.recipe.schema || batch.recipe.schema === 1) && 
                            <>
                                <div className="recipe-ingredient-name">Wax</div>
                                <div className="recipe-ingredient-value">{batch.recipe.wax}</div>
                            </>
                            }
                            {batch.recipe.schema === 2 &&
                            <>
                                <div className="recipe-ingredient-name">
                                    Wax
                                    {batch.recipe.wax_blend_map.blends.length > 1 && <span>Blend</span>}
                                </div>
                                {getWaxSchema2(batch.recipe, globalState.supplies)}
                            </>
                            }
                        </div>

                        {(!batch.recipe.schema || batch.recipe.schema === 1 || !batch.recipe.fragrance_oil_blend_map) && parseFloat(batch.recipe.fragrance_load) > 0 &&
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Fragrance Oil</div>
                            <div className="recipe-ingredient-value">{batch.recipe.fragrance_oil}</div>
                        </div>
                        }

                        {batch.recipe.schema === 2 && batch.recipe.fragrance_oil_blend_map && parseFloat(batch.recipe.fragrance_load) > 0 &&
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">
                                Fragrance Oil
                                {batch.recipe.fragrance_oil_blend_map.blends.length > 1 && <span>Blend</span>}
                            </div>
                            {getFOSchema2(batch.recipe, globalState.supplies)}
                        </div>
                        }
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Fragrance Load</div>
                            <div className="recipe-ingredient-value">{batch.recipe.fragrance_load}%</div>
                        </div>

                        {/* Non-supplyitem Vessels */}
                        {recipe.container_name && recipe.recipe_type === "container" &&
                        <>
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Container</div>
                            <div className="recipe-ingredient-value">{getContainer()}</div>
                        </div>
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Capacity</div>
                            <div className="recipe-ingredient-value">{parseFloat(batch.recipe.vessel_capacity_grams).toFixed(0)} g ({parseFloat(batch.recipe.vessel_capacity_grams/28.3495).toFixed(2)} oz)</div>
                        </div>
                        </>
                        }

                        {/* SupplyItem Vessels */}
                        {recipeContainer &&
                        <>
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Container</div>
                            <div className="recipe-ingredient-value general-supply-clickable" onClick={() => goToPath(`/candles/inventory/supplies/${recipeContainer._id}`)}>{recipeContainer.supply_name}</div>
                        </div>
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Capacity</div>
                            <div className="recipe-ingredient-value">{parseFloat(recipeContainer.properties.vessel_capacity_grams).toFixed(0)} g ({parseFloat(recipeContainer.properties.vessel_capacity_grams/28.3495).toFixed(2)} oz)</div>
                        </div>
                        </>
                        }

                        {/* Wick */}
                        {recipe.wick_in_recipe && recipe.wick_name && 
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Wick</div>
                            <div className="recipe-ingredient-value">{recipe.wick_name}{getAdditionalWickNameTextIfDouble()}</div>
                        </div>
                        }

                        {recipe.wick_in_recipe && recipe.wick_id && 
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Wick</div>
                            <div className="recipe-ingredient-value general-supply-clickable" onClick={() => goToPath(`/candles/inventory/supplies/${recipe.wick_id}`)}>{getSupplyById(recipe.wick_id, globalState.supplies).supply_name}{getAdditionalWickNameTextIfDouble()}</div>
                        </div>
                        }

                        {!batch.recipe.color_in_recipe &&
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Color</div>
                            <div className="recipe-ingredient-value">None</div>
                        </div>
                        }
                        {batch.recipe.color_in_recipe && !batch.recipe.color_map &&
                        <>
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Color</div>
                            <div className="recipe-ingredient-value">{batch.recipe.color_name}</div>
                        </div>
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">
                                Color Density
                                <span>{batch.recipe.color_units} per Pound</span>
                            </div>
                            <div className="recipe-ingredient-value">{batch.recipe.color_drops_per_pound}</div>
                        </div>
                        </>
                        }
                        {batch.recipe.color_in_recipe && batch.recipe.color_map &&
                        <>
                        <div className="recipe-ingredient-row" style={{borderBottom: "0px solid transparent", paddingBottom: "0px"}}>
                            <div className="recipe-ingredient-name">Color Design</div>
                            <div className="recipe-ingredient-value"></div>
                        </div>
                        <div>
                            <div className="general-page-create-option-small-headings" style={{margin: "0px"}}>
                                <span>Color Name</span>
                                <span>{batch.recipe.color_units} per Pound</span>
                            </div>
                            {batch.recipe.color_map.colors.map((color, index) => (
                                <div className="recipe-ingredient-row" key={index} style={{margin: "4px 0px"}}>
                                    <div className="recipe-ingredient-value">{color.color_name}</div>
                                    <div className="recipe-ingredient-value">{color.color_drops_per_pound}</div>
                                </div>
                            ))}
                        </div>
                        </>
                        }
                    </div>
                    <div className="general-list-show-more-button" onClick={() => goToPath(`/recipe/${recipe._id}`)}>Go to recipe</div>
                </div>
                }

                {view === "overview" &&
                <div>               
                    <div className="batch-desktop-split">
                        <div className="batch-desktop-split-section" style={{marginTop: 0, paddingTop: 0}}>
                            <div>
                                <h2>Supplies</h2>
                                <BatchSuppliesPane fetchBatch={fetchBatch} recipeContainer={recipeContainer} recipe={recipe} batch={batch} fetchUserLibrary={fetchUserLibrary} globalState={globalState}/>
                            </div>

                            {(batch.estimated_cogs_pennies && batch.estimated_cogs_pennies > 0) ?
                            <div>
                                <h2>Material Cost of Goods</h2>
                                <COGSBreakdownTable cogsSchedule={batch.estimated_cogs_schedule} totalCostPennies={batch.estimated_cogs_pennies} />
                            </div>
                            : <></>
                            }

                            <div>
                                <h2>Recipe</h2>
                                <div style={{display: "flex"}}>
                                    <div className="batch-desktop-clickable-item-title" onClick={() => goToPath(`/recipe/${recipe._id}`)}>{recipe.name}</div>
                                </div>
                            </div>

                            <div>
                                <h2>Candles</h2>
                                <div className="general-card-list batch-desktop-candle-list">
                                    {getCandles().slice(0, candlePreviewSize).map((candle) => (

                                    <div style={{display: "flex", flexDirection: "column", gap: "4px"}} key={candle._id}>
                                        <div className="batch-desktop-clickable-item-title candle" onClick={() => goToPath(`/candles/candle/${candle._id}`)}>
                                            <div>{candle.name}</div>
                                            <span>→</span>
                                        </div>
                                    </div>

                                    ))}
                                    {candlePreviewSize < getCandles().length && 
                                    <div className="general-list-show-more-button" onClick={showMoreCandles}>Show More</div>
                                    }
                                </div>
                            </div>
                            {batch.stage_history && batch.stage_history.length > 0 &&
                            <div className="general-page-create-section general-page-batch-create-section">
                                <BatchTimeline stageHistory={batch.stage_history} />
                            </div>
                            }
                        </div>
                    </div>

                </div>
                }

                {view === "notes" &&
                <div className="general-page-create-section">
                    <div>
                        <Notes notes={batch.notes} updateNote={updateBatchWithNotes} baseId={batch._id}/>
                    </div>
                </div>
                }

                {view === "production" && batchPlan && (
                    <div className="batch-mobile-section">
                        {batchPlan ? (
                            <BatchPlanSection
                                batchPlan={batchPlan}
                                batchId={batch._id}
                                onUpdate={setBatchPlan}
                                recipe={recipe}
                            />
                        ) : (
                            <div className="batch-plan-actions">
                                <button 
                                    className="batch-plan-create" 
                                    onClick={createNewBatchPlan}
                                    disabled={isCreatingBatchPlan}
                                >
                                    {isCreatingBatchPlan ? 'Creating...' : 'Create Production Plan'}
                                </button>
                                <button
                                    className="batch-plan-duplicate"
                                    onClick={() => setShowDuplicateModal(true)}
                                >
                                    Duplicate Recent Plan
                                </button>
                            </div>
                        )}
                    </div>
                )}

                {view === "production" && !batchPlan &&
                <div className="batch-desktop-production-empty">
                    {isCreatingBatchPlan ? (
                        <LoadingSymbol />
                    ) : (
                        <>
                            <div style={{color: "var(--ml-color-dark)", margin: "0 12px", textAlign: "center"}}>Production notes help you track and manage your process</div>
                            <div className="batch-desktop-production-empty-button" onClick={createNewBatchPlan}>Create new production notes</div>
                            {recentBatchPlans.length > 0 && (
                                <div className="batch-desktop-production-empty-button-text" onClick={() => setShowDuplicateModal(true)}>
                                    Copy from existing batch
                                </div>
                            )}
                            {showDuplicateModal && (
                                <BatchPlanDuplicateModal
                                    onClose={() => setShowDuplicateModal(false)}
                                    onSelect={handleDuplicateSelect}
                                    recentBatchPlans={recentBatchPlans}
                                />
                            )}
                        </>
                    )}
                </div>
                }

            {view === "tests" &&
            <div className="batch-desktop-production-empty" style={{margin: "0 12px"}}>
                <p style={{maxWidth: "600px", textAlign: "center", fontSize: "16px"}}>Coming soon for members with a <span style={{fontWeight: 600}}>Pro subscription</span></p>
                <p style={{maxWidth: "600px", textAlign: "center", fontSize: "12px"}}>Test individual candles! Record notes and check your work against the ASTM 2417 safety test standards.</p>
            </div>
            }
                
                {view === "overview" &&
                <div className="general-card-bottom-text-links">
                    <span onClick={() => setShowConfirmDeleteModal(true)}>Delete Batch</span>
                </div>
                }
            </div>
            }
        </div>
    )
}

function DesktopBatchPage(props) {
    const {
        batch,
        recipe,
        recipeContainer,
        view,
        setView,
        showConfirmDeleteModal,
        setShowConfirmDeleteModal,
        showRenameModal,
        setShowRenameModal,
        candlePreviewSize,
        batchPlan,
        setBatchPlan,
        isCreatingBatchPlan,
        handleStageChange,
        handleRenameBatch,
        attemptRecipeDelete,
        getCandles,
        showMoreCandles,
        updateBatchWithNotes,
        goToPath,
        getSubtitleText,
        globalState,
        fetchUserLibrary,
        createNewBatchPlan,
        recentBatchPlans,
        showDuplicateModal,
        setShowDuplicateModal,
        handleDuplicateSelect,
        showNotificationModal,
        setShowNotificationModal,
        handleSetupNotification,
        handleNotificationConfirm,
        handleCancelNotification,
        getNotificationDisplay,
        fetchBatch
    } = props;
    const views = [
        {id: "overview", name: "Overview", symbol: false },
        {id: "production", name: "Production", symbol: <GrCompliance/>},
        {id: "tests", name: "Tests", symbol: <GrTest/>},
        {id: "notes", name: "Notes", symbol: <GrNotes/>}
    ]
    if (!batch) return (
        <div className="batch-page-container-desktop">
            <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: "20vh"}}><LoadingSymbol/></div>
        </div>
    )
    return (
        <div className="batch-desktop-container batch-page-container-desktop">
            {showConfirmDeleteModal && <ModalConfirmAction hideModal={() => setShowConfirmDeleteModal(false)} confirmButtonText="Delete batch" text={`Delete batch ${batch.name}?  \n\n This will also permanently remove all candles associated with this batch.`} heading="⚠️ Delete Batch" takeActionFunction={attemptRecipeDelete} /> }
            {showNotificationModal && (
                <NotificationSetupModal
                    hideModal={() => setShowNotificationModal(false)}
                    onConfirm={handleNotificationConfirm}
                    onCancel={handleCancelNotification}
                    initialDate={batch.notification_not_before_timestamp}
                    cureStartTimestamp={batch.cure_start_timestamp}
                    isStageChange={batch.stage === 'CURING' && !batch.notification_set}
                />
            )}
            {showRenameModal && (
                <BatchRenameModal
                    hideModal={() => setShowRenameModal(false)}
                    currentName={batch.name}
                    onConfirm={handleRenameBatch}
                    onCancel={() => setShowRenameModal(false)}
                />
            )}
            
            <div className="batch-desktop-heading">
                <h1 
                    onClick={() => setShowRenameModal(true)}
                    style={{ cursor: 'pointer' }}
                    title="Click to rename batch"
                >
                    {batch.name}
                </h1>
                <div className="batch-desktop-heading-controls">
                    <div 
                        className="batch-desktop-heading-settings" 
                        onClick={() => setShowConfirmDeleteModal(true)}
                    >
                        <GrTrash/>
                    </div>
                </div>
            </div>

            <div className="batch-desktop-cure-timer-container">
                <div className="batch-desktop-status-row">
                    <p>{getSubtitleText()}</p>
                </div>
            </div>

            <div className="batch-desktop-navigation-bar">
                {views.map((v) => (
                    <span onClick={() => setView(v.id)} className={view === v.id ? "selected" : ""} key={v.id}>{v.symbol && v.symbol} {v.name}</span>
                ))}
            </div>

            {view === "overview" &&
            <div className="batch-desktop-split">
                <div className="batch-desktop-split-section">
                    <div>
                        <h2>Supplies</h2>
                        <BatchSuppliesPane fetchBatch={fetchBatch} recipeContainer={recipeContainer} recipe={recipe} batch={batch} fetchUserLibrary={fetchUserLibrary} globalState={globalState}/>
                    </div>

                </div>

                <div className="batch-desktop-split-section batch-desktop-split-section-backgrounds">
                    <div>
                        <h2>Stage</h2>
                        <BatchStageSelector batch={batch} onStageChange={handleStageChange} />
                        <div 
                            className="batch-desktop-notification-link" 
                            onClick={handleSetupNotification}
                        >
                            {batch.notification_set 
                                ? <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                    <span style={{display: "flex", alignItems: "center", gap: "4px"}}><IoNotifications className="active" />{getNotificationDisplay()}</span>
                                    <button 
                                        className="batch-notification-cancel" 
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleCancelNotification();
                                        }}
                                        title="Cancel notification"
                                    >
                                        <MdOutlineClear/>
                                    </button>
                                    </div> 
                                : <div>Add notification</div>
                            }
                        </div>
                    </div>

                    {batch.stage_history && batch.stage_history.length > 0 &&
                    <div>
                        <BatchTimeline stageHistory={batch.stage_history} />
                    </div>
                    }

                    <div>
                        <h2>Recipe</h2>
                        <div style={{display: "flex"}}>
                            <div className="batch-desktop-clickable-item-title" onClick={() => goToPath(`/recipe/${recipe._id}`)}>{recipe.name}</div>
                        </div>
                    </div>

                    {batch.estimated_cogs_pennies && batch.estimated_cogs_pennies > 0 ?
                    <div>
                        <h2>Material Cost of Goods</h2>
                        <COGSBreakdownTable cogsSchedule={batch.estimated_cogs_schedule} totalCostPennies={batch.estimated_cogs_pennies} />
                    </div> : <></>
                    }

                    <div>
                        <h2>Candles</h2>
                        <div className="general-card-list batch-desktop-candle-list">
                            {getCandles().slice(0, candlePreviewSize).map((candle) => (

                            <div style={{display: "flex", flexDirection: "column", gap: "4px"}} key={candle._id}>
                                <div className="batch-desktop-clickable-item-title candle" onClick={() => goToPath(`/candles/candle/${candle._id}`)}>
                                    <div>{candle.name}</div>
                                    <span>→</span>
                                </div>
                            </div>

                            ))}
                            {candlePreviewSize < getCandles().length && 
                            <div className="general-list-show-more-button" onClick={showMoreCandles}>Show More</div>
                            }
                        </div>
                    </div>

                </div>

            </div>
            }

            {view === "production" && batchPlan &&
                <BatchPlanSection
                    batchPlan={batchPlan}
                    batchId={batch._id}
                    onUpdate={setBatchPlan}
                    recipe={recipe}
                />
            }

            {view === "production" && !batchPlan &&
            <div className="batch-desktop-production-empty">
                {isCreatingBatchPlan ? (
                    <LoadingSymbol />
                ) : (
                    <>
                        <div style={{color: "var(--ml-color-dark)"}}>Production notes help you track and manage your process</div>
                        <div className="batch-desktop-production-empty-button" onClick={createNewBatchPlan}>Create new production notes</div>
                        {recentBatchPlans.length > 0 && (
                            <div className="batch-desktop-production-empty-button-text" onClick={() => setShowDuplicateModal(true)}>
                                Copy from existing batch
                            </div>
                        )}
                        {showDuplicateModal && (
                            <BatchPlanDuplicateModal
                                onClose={() => setShowDuplicateModal(false)}
                                onSelect={handleDuplicateSelect}
                                recentBatchPlans={recentBatchPlans}
                            />
                        )}
                    </>
                )}
            </div>
            }

            {view === "tests" &&
            <div className="batch-desktop-production-empty">
                <p style={{maxWidth: "600px", textAlign: "center", fontSize: "18px"}}>Coming soon for members with a <span style={{fontWeight: 600}}>Pro subscription</span></p>
                <p style={{maxWidth: "600px", textAlign: "center", fontSize: "14px"}}>Test individual candles! Record notes and check your work against the ASTM 2417 safety test standards.</p>
            </div>
            }

            {view === "notes" &&
            <div className="batch-desktop-split">
                <div className="batch-desktop-split-section">
                    <div className="general-page-create-section">
                        <div>
                            <Notes notes={batch.notes} updateNote={updateBatchWithNotes} baseId={batch._id}/>
                        </div>
                    </div>
                </div>
            </div>

            }
        </div>
    )
}