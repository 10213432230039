import React, { useState, useEffect } from 'react'
import './PopupQuestion.css'
import { IoClose } from "react-icons/io5";
import { differenceInSeconds, parseISO } from 'date-fns';

export default function PopupQuestion() {
    const [isVisible, setIsVisible] = useState(false);
    
    useEffect(() => {
        const dismissTimestamp = localStorage.getItem('ml_feedback_popup_dismiss_timestamp');
        
        if (!dismissTimestamp) {
            setIsVisible(true);
            return;
        }

        const now = new Date();
        const diff = differenceInSeconds(now, parseISO(dismissTimestamp));
        
        if (diff > 1036800) { // 12 days in seconds
            setIsVisible(true);
        }
    }, []);

    const handleDismiss = () => {
        const now = new Date().toISOString();
        localStorage.setItem('ml_feedback_popup_dismiss_timestamp', now);
        setIsVisible(false);
    };

    const handleSubmitIdea = () => {
        window.open('https://tetherform.com/f/67af5a3388467037d98d617b', '_blank');
    };

    if (!isVisible) return null;

    return (
        <div className="popup-question-outer">
            <div className="popup-question-inner">
                <div className="popup-question-text">
                    Make MoonLite better!<span onClick={handleSubmitIdea}>Submit an idea</span>
                </div>
                <div className="popup-question-icon" onClick={handleDismiss}><IoClose/></div>
            </div>
        </div>
    )
}
