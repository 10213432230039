import React, { useState } from 'react';
import { format, differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';
import { MdCheckCircle, MdOutlineTimelapse } from "react-icons/md";
import './BatchTimeline.css';

const BatchTimeline = ({ stageHistory }) => {
  const [showModal, setShowModal] = useState(false);
  
  if (!stageHistory || stageHistory.length === 0) {
    return null;
  }

  // Sort the history by timestamp (just to ensure it's in chronological order)
  const sortedHistory = [...stageHistory].sort((a, b) => 
    new Date(a.timestamp) - new Date(b.timestamp)
  );
  
  // Helper function to normalize stage name (NEW -> New)
  const normalizeStage = (stage) => {
    return stage.charAt(0).toUpperCase() + stage.slice(1).toLowerCase();
  };
  
  // Calculate duration between current stage and next stage
  const calculateDuration = (currentDate, nextDate) => {
    if (!currentDate || !nextDate) return null;
    
    const minutesDiff = differenceInMinutes(nextDate, currentDate);
    
    // Don't show duration if less than 5 minutes
    if (minutesDiff < 5) return null;
    
    const daysDiff = differenceInDays(nextDate, currentDate);
    
    if (daysDiff >= 2) {
      return `${daysDiff} day${daysDiff !== 1 ? 's' : ''}`;
    } else {
      const hoursDiff = differenceInHours(nextDate, currentDate);
      
      if (hoursDiff >= 1) {
        return `${hoursDiff} hour${hoursDiff !== 1 ? 's' : ''}`;
      } else {
        return `${minutesDiff} minute${minutesDiff !== 1 ? 's' : ''}`;
      }
    }
  };
  
  // Determine if we need to show "View All" button (more than 3 entries)
  const showViewAll = sortedHistory.length > 3;
  
  // For the main display (not modal), show the most recent entries first
  // For more than 3 entries, take the 3 most recent
  const mainDisplayHistory = showViewAll && !showModal 
    ? [...sortedHistory].reverse().slice(0, 3) 
    : [...sortedHistory].reverse();

  // Create a chronological and reverse chronological version of the history
  const chronologicalHistory = [...sortedHistory];
  const reverseChronologicalHistory = [...sortedHistory].reverse();
  
  // Get the most recent stage (will be the first item in reverse chronological)
  const mostRecentStage = reverseChronologicalHistory.length > 0 ? reverseChronologicalHistory[0] : null;

  // Timeline entry component
  const TimelineEntry = ({ entry, index, isLast, allHistory, isReversed }) => {
    // Determine if this entry is the most recent stage
    const isCurrentStage = mostRecentStage && entry.timestamp === mostRecentStage.timestamp && entry.stage === mostRecentStage.stage;
    
    // Calculate duration
    let duration = null;
    
    if (isReversed) {
      // Find the entry in the reverse chronological array
      const entryIndex = reverseChronologicalHistory.findIndex(
        item => item.timestamp === entry.timestamp && item.stage === entry.stage
      );
      
      // Check if this isn't the first item in the original chronological order
      if (entryIndex < reverseChronologicalHistory.length - 1) {
        const currentDate = new Date(entry.timestamp);
        const previousStageDate = new Date(reverseChronologicalHistory[entryIndex + 1].timestamp);
        
        duration = calculateDuration(previousStageDate, currentDate);
      }
    } else {
      // For chronological display (in modal)
      const entryIndex = chronologicalHistory.findIndex(
        item => item.timestamp === entry.timestamp && item.stage === entry.stage
      );
      
      if (entryIndex < chronologicalHistory.length - 1) {
        const currentDate = new Date(entry.timestamp);
        const nextStageDate = new Date(chronologicalHistory[entryIndex + 1].timestamp);
        
        duration = calculateDuration(currentDate, nextStageDate);
      }
    }
    
    return (
      <div className="batch-timeline-entry" key={index}>
        <div className={`batch-timeline-bullet ${isCurrentStage ? `batch-timeline-bullet-current batch-timeline-bullet-${entry.stage.toLowerCase()}` : 'batch-timeline-bullet-past'}`}>
          {isCurrentStage ? (
            <MdOutlineTimelapse className="batch-timeline-icon" />
          ) : (
            <MdCheckCircle className="batch-timeline-icon" />
          )}
        </div>
        <div className="batch-timeline-date">
          {format(new Date(entry.timestamp), 'h:mm a - MMM d, yyyy')}
        </div>
        <div className={`batch-timeline-stage batch-timeline-stage-${entry.stage.toLowerCase()}`}>
          {normalizeStage(entry.stage)}
        </div>
        {duration && (
          <div className="batch-timeline-duration">
            {duration}
          </div>
        )}
        {!isLast && <div className="batch-timeline-line"></div>}
      </div>
    );
  };

  // Modal component
  const TimelineModal = () => (
    <div className="batch-timeline-modal-overlay" onClick={() => setShowModal(false)}>
      <div className="batch-timeline-modal" onClick={(e) => e.stopPropagation()}>
        <div className="batch-timeline-modal-header">
          <h2>Full History</h2>
          <button className="batch-timeline-modal-close" onClick={() => setShowModal(false)}>×</button>
        </div>
        <div className="batch-timeline-modal-content">
          <div className="batch-timeline-container batch-timeline-modal-container">
            {chronologicalHistory.map((entry, index) => (
              <TimelineEntry 
                entry={entry} 
                index={index} 
                isLast={index === chronologicalHistory.length - 1} 
                allHistory={chronologicalHistory}
                isReversed={false}
                key={index} 
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="batch-timeline">
      <h2>History</h2>
      <div className="batch-timeline-container">
        {mainDisplayHistory.map((entry, index) => (
          <TimelineEntry 
            entry={entry} 
            index={index} 
            isLast={index === mainDisplayHistory.length - 1} 
            allHistory={mainDisplayHistory}
            isReversed={true}
            key={index} 
          />
        ))}
      </div>
      
      {showViewAll && (
        <button 
          className="batch-timeline-view-all" 
          onClick={() => setShowModal(true)}
        >
          View All ({sortedHistory.length})
        </button>
      )}
      
      {showModal && <TimelineModal />}
    </div>
  );
};

export default BatchTimeline; 