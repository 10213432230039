import React, { useState, useEffect } from 'react';
import { useSubscription } from '../../context/SubscriptionContext';
import './InventorySettingsModal.css';

const InventorySettingsModal = ({ hideModal }) => {
    const { userSettings, updateSettings } = useSubscription();
    
    const [currency, setCurrency] = useState(userSettings?.inventory_units_currency || 'dollar');
    const [isSaving, setIsSaving] = useState(false);
    
    const currencyOptions = [
        { value: 'dollar', label: 'US Dollar ($)' },
        { value: 'euro', label: 'Euro (€)' },
        { value: 'pound', label: 'British Pound (£)' }
    ];
        
    const handleSave = async () => {
        try {
            setIsSaving(true);
            
            // Build the settings object
            const updatedSettings = {
                inventory_units_currency: currency
            };
            
            // Update settings using the context function
            const success = await updateSettings(updatedSettings);
            
            if (success) {
                // Close the modal on success
                hideModal();
            }
        } catch (error) {
            console.error('Error saving settings:', error);
        } finally {
            setIsSaving(false);
        }
    };
    
    const handleCurrencyChange = (value) => {
        setCurrency(value);
    };
    
    // Close modal when Escape key is pressed
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                hideModal();
            }
        };
        
        window.addEventListener('keydown', handleKeyDown);
        
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [hideModal]);
    
    return (
        <div className="inventory-settings-modal-overlay" onClick={hideModal}>
            <div 
                className="inventory-settings-modal-content" 
                onClick={(e) => e.stopPropagation()}
            >
                <h2 className="inventory-settings-modal-title">Settings</h2>
                
                {/* Currency Settings */}
                <div className="inventory-settings-modal-section">
                    <h3 className="inventory-settings-modal-section-title">Currency</h3>
                    <p className="inventory-settings-modal-section-subtitle">Changing this will not impact existing values</p>
                    <div className="inventory-settings-modal-options">
                        {currencyOptions.map(option => (
                            <div 
                                key={option.value}
                                className={`inventory-settings-modal-option ${currency === option.value ? 'selected' : ''}`}
                                onClick={() => handleCurrencyChange(option.value)}
                            >
                                <input 
                                    type="radio"
                                    id={`currency-${option.value}`}
                                    name="currency"
                                    className="inventory-settings-modal-option-radio"
                                    checked={currency === option.value}
                                    onChange={() => handleCurrencyChange(option.value)}
                                />
                                <label 
                                    htmlFor={`currency-${option.value}`}
                                    className="inventory-settings-modal-option-label"
                                >
                                    {option.label}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                                                
                {/* Action Buttons */}
                <div className="inventory-settings-modal-actions">
                    <button 
                        className="inventory-settings-modal-button cancel" 
                        onClick={hideModal}
                        disabled={isSaving}
                    >
                        Cancel
                    </button>
                    <button 
                        className="inventory-settings-modal-button save" 
                        onClick={handleSave}
                        disabled={isSaving}
                    >
                        {isSaving ? 'Saving...' : 'Save'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InventorySettingsModal; 