import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { differenceInDays, differenceInMinutes, differenceInHours, format } from 'date-fns';
import { BiSort, BiSortUp, BiSortDown, BiX } from 'react-icons/bi';
import Api from '../../common/APIUtils';
import './BatchTable.css';
import { GiPouringPot } from 'react-icons/gi';
import BatchTableStageFilterMenu from './BatchTableStageFilterMenu';
import BatchTableStageSelector from './BatchTableStageSelector';

const api = new Api();
const ITEMS_PER_PAGE = 50;

const BatchTable = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [batches, setBatches] = useState([]);
    const [allBatches, setAllBatches] = useState([]); // Store all batches for local operations
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [selectedBatch, setSelectedBatch] = useState(null);
    const [pagination, setPagination] = useState({
        total: 0,
        current_page: 1,
        total_pages: 1,
        has_next: false,
        has_prev: false
    });
    const [stageCounts, setStageCounts] = useState({
        NEW: 0,
        POURING: 0,
        CURING: 0,
        DONE: 0
    });
    const [filters, setFilters] = useState({
        stage: '',       // Keep this for backward compatibility
        stages: [],      // New array to hold multiple selected stages
        search: '',
        sortBy: 'updatedAt',
        sortOrder: 'desc'
    });
    const [searchInput, setSearchInput] = useState('');
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const [isLocalMode, setIsLocalMode] = useState(false);
    const [hasAllBatches, setHasAllBatches] = useState(false);

    // Add a ref for the search input
    const searchInputRef = useRef(null);

    // Function to update URL parameters based on current state
    const updateUrlParams = (newFilters, newPage) => {
        const params = new URLSearchParams();
        
        // Add filters to URL
        if (newFilters.stages && newFilters.stages.length > 0) {
            params.set('stages', newFilters.stages.join(','));
        } else if (newFilters.stage) {
            // Backward compatibility
            params.set('stage', newFilters.stage);
        }
        
        if (newFilters.search) params.set('search', newFilters.search);
        if (newFilters.sortBy) params.set('sortBy', newFilters.sortBy);
        if (newFilters.sortOrder) params.set('sortOrder', newFilters.sortOrder);
        
        // Add pagination to URL
        if (newPage > 1) params.set('page', newPage.toString());
        
        // Update URL without reloading the page
        setSearchParams(params);
    };

    // Initialize state from URL parameters on component mount
    useEffect(() => {
        const stage = searchParams.get('stage') || '';
        const stagesParam = searchParams.get('stages') || '';
        const stages = stagesParam ? stagesParam.split(',') : [];
        const search = searchParams.get('search') || '';
        const sortBy = searchParams.get('sortBy') || 'updatedAt';
        const sortOrder = searchParams.get('sortOrder') || 'desc';
        const urlPage = parseInt(searchParams.get('page')) || 1;
        
        // Set initial state based on URL parameters
        setFilters({
            stage,            // Keep for backward compatibility
            stages: stages.length > 0 ? stages : (stage ? [stage] : []),  // Use stages param or convert single stage to array
            search,
            sortBy,
            sortOrder
        });
        
        setPage(urlPage);
        
        // If there's a search term in the URL, update the search input field
        if (search) {
            setSearchInput(search);
        }
        
        // Mark initial load as complete
        setInitialLoadComplete(true);
    }, [searchParams]);

    // Main effect for handling filter changes and data fetching
    useEffect(() => {
        // Skip the first render until URL parameters are processed
        if (!initialLoadComplete) return;
        
        // If we're in local mode and have all batches, apply filters locally
        if (isLocalMode && hasAllBatches) {
            applyLocalFilters(allBatches);
        } 
        // If we're in local mode but don't have all batches yet, fetch all batches first
        else if (isLocalMode && !hasAllBatches) {
            fetchBatches(true); // Fetch all batches without filters
        }
        // Otherwise, use server-side filtering
        else {
            fetchBatches(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, page, initialLoadComplete, isLocalMode, hasAllBatches, allBatches]);

    // Add a keyboard shortcut to focus the search input
    useEffect(() => {
        const handleKeyDown = (e) => {
            // Focus search input on Ctrl+F or Cmd+F
            if ((e.ctrlKey || e.metaKey) && e.key === 'f') {
                e.preventDefault(); // Prevent browser's default find behavior
                if (searchInputRef.current) {
                    searchInputRef.current.focus();
                }
            }
        };
        
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    // Trigger initial data fetch once URL parameters are processed
    useEffect(() => {
        if (initialLoadComplete) {
            fetchBatches(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialLoadComplete]);

    // Fetch batches with filters
    const fetchBatches = async (fetchAll = false) => {
        try {
            setLoading(true);
            setError(null);
            
            // If fetchAll is true, don't apply filters to get all batches for local operations
            const queryParams = new URLSearchParams({
                page,
                limit: ITEMS_PER_PAGE,
                ...(fetchAll ? {} : {
                    ...(filters.stages && filters.stages.length > 0 && { stages: filters.stages.join(',') }),
                    ...(filters.stage && !filters.stages.length && { stage: filters.stage }), // Backward compatibility
                    ...(filters.search && { 
                        search: filters.search,
                        search_in_recipe: 'true' // Add parameter to search in recipe names
                    }),
                }),
                sort_by: filters.sortBy,
                sort_order: filters.sortOrder
            }).toString();

            const response = await api.getBatchesWithFilters(queryParams);
            
            if (!response || !response.data) {
                throw new Error('Invalid response format');
            }

            const totalRecords = response.data.total_records || 0;

            // Determine if we should use local mode (≤50 records)
            setIsLocalMode(totalRecords <= ITEMS_PER_PAGE);

            if (totalRecords <= ITEMS_PER_PAGE) {
                if (fetchAll) {
                    // Store all batches for local operations
                    setAllBatches(response.data.batches || []);
                    setHasAllBatches(true);
                    
                    // Apply filters locally to display the correct data
                    applyLocalFilters(response.data.batches || []);
                } else if (!hasAllBatches) {
                    // If we don't have all batches yet, store what we have
                    setAllBatches(response.data.batches || []);
                    setBatches(response.data.batches || []);
                }
            } else {
                // For large datasets, don't use local operations
                setAllBatches([]); 
                setHasAllBatches(false);
                setBatches(response.data.batches || []);
            }

            if (!fetchAll || totalRecords > ITEMS_PER_PAGE) {
                setBatches(response.data.batches || []);
            }

            setStageCounts(response.data.stage_counts || {
                NEW: 0,
                POURING: 0,
                CURING: 0,
                DONE: 0
            });
            setPagination(response.data.pagination || {
                total: 0,
                current_page: 1,
                total_pages: 1,
                has_next: false,
                has_prev: false
            });
            setLoading(false);
        } catch (err) {
            console.error('Error fetching batches:', err);
            setError(err.message || 'Failed to fetch batches');
            setLoading(false);
        }
    };

    // Function to apply filters locally
    const applyLocalFilters = (batchesToFilter) => {
        if (!batchesToFilter || !Array.isArray(batchesToFilter)) return;
        
        let filteredBatches = [...batchesToFilter];
        
        // Apply stage filter
        if (filters.stages && filters.stages.length > 0) {
            filteredBatches = filteredBatches.filter(batch => 
                filters.stages.includes(batch.stage || 'DONE')
            );
        }

        // Apply search filter
        if (filters.search) {
            const searchTerm = filters.search.toLowerCase();
            filteredBatches = filteredBatches.filter(batch => 
                // Search in batch name
                (batch.name?.toLowerCase() || '').includes(searchTerm) ||
                // Search in batch visual_id
                (batch.visual_id?.toString() === searchTerm) ||
                // Search in recipe name
                (batch.recipe?.name?.toLowerCase() || '').includes(searchTerm)
            );
        }

        // Apply sorting
        filteredBatches.sort((a, b) => {
            const aValue = a[filters.sortBy];
            const bValue = b[filters.sortBy];
            const order = filters.sortOrder === 'asc' ? 1 : -1;
            return (aValue < bValue ? -1 : 1) * order;
        });

        setBatches(filteredBatches);
    };

    // Update to handle multiple stages directly
    const handleStageFilterChange = (newStages) => {
        // Always reset to page 1 when filtering
        setPage(1);
        
        const newFilters = {
            ...filters,
            stages: newStages,
            stage: '' // Clear the old single stage filter
        };
        
        setFilters(newFilters);
        updateUrlParams(newFilters, 1); // Reset to page 1 and update URL
    };
    
    // Handle clearing all stage filters
    const handleClearStageFilters = () => {
        const newFilters = {
            ...filters,
            stages: [],
            stage: ''
        };
        setFilters(newFilters);
        updateUrlParams(newFilters, page);
    };

    const handleSort = (field) => {
        let newFilters;
        
        // If clicking the same field, toggle the order
        if (filters.sortBy === field) {
            newFilters = {
                ...filters,
                sortOrder: filters.sortOrder === 'asc' ? 'desc' : 'asc'
            };
        } else {
            // If clicking a new field, set it as the sort field with default desc order
            newFilters = {
                ...filters,
                sortBy: field,
                sortOrder: 'desc'
            };
        }
        
        setFilters(newFilters);
        updateUrlParams(newFilters, page);
        setPage(1);
    };

    const handleSearch = (e) => {
        const newValue = e.target.value;
        setSearchInput(newValue);
        
        // If the user clears the search input, immediately clear the search filter
        if (newValue === '' && filters.search) {
            const newFilters = {
                ...filters,
                search: ''
            };
            setFilters(newFilters);
            updateUrlParams(newFilters, page);
        }
    };

    const handleSearchSubmit = (e) => {
        // Handle Enter key to submit search
        if (e.key === 'Enter') {
            const trimmedSearch = searchInput.trim();
            
            if (trimmedSearch) {
                // Search in batch names, IDs, and recipe names
                setPage(1);
                const newFilters = {
                    ...filters,
                    search: trimmedSearch
                };
                setFilters(newFilters);
                updateUrlParams(newFilters, 1); // Reset to page 1 and update URL
            }
        }
        
        // Handle Escape key to clear search
        if (e.key === 'Escape' && (searchInput || filters.search)) {
            handleClearSearch();
        }
    };

    const handleClearSearch = () => {
        // Clear the search input
        setSearchInput('');
        
        // Clear the search filter
        const newFilters = {
            ...filters,
            search: ''
        };
        setFilters(newFilters);
        updateUrlParams(newFilters, page);
        
        // Focus the search input after clearing
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    };

    const formatCureTime = (cureStartTimestamp) => {
        const now = new Date();
        const cureStart = new Date(cureStartTimestamp);
        
        const totalMinutes = differenceInMinutes(now, cureStart);
        const totalHours = differenceInHours(now, cureStart);
        const totalDays = differenceInDays(now, cureStart);

        if (totalDays > 0) {
            const remainingHours = totalHours % 24;
            return `${totalDays}d ${remainingHours}h`;
        }
        
        if (totalHours > 0) {
            const remainingMinutes = totalMinutes % 60;
            return `${totalHours}h ${remainingMinutes}m`;
        }
        
        return `${totalMinutes}m`;
    };
    
    // Format creation date in the format "May 2, 2025"
    const formatCreationDate = (createdAt) => {
        try {
            if (!createdAt) return '-';
            return format(new Date(createdAt), 'MMM d, yyyy');
        } catch (error) {
            console.error('Error formatting date:', error);
            return '-';
        }
    };

    const renderStageTag = (stage, cureStartTimestamp) => {
        const stageClasses = {
            NEW: 'batch-stage-new',
            POURING: 'batch-stage-pouring',
            CURING: 'batch-stage-curing',
            TESTING: 'batch-stage-testing',
            DONE: 'batch-stage-done'
        };

        // If stage is missing or null, treat as "DONE"
        const effectiveStage = stage || 'DONE';

        let additionalInfo = '';
        if (effectiveStage === 'CURING' && cureStartTimestamp) {
            additionalInfo = formatCureTime(cureStartTimestamp);
        }

        return (
            <div className="stage-column">
                <span className={`batch-stage-tag ${stageClasses[effectiveStage]}`}>
                    {effectiveStage}
                </span>
                {additionalInfo && <span className="stage-info">{additionalInfo}</span>}
            </div>
        );
    };

    const renderSortIcon = (field) => {
        if (filters.sortBy !== field) {
            return <BiSort className="sort-icon" />;
        }
        return filters.sortOrder === 'asc' ? 
            <BiSortUp className="sort-icon active" /> : 
            <BiSortDown className="sort-icon active" />;
    };

    const renderColumnHeader = (field, label) => {
        const isActive = filters.sortBy === field;
        return (
            <div 
                className={`table-header-content ${isActive ? 'active' : ''}`}
                onClick={() => handleSort(field)}
            >
                {label}
                {renderSortIcon(field)}
            </div>
        );
    };

    const BatchTableRow = ({ batch, onStageClick }) => {
        return (
            <tr key={batch._id}>
                <td>
                    <span 
                        className="batch-table-link" 
                        onClick={() => navigate(`/candles/batch/${batch._id}`)}
                    >
                        {batch.name}
                    </span>
                </td>
                <td onClick={() => onStageClick(batch)}>
                    {renderStageTag(batch.stage, batch.cure_start_timestamp)}
                </td>
                <td>{batch.batch_size}</td>
                <td>
                    {batch.recipe ? (
                        <div className="recipe-info">
                            <span 
                                className="batch-table-link" 
                                onClick={() => navigate(`/recipe/${batch.recipe._id}`)}
                            >
                                {batch.recipe.name}
                            </span>
                            <span className="recipe-id">#{batch.recipe.visual_id}</span>
                        </div>
                    ) : (
                        <span className="recipe-not-found">No recipe</span>
                    )}
                </td>
                <td className="created-date">
                    {formatCreationDate(batch.createdAt)}
                </td>
            </tr>
        );
    };

    const renderPagination = () => {
        if (pagination.total_pages <= 1) return null;

        const handlePageChange = (newPage) => {
            setPage(newPage);
            updateUrlParams(filters, newPage);
        };

        return (
            <div className="batch-table-pagination">
                <button 
                    className="batch-table-pagination-button"
                    disabled={!pagination.has_prev}
                    onClick={() => handlePageChange(page - 1)}
                >
                    Previous
                </button>
                <span className="batch-table-pagination-info">
                    Page {pagination.current_page} of {pagination.total_pages}
                </span>
                <button 
                    className="batch-table-pagination-button"
                    disabled={!pagination.has_next}
                    onClick={() => handlePageChange(page + 1)}
                >
                    Next
                </button>
            </div>
        );
    };

    const handleStageChange = (batchId, newStage) => {
        setBatches(prevBatches => 
            prevBatches.map(batch => 
                batch._id === batchId 
                    ? { ...batch, stage: newStage }
                    : batch
            )
        );
        // Optionally refresh the data to get updated counts
        fetchBatches();
    };

    return (
        <div className="batch-table-container">
            <div className="general-category-page-header" style={{paddingLeft: 0, marginTop: 0}}>
                <h2>Batches</h2>
            </div>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "12px"}}>
                <div className="batch-table-filters">
                    <BatchTableStageFilterMenu
                        selectedStages={filters.stages}
                        allStages={Object.keys(stageCounts)}
                        stageCounts={stageCounts}
                        onStageFilterChange={handleStageFilterChange}
                        onClearFilters={handleClearStageFilters}
                    />
                </div>

                <div className="batch-table-actions">
                    <div className="batch-table-search-container">
                        <input
                            type="text"
                            placeholder="Search by batch name or recipe..."
                            value={searchInput}
                            onChange={handleSearch}
                            onKeyDown={handleSearchSubmit}
                            className="batch-table-search"
                            ref={searchInputRef}
                        />
                        {(searchInput || filters.search) && (
                            <div 
                                className="batch-table-search-clear" 
                                onClick={handleClearSearch}
                                title="Clear search"
                            >
                                <BiX size={20} />
                            </div>
                        )}
                    </div>
                    <button onClick={() => navigate('/create/batch')} className="batch-table-new-button">
                        + New Batch
                    </button>
                </div>


            </div>


            {error && (
                <div className="batch-table-error-banner">
                    <p>{error}</p>
                    <button onClick={fetchBatches} className="batch-table-retry">
                        Retry
                    </button>
                </div>
            )}

            {loading ? (
                <div className="batch-table-loading">Loading...</div>
            ) : batches.length === 0 ? (
                <div className="batch-table-empty">
                    <GiPouringPot className="batch-table-empty-icon"/>
                    <h3>No batches found</h3>
                    {filters.search || filters.stage ? (
                        <p>Try adjusting your search or filters</p>
                    ) : (
                        <p>Create your first batch to get started</p>
                    )}
                    {(filters.search || filters.stage) && (
                        <button 
                            onClick={() => {
                                setSearchInput('');
                                setFilters({
                                    stage: '',
                                    search: '',
                                    sortBy: 'updatedAt',
                                    sortOrder: 'desc'
                                });
                                setPage(1);
                            }}
                            className="batch-table-clear-filters"
                        >
                            Clear Filters
                        </button>
                    )}
                </div>
            ) : (
                <>
                    <div className="batch-table-wrapper">
                        <table className="batch-table">
                            <thead>
                                <tr>
                                    <th>{renderColumnHeader('name', 'Name')}</th>
                                    <th>{renderColumnHeader('stage', 'Stage')}</th>
                                    <th>Size</th>
                                    <th>Recipe</th>
                                    <th>{renderColumnHeader('createdAt', 'Created')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {batches.map((batch) => (
                                    <BatchTableRow 
                                        key={batch._id} 
                                        batch={batch}
                                        onStageClick={(batch) => setSelectedBatch(batch)}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {renderPagination()}
                </>
            )}

            {selectedBatch && (
                <BatchTableStageSelector
                    batch={selectedBatch}
                    onClose={() => setSelectedBatch(null)}
                    onStageChange={(newStage) => {
                        handleStageChange(selectedBatch._id, newStage);
                        setSelectedBatch(null);
                    }}
                />
            )}
        </div>
    );
};

export default BatchTable; 