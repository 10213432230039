import React from 'react'
import { useState, useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import LoadingSymbol from '../misc/LoadingSymbol'
import { capitalizeFirstLetter, gramsToOuncesString, getPrimaryDomain, formatCurrencyByType, getCurrencySymbol } from '../../common/Helpers'
import Api from '../../common/APIUtils'
import { useSubscription } from '../../context/SubscriptionContext'
import './InventorySupplyItem.css'

export default function InventorySupplyItem({globalState, dispatch, fetchUserLibrary}) {
    const api = new Api()
    const { supplyId } = useParams()
    const { userSettings } = useSubscription()
    const [supplyItem, setSupplyItem] = useState(() => { return false })
    const [isLoading, setIsLoading] = useState(() => { return true })
    const [isEditing, setIsEditing] = useState(() => { return false })
    const [proposedSupplyItem, proposedSupplyItemDispatch] = useReducer((state, action) => {
        if (action.type === "reset") {
            let update = { ...action.value, loaded: true }
            
            // Format price for display
            if ("unit_price_pennies" in action.value) {
                update["unit_price_dollars"] = (action.value["unit_price_pennies"]/100).toFixed(2)
            }
            
            // Convert units from storage format to display format for wax items
            if (action.value.type === "wax" && action.value.units) {
                // We keep the storage format (lb/kg) in the database value
                // but use the display format for the proposed item
                if (action.value.units === "lb") {
                    update.displayUnits = "Pound (lb)";
                } else if (action.value.units === "kg") {
                    update.displayUnits = "Kilogram (kg)";
                }
            }
            
            return { ...state, ...update }
        }
        if (action.type === "update") {
            let update = {}
            update[action.name] = action.value
            return { ...state, ...update }
        }
        return state
    },
    {
        loaded: false
    })
    const updateSupplyItem = () => {
        try {
            setIsLoading(true)
            const supply = globalState.supplies.find(s => s._id === supplyId)
            setSupplyItem(supply)
            setIsLoading(false)
        } catch (error) {
            return
        }
    }
    const openSupplierWebsite = () => {
        try {
            if (!supplyItem.supplier_website) return false
            if (!getPrimaryDomain(supplyItem.supplier_website)) return false
            return window.open(supplyItem.supplier_website, '_blank').focus()
        } catch (error) {
            return false
        }
    }
    const getCurrentCurrency = () => {
        return userSettings?.inventory_units_currency || 'dollar';
    }
    const saveChanges = () => {
        // Validate changes
        
        // Ensure we're converting to pennies correctly, regardless of input format
        // Remove any non-numeric characters except decimal point
        const sanitizedPrice = proposedSupplyItem.unit_price_dollars.toString().replace(/[^\d.]/g, '');
        const priceInPennies = Math.round(parseFloat(sanitizedPrice) * 100);

        // Determine the units value to send to the backend
        let units = proposedSupplyItem.units; // Default to current units
        
        // For wax items, convert display units to storage format
        if (supplyItem.type === "wax" && proposedSupplyItem.displayUnits) {
            if (proposedSupplyItem.displayUnits.includes("Pound")) {
                units = "lb";
            } else if (proposedSupplyItem.displayUnits.includes("Kilogram")) {
                units = "kg";
            }
        }

        // submit changes
        let payload = {
            supply_name: proposedSupplyItem.supply_name,
            supplier_name: proposedSupplyItem.supplier_name,
            supplier_website: proposedSupplyItem.supplier_website,
            unit_price_pennies: priceInPennies,
            units: units, // Use the converted units value
            notes: proposedSupplyItem.notes
        }
        
        api.updateSupplyItem(payload, supplyId)
        .then((res) => {
            setIsEditing(false)
            fetchUserLibrary()
        })
        .catch((err) => {
            setIsEditing(false)
            return
        })
    }
    const getVesselCapacity = () => {
        try {
            return parseFloat(supplyItem.properties.vessel_capacity_grams).toFixed(0)
        } catch (error) {
            return "-"
        }
    }
    const cancelChanges = () => {
        setIsEditing(false)
        proposedSupplyItemDispatch({type: "reset", value: supplyItem })
    }
    useEffect(() => {
        if (!supplyId) return
        if (!globalState) return
        updateSupplyItem()
    // eslint-disable-next-line
    }, [globalState, supplyId])
    useEffect(() => {
        if (!supplyItem) return
        proposedSupplyItemDispatch({type: "reset", value: supplyItem })
    }, [supplyItem])
    useEffect(() => {
        if (!globalState.library_loaded) fetchUserLibrary()
    // eslint-disable-next-line
    }, [globalState])
    return (
        <div>
            {isLoading && <div style={{display: "flex", justifyContent: "center"}}><LoadingSymbol/></div> }
            {!isLoading && supplyItem &&
            <div className="general-category-page-main">
                <div style={{marginTop: "24px"}}></div>
                <div className="general-category-page-header">
                    <h2>{supplyItem.supply_name}</h2>
                    <div className="util-row util-align-center" style={{gap: "12px"}}>
                        {isEditing && <div className="general-category-page-create" onClick={saveChanges} style={{backgroundColor: "var(--ml-color-accent)"}}>Save Changes</div>}
                        {isEditing && <div className="general-category-page-create general-category-page-create-inverse" onClick={cancelChanges}>Cancel</div>}
                        {!isEditing && <div className="general-category-page-create general-category-page-create-inverse" onClick={() => setIsEditing(true)}>Edit</div>}
                    </div>
                </div>
                <div className="inventory-item-main">
                    <div className="inventory-item-section-column inventory-item-section-column-60">
                        <div className="inventory-item-section">
                            <h3>Information</h3>
                            <div className="inventory-item-section-content">
                                <div>
                                    <div className="inventory-item-input-label">Supply Name</div>
                                    {isEditing && <input type="text" placeholder={supplyItem.supply_name} value={proposedSupplyItem.supply_name} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "supply_name", value: e.target.value })} />}
                                    {!isEditing && <p>{supplyItem.supply_name}</p> }
                                </div>
                                <div>
                                    <div className="inventory-item-input-label">Type</div>
                                    <p>{capitalizeFirstLetter(supplyItem.type)}</p>
                                </div>
                                <div>
                                    <div className="inventory-item-input-label">Notes</div>
                                    {isEditing && <textarea value={proposedSupplyItem.notes} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "notes", value: e.target.value })}></textarea> }
                                    {!isEditing && !supplyItem.notes && <p>-</p> }
                                    {!isEditing && supplyItem.notes && <p className="inventory-item-multiline">{supplyItem.notes}</p> }
                                </div>
                            </div>
                        </div>
                        {supplyItem.properties && supplyItem.type === "container" &&
                        <div className="inventory-item-section">
                            <h3>Properties</h3>
                            <div className="inventory-item-section-content">
                                {supplyItem.properties.vessel_capacity_grams &&
                                <div>
                                    {/* Allow changing this if there are no batches using it */}
                                    <div className="inventory-item-input-label">Vessel Capacity</div>
                                    <p>{getVesselCapacity()} g ({gramsToOuncesString(supplyItem.properties.vessel_capacity_grams)})</p>
                                </div>
                                }
                            </div>
                        </div>
                        }
                    </div>
                    <div className="inventory-item-section-column inventory-item-section-column-40">
                        <div className="inventory-item-section">
                            <h3>Suppliers</h3>
                            <div className="inventory-item-section-content">
                                <div>
                                    <div className="inventory-item-input-label">Supplier Name</div>
                                    {isEditing && <input type="text" placeholder={supplyItem.supplier_name ? supplyItem.supplier_name : "Enter a supplier name"} value={proposedSupplyItem.supplier_name} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "supplier_name", value: e.target.value })} />}
                                    {!isEditing && <p>{supplyItem.supplier_name ? supplyItem.supplier_name : "-"}</p> }
                                </div>
                                <div>
                                    <div className="inventory-item-input-label">Supplier Website</div>
                                    {isEditing && <input type="text" placeholder={supplyItem.supplier_website ? supplyItem.supplier_website : "Enter a website for this supplier or supply"} value={proposedSupplyItem.supplier_website} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "supplier_website", value: e.target.value })} />}
                                    {!isEditing && supplyItem.supplier_website && <p className="inventory-item-hyperlink" onClick={openSupplierWebsite}>{getPrimaryDomain(supplyItem.supplier_website)}</p> }
                                    {!isEditing && !supplyItem.supplier_website && <p>-</p> }
                                </div>
                            </div>
                        </div>
                        <div className="inventory-item-section">
                            <h3>Economics</h3>
                            <div className="inventory-item-section-content">
                                <div>
                                    <div className="inventory-item-input-label">Units</div>
                                    {isEditing && supplyItem.type === "wax" ? (
                                        <div className="inventory-supply-item-select-container">
                                            <select 
                                                className="inventory-supply-item-select"
                                                value={proposedSupplyItem.displayUnits || (supplyItem.units === "lb" ? "Pound (lb)" : "Kilogram (kg)")}
                                                onChange={(e) => proposedSupplyItemDispatch({
                                                    type: "update", 
                                                    name: "displayUnits", 
                                                    value: e.target.value
                                                })}
                                            >
                                                <option value="Pound (lb)">Pounds (lb)</option>
                                                <option value="Kilogram (kg)">Kilograms (kg)</option>
                                            </select>
                                        </div>
                                    ) : (
                                        <p>{supplyItem.type === "wax" ? 
                                            (supplyItem.units === "lb" ? "Pounds (lb)" : "Kilograms (kg)") : 
                                            supplyItem.units}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div className="inventory-item-input-label">Unit Pricing</div>
                                    {!isEditing && (
                                        <p>{formatCurrencyByType(supplyItem.unit_price_pennies, getCurrentCurrency())}</p>
                                    )}
                                    {isEditing && (
                                        <div className="inventory-price-input-container">
                                            <span className="currency-symbol">{getCurrencySymbol(getCurrentCurrency())}</span>
                                            <input 
                                                type="number" 
                                                placeholder={0} 
                                                min={0} 
                                                step="0.01"
                                                value={proposedSupplyItem.unit_price_dollars} 
                                                onChange={(e) => proposedSupplyItemDispatch({
                                                    type: "update", 
                                                    name: "unit_price_dollars", 
                                                    value: e.target.value 
                                                })} 
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}
