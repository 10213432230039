import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BsCheck2 } from 'react-icons/bs';
import './PlanSelection.css';

const PlanSelection = () => {
    const [billingPeriod, setBillingPeriod] = useState('monthly');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        // Set animation flag after initial animations complete
        const timer = setTimeout(() => {
            setHasAnimated(true);
        }, 2000); // Slightly longer than our longest animation

        return () => clearTimeout(timer);
    }, []);

    const plans = {
        basic: {
            name: 'Basic',
            monthly: 12,
            annual: 132,
            description: 'Ignite your creativity with MoonLite Basic. Manage up to 50 recipes, track your inventory effortlessly, and streamline production with our built-in candle making calculator and batch tracker. All on a mobile-ready platform designed to jumpstart your candle making journey.',
            features: [
                'Up to 50 recipes',
                'Basic inventory tracking',
                'Recipe management',
                'Candle making calculator',
                'Batch tracking',
                'Mobile-ready access'
            ]
        },
        pro: {
            name: 'Pro',
            monthly: 29,
            annual: 319,
            description: 'Elevate your candle making with MoonLite Pro. Enjoy all the benefits of Basic—plus unlimited recipes, advanced inventory management, detailed COGS reporting, and sophisticated testing notes. And with priority support, your creative flow never skips a beat.',
            features: [
                'Everything in basic',
                'Unlimited recipes',
                'Advanced inventory management',
                'COGS reporting',
                'Advanced candle testing notes',
                'Priority support'
            ]
        }
    };

    const handleBillingPeriodChange = (period) => {
        setBillingPeriod(period);
        setError(null);
    };

    const handleSubscribe = async (planType) => {
        setIsLoading(true);
        setError(null);

        try {
            const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5001' : '';
            const response = await axios.post(`${baseUrl}/api/payments/create-checkout-session`, {
                planType,
                billingPeriod
            }, {
                withCredentials: true
            });

            if (response.data.url) {
                window.location.href = response.data.url;
            } else {
                setError('Unable to start checkout process. Please try again.');
            }
        } catch (err) {
            console.error('[handleSubscribe] Error:', err);
            setError(err.response?.data?.message || 'An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const getPrice = (plan) => {
        const price = plans[plan][billingPeriod];
        const period = billingPeriod === "monthly" ? "mo" : "yr"
        return <div className="plan-selection-price-text">${price} <span>/{period}</span> </div>
    };

    return (
        <div className="plan-selection-container">
            <div className="plan-selection-content">
                <div className={`plan-selection-header ${hasAnimated ? 'no-animation' : ''}`}>
                    <h1 className="plan-selection-title">Welcome to MoonLite</h1>
                    <p className="plan-selection-subtitle">Select a plan that best fits your needs</p>
                </div>
                
                <div className={`plan-selection-billing-toggle ${hasAnimated ? 'no-animation' : ''}`}>
                    <button 
                        className={`plan-selection-billing-option ${billingPeriod === 'monthly' ? 'active' : ''}`}
                        onClick={() => handleBillingPeriodChange('monthly')}
                    >
                        Monthly
                    </button>
                    <button 
                        className={`plan-selection-billing-option ${billingPeriod === 'annual' ? 'active' : ''}`}
                        onClick={() => handleBillingPeriodChange('annual')}
                    >
                        Annual <span>1 month free</span>
                    </button>
                </div>

                <div className="plan-selection-cards">
                    {Object.entries(plans).map(([key, plan]) => (
                        <div 
                            key={key} 
                            className={`plan-selection-plan ${hasAnimated ? 'no-animation' : ''}`}
                        >
                            <div className="plan-selection-plan-header">
                                <h2 className="plan-selection-plan-name">{plan.name}</h2>
                                <p className="plan-selection-plan-description">{plan.description}</p>
                            </div>
                            <ul className="plan-selection-plan-features">
                                {plan.features.map((feature, index) => (
                                    <li key={index}>
                                        <BsCheck2 className="plan-selection-feature-check" />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                            <div className="plan-selection-plan-price">
                                <span className="plan-selection-price">{getPrice(key)}</span>
                            </div>
                            <div className="plan-selection-plan-footer">
                                {plan.name !== "Pro" &&
                                <button
                                    className="plan-selection-select-button"
                                    onClick={() => handleSubscribe(key)}
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <>
                                            <span>Processing...</span>
                                        </>
                                    ) : (
                                        `Get ${plan.name}`
                                    )}
                                </button>
                                }
                                {plan.name === "Pro" &&
                                <button
                                    className="plan-selection-select-button plan-selection-select-button-inverted"
                                >
                                    Coming Soon
                                </button>
                                }
                            </div>
                        </div>
                    ))}
                </div>

                {error && (
                    <div className="plan-selection-error">
                        <p>{error}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PlanSelection; 