import React, { useState, useEffect, useRef } from 'react';
import './BatchRenameModal.css';

const BatchRenameModal = ({ 
    hideModal, 
    currentName, 
    onConfirm, 
    onCancel 
}) => {
    const [batchName, setBatchName] = useState(currentName || '');
    const [error, setError] = useState('');
    const inputRef = useRef(null);
    const MAX_LENGTH = 120;

    useEffect(() => {
        // Focus the input when the modal opens
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, []);

    const handleNameChange = (e) => {
        const value = e.target.value;
        setBatchName(value);
        
        // Clear error when user starts typing
        if (error) setError('');
    };

    const handleSubmit = () => {
        // Validate input
        const trimmedName = batchName.trim();
        
        if (!trimmedName) {
            setError('Batch name cannot be empty');
            return;
        }
        
        if (trimmedName.length > MAX_LENGTH) {
            setError(`Batch name cannot exceed ${MAX_LENGTH} characters`);
            return;
        }
        
        // Call the confirm handler with the new name
        onConfirm(trimmedName);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        } else if (e.key === 'Escape') {
            onCancel();
        }
    };

    return (
        <div className="batch-rename-modal-overlay" onClick={onCancel}>
            <div 
                className="batch-rename-modal-content" 
                onClick={(e) => e.stopPropagation()}
            >
                <h2 className="batch-rename-modal-title">Rename batch</h2>
                
                <div className="batch-rename-modal-input-container">
                    <input
                        ref={inputRef}
                        type="text"
                        className="batch-rename-modal-input"
                        value={batchName}
                        onChange={handleNameChange}
                        onKeyDown={handleKeyDown}
                        maxLength={MAX_LENGTH}
                        placeholder="Enter batch name"
                    />
                    {error && <div className="batch-rename-modal-error">{error}</div>}
                </div>
                
                <div className="batch-rename-modal-actions">
                    <button 
                        className="batch-rename-modal-button cancel" 
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                    <button 
                        className="batch-rename-modal-button save" 
                        onClick={handleSubmit}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BatchRenameModal; 