import React from 'react'
import { MdDocumentScanner } from 'react-icons/md'
import { GiPouringPot, GiCube } from 'react-icons/gi'
import { useNavigate  } from 'react-router-dom'

export default function NavigationBar({globalState, dispatch}) {
    const navigate = useNavigate();
    const getNavigationClasses = (viewName) => {
        if (viewName === globalState.view) return "navigation-bar-selected"
        return ""
    }
    const updateView = (viewName) => {
        dispatch({type: "nav", payload: {view: viewName}})
        if (viewName === "home") return navigate("/home")
        return navigate(`/candles/${viewName}`);

    }
    return (
        <div className="responsive-navigation-bar">
            <div className="navigation-bar-container">
                <div className="navigation-bar-floating">
                    <span onClick={() => updateView("recipes")} className={getNavigationClasses("recipes")}>
                        <MdDocumentScanner/>
                        <div>Recipes</div>
                    </span>
                    <span onClick={() => updateView("batches")} className={getNavigationClasses("batches")}>
                        <GiPouringPot/>
                        <div>Batches</div>
                    </span>
                    <span onClick={() => updateView("inventory")} className={getNavigationClasses("inventory")}>
                        <GiCube/>
                        <div>Inventory</div>
                    </span>
                </div>
            </div>
        </div>
        
    )
}
