import React, { useState, useRef, useEffect } from 'react';
import './BatchTableStageFilterMenu.css';

const BatchTableStageFilterMenu = ({ 
    selectedStages, 
    allStages, 
    stageCounts, 
    onStageFilterChange, 
    onClearFilters 
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    
    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    
    // Toggle a single stage selection
    const toggleStage = (stage) => {
        const isSelected = selectedStages.includes(stage);
        let newSelectedStages;
        
        if (isSelected) {
            // Remove stage from selection
            newSelectedStages = selectedStages.filter(s => s !== stage);
        } else {
            // Add stage to selection
            newSelectedStages = [...selectedStages, stage];
        }
        
        onStageFilterChange(newSelectedStages);
    };
    
    // Select all stages
    const selectAll = () => {
        onStageFilterChange([...allStages]);
    };
    
    // Clear all selected stages
    const clearFilters = () => {
        onClearFilters();
        setIsOpen(false);
    };
    
    return (
        <div className="stage-filter-dropdown" ref={dropdownRef}>
            {/* Dropdown button */}
            <div 
                className="stage-filter-button"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span>Stage</span>
                {selectedStages.length > 0 && (
                    <span className="selected-count">{selectedStages.length} selected</span>
                )}
                <span className="dropdown-arrow">▼</span>
                {selectedStages.length > 0 && (
                    <button 
                        className="clear-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            clearFilters();
                        }}
                    >
                        ✕
                    </button>
                )}
            </div>
            
            {/* Dropdown panel */}
            {isOpen && (
                <div className="stage-filter-panel">
                    {allStages.map(stage => (
                        <div key={stage} className="filter-option">
                            <label className="filter-checkbox-label">
                                <input 
                                    type="checkbox"
                                    checked={selectedStages.includes(stage)}
                                    onChange={() => toggleStage(stage)}
                                    className="filter-checkbox"
                                />
                                <span className={`stage-tag ${stage.toLowerCase()}`}>{stage}</span>
                                <span className="stage-count">{stageCounts[stage] || 0}</span>
                            </label>
                        </div>
                    ))}
                    
                    <div className="filter-option select-all">
                        <button 
                            onClick={selectAll}
                            className="select-all-button"
                        >
                            Select All
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BatchTableStageFilterMenu; 