import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate  } from 'react-router-dom'
import { HiOutlinePlus } from 'react-icons/hi2'
import { BiSort, BiFilter, BiX } from 'react-icons/bi'
import { MdInventory } from 'react-icons/md'
import ModalAddNewInventoryItem from '../modals/ModalAddNewInventoryItem'
import { formatCurrencyByType, capitalizeFirstWord } from '../../common/Helpers'
import { useSubscription } from '../../context/SubscriptionContext'
import '../batches/BatchTable.css'
import './InventoryMain.css'

export default function InventoryMain({globalState, fetchUserLibrary, dispatch}) {
    const [query, setQuery] = useState(() => { return "" })
    const [inventoryView, setInventoryView] = useState(() => { return "all" })
    const [isAddingNewItem, setIsAddingNewItem] = useState(() => { return false })
    const [inventoryItemsRaw, setInventoryItemsRaw] = useState(() => { return [] })
    const [inventoryItemsFiltered, setInventoryItemsFiltered] = useState(() => { return [] })
    const [sortConfig, setSortConfig] = useState({
        key: 'supply_name',
        direction: 'asc'
    })
    const navigate = useNavigate();
    const { userSettings } = useSubscription()
    
    const goToPath = (path) => {
        navigate(path)
    }
    const AVAILABLE_INVENTORY = [
        { display_name: "All", view: "all" },
        { display_name: "Containers", view: "container" },
        { display_name: "Wax", view: "wax" },
        { display_name: "Fragrance Oil", view: "fragrance" },
        { display_name: "Wicks", view: "wick" },
        // { display_name: "Colors", view: "colors" },
    ]
    const closeNewCreationModal = (shouldFetch) => {
        if (shouldFetch) fetchUserLibrary()
        setIsAddingNewItem(false)
    }

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    }

    const renderSortIcon = (key) => {
        if (sortConfig.key !== key) {
            return <BiSort className="sort-icon" />;
        }
        return sortConfig.direction === 'asc' ? 
            <BiSort className="sort-icon active" /> : 
            <BiSort className="sort-icon active" style={{transform: 'rotate(180deg)'}} />;
    }
    
    const updateFilteredItems = () => {
        try {
            if (!inventoryItemsRaw || inventoryItemsRaw.length === 0) return setInventoryItemsFiltered([])
            const copyOfRawInventory = Array.from(inventoryItemsRaw)
            let filteredByView = copyOfRawInventory
            if (inventoryView !== "all") {
                filteredByView = copyOfRawInventory.filter((inventoryItem) => {
                    if (inventoryItem.type === inventoryView) return true
                    return false
                })
            }
            
            // Filter by query
            let filteredItems = filteredByView
            if (query.trim()) {
                filteredItems = filteredItems.filter((item) => {
                    const itemTextToSearch = `${item.supply_name}${item.type}${item.supplier_name}${item.supplier_website}${item.notes}`
                    if (itemTextToSearch.toLowerCase().indexOf(query.trim().toLowerCase()) !== -1) return true
                    return false
                })
            }
            
            // Apply sorting
            const sortedItems = [...filteredItems].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
            
            setInventoryItemsFiltered(sortedItems)
            
        } catch (error) {
            return setInventoryItemsFiltered([])
        }
    }
    useEffect(() => {
        if (!globalState.loaded) return
        if (globalState.supplies.length > 0) setInventoryItemsRaw(globalState.supplies)
    // eslint-disable-next-line
    }, [globalState])
    useEffect(() => {
        fetchUserLibrary()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        updateFilteredItems()
    // eslint-disable-next-line
    }, [inventoryItemsRaw, inventoryView, query, sortConfig])
    return (
        <div className="general-category-page-main">
            {isAddingNewItem && <ModalAddNewInventoryItem hideModal={closeNewCreationModal}/>}
            <div className="general-category-page-header">
                <h2>Inventory</h2>
                <div className="util-row util-align-center desktop-only" style={{gap: "12px"}}>
                    <div className="general-category-page-create desktop-create-button" onClick={() => setIsAddingNewItem(true)}>
                        <HiOutlinePlus/> New Item
                    </div>
                </div>
            </div>

            <div className="batch-table-container" style={{paddingTop: 0}}>
                <div className="inventory-controls-container">
                    <div className="inventory-search-row">
                        <div className="batch-table-search-container">
                            <input
                                type="text"
                                placeholder="Search inventory items"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                className="batch-table-search"
                            />
                            {query && (
                                <div className="batch-table-search-clear" onClick={() => setQuery('')}>
                                    <BiX size={20} />
                                </div>
                            )}
                        </div>
                    </div>
                    
                    <div className="inventory-filter-row">
                        <div className="recipe-table-collections">
                            <div className="inventory-filter-dropdown">
                                <button 
                                    className="recipe-table-filter-button" 
                                    onClick={() => {}}
                                >
                                    <BiFilter /> {AVAILABLE_INVENTORY.find(item => item.view === inventoryView)?.display_name || 'All'}
                                </button>
                                <select 
                                    className="inventory-select-filter" 
                                    onChange={(e) => setInventoryView(e.target.value)} 
                                    value={inventoryView}
                                >
                                    {AVAILABLE_INVENTORY.map((inventory_item) => (
                                        <option value={inventory_item.view} key={inventory_item.view}>{inventory_item.display_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        
                        <div className="mobile-only inventory-mobile-create">
                            <div className="general-category-page-create mobile-create-button" onClick={() => setIsAddingNewItem(true)}>
                                <HiOutlinePlus size={20}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="batch-table-wrapper">
                    <table className="batch-table">
                        <thead>
                            <tr>
                                <th>
                                    <div className="table-header-content active" onClick={() => handleSort('supply_name')}>
                                        Name {renderSortIcon('supply_name')}
                                    </div>
                                </th>
                                <th>
                                    <div className="table-header-content" onClick={() => handleSort('type')}>
                                        Type {renderSortIcon('type')}
                                    </div>
                                </th>
                                <th>
                                    <div className="table-header-content">Units</div>
                                </th>
                                <th>
                                    <div className="table-header-content" onClick={() => handleSort('unit_price_pennies')}>
                                        Unit Price {renderSortIcon('unit_price_pennies')}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {inventoryItemsFiltered.map((inventoryItem) => (
                                <tr key={inventoryItem._id}>
                                    <td>
                                        <span 
                                            className="batch-table-link" 
                                            onClick={() => goToPath(`/candles/inventory/supplies/${inventoryItem._id}`)}
                                        >
                                            {inventoryItem.supply_name}
                                        </span>
                                        {inventoryItem.supplier_name &&
                                        <>
                                        <br/>
                                        <div className="recipe-table-cell-collection" style={{color: "grey"}}>
                                            <span>{inventoryItem.supplier_name}</span>
                                        </div>
                                        </>
                                        }
                                    </td>
                                    <td>{capitalizeFirstWord(inventoryItem.type)}</td>
                                    <td>{inventoryItem.units}</td>
                                    <td>{formatCurrencyByType(inventoryItem.unit_price_pennies, userSettings?.inventory_units_currency)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                
                {inventoryItemsFiltered.length === 0 && !globalState.loaded &&
                <div className="batch-table-loading">Loading...</div>
                }
                
                {inventoryItemsFiltered.length === 0 && globalState.loaded &&
                <div className="batch-table-empty">
                    <MdInventory className="batch-table-empty-icon"/>
                    <h3>No inventory items found</h3>
                    {query ? (
                        <p>Try adjusting your search or filters</p>
                    ) : (
                        <p>Add your first inventory item to get started</p>
                    )}
                    {query && (
                        <button 
                            onClick={() => {
                                setQuery('');
                                setInventoryView('all');
                            }}
                            className="batch-table-clear-filters"
                        >
                            Clear Filters
                        </button>
                    )}
                </div>
                }
            </div>
        </div>
    )
}
