import React from 'react'
import { useReducer, useEffect, useState } from 'react'
import { MdClear } from 'react-icons/md'
import { convertRawOuncesToRawGrams, getTotalWeightsOfBlend, sortByProperty } from '../../common/Helpers'
import { GrRadial, GrRadialSelected, GrFormSubtract, GrFormAdd } from 'react-icons/gr'
import { TiWarning } from 'react-icons/ti'
import ModalEditBlendWeights from './ModalEditBlendWeights'

export default function ModalEditRecipe({sendEdits, hideModal, recipe, batches, globalState}) {
    const [isOunces, setIsOunces] = useState(() => { return false })
    const [validContainers, setValidContainers] = useState(() => { return [] })
    const [validWicks, setValidWicks] = useState(() => { return [] })
    const [validWax, setValidWax] = useState(() => { return [] })
    const [validFragrance, setValidFragrance] = useState(() => { return [] })
    // eslint-disable-next-line
    const [newColorToAdd, setNewColorToAdd] = useState(() => { return { name: "", color_drops_per_pound: "5" } })
    const [isEditingBlendWeights, setIsEditingBlendWeights] = useState(() => { return false })
    const [isEditingFoBlendWeights, setIsEditingFoBlendWeights] = useState(() => { return false })
    const [updatedRecipe, updatedRecipeDispatch] = useReducer((state, action) => {
        if (action.type === "init") {
            let isImmutable = true
            if (batches.length === 0) isImmutable = false
            return { ...state, loaded: true, isImmutable: isImmutable }
        }
        if (action.type === "update") {
            let update = {}
            update[action.prop] = action.value
            return { ...state, ...update }
        }
        if (action.type === "color_map_update") {
            if (!state.color_map.colors) return state
            let colorMap = state.color_map.colors
            const index = action.index
            const proposedColorName = action.name
            const proposedColorDrops = action.drops
            if (action.update === "name") {
                const exDrops = colorMap[index].color_drops_per_pound
                colorMap[index] = { color_name: proposedColorName, color_drops_per_pound: exDrops }
            }
            if (action.update === "drops") {
                const exName = colorMap[index].color_name
                colorMap[index] = { color_name: exName, color_drops_per_pound: proposedColorDrops }
            }
            const updatedColorItems = {
                colors: colorMap
            }
            return { ...state, color_map: updatedColorItems }
        }
        if (action.type === "color_map_update_new") {
            let colorMap = [{name: "", color_drops_per_pound: "5"}]
            const index = action.index
            const proposedColorName = action.name
            const proposedColorDrops = action.drops
            if (action.update === "name") {
                const exDrops = newColorToAdd.color_drops_per_pound
                colorMap[index] = { color_name: proposedColorName, color_drops_per_pound: exDrops }
            }
            if (action.update === "drops") {
                const exName = newColorToAdd.color_name
                colorMap[index] = { color_name: exName, color_drops_per_pound: proposedColorDrops }
            }
            const updatedColorItems = {
                colors: colorMap
            }
            return { ...state, color_map: updatedColorItems }
        }
        return state
      }, recipe)
    const saveBlendWeightEdits = (blendWeights) => {
        setIsEditingBlendWeights(false)
        let updatedBlendWeights = {
            blends: blendWeights
        }
        updatedRecipeDispatch({type: "update", prop: "wax_blend_map", value: updatedBlendWeights })
    }
    const saveFoBlendWeightEdits = (blendWeights) => {
        setIsEditingFoBlendWeights(false)
        let updatedBlendWeights = {
            blends: blendWeights
        }
        updatedRecipeDispatch({type: "update", prop: "fragrance_oil_blend_map", value: updatedBlendWeights })
    }
    const saveUpdates = () => {
        if (!isValid()) return hideModal()
        if (updatedRecipe.isImmutable) return sendEdits({name: updatedRecipe.name, recipe_id: recipe._id})
        let edits = {
            name: updatedRecipe.name,
            wax: updatedRecipe.wax,
            fragrance_oil: updatedRecipe.fragrance_oil,
            fragrance_load: updatedRecipe.fragrance_load,
            wick_name: updatedRecipe.wick_name,
            wick_count: updatedRecipe.wick_count,
            recipe_id: recipe._id,
        }

        if (!updatedRecipe.container_id) {
            edits["container_name"] = updatedRecipe.container_name
            edits["vessel_capacity_grams"] = updatedRecipe.vessel_capacity_grams
        }

        if (updatedRecipe.container_id) edits["container_id"] = updatedRecipe.container_id
        if (updatedRecipe.wick_id) edits["wick_id"] = updatedRecipe.wick_id

        if (updatedRecipe.recipe_type === "container" && !updatedRecipe.container_id) {
            if (!isOunces) edits['vessel_capacity_grams'] = updatedRecipe.vessel_capacity_grams
            if (isOunces) edits['vessel_capacity_grams'] = convertRawOuncesToRawGrams(updatedRecipe.vessel_capacity_grams)
        }
        if (updatedRecipe.color_in_recipe && updatedRecipe.color_name && (updatedRecipe.color_drops_per_pound || updatedRecipe.color_drops_per_pound === 0) && !updatedRecipe.color_map) {
            edits['color_in_recipe'] = true
            edits['color_name'] = updatedRecipe.color_name
            edits['color_drops_per_pound'] = updatedRecipe.color_drops_per_pound
        }
        if (!updatedRecipe.color_in_recipe) {
            edits['color_in_recipe'] = false
        }
        if (updatedRecipe.color_in_recipe && getColorMap().length > 0) edits['color_in_recipe'] = true

        if (updatedRecipe.wax_blend_map) edits['wax_blend_map'] = updatedRecipe.wax_blend_map
        if (updatedRecipe.fragrance_oil_blend_map) edits['fragrance_oil_blend_map'] = updatedRecipe.fragrance_oil_blend_map
        if (updatedRecipe.color_map) edits['color_map'] = updatedRecipe.color_map
        console.log(edits)
        sendEdits(edits)
    }
    const getAdditionalUnitsClass = (unitIsOunces) => {
        if (unitIsOunces && isOunces) return "general-page-item-unit-selection-selected"
        if (!unitIsOunces && !isOunces) return "general-page-item-unit-selection-selected"
        return ""
    }
    const waxSchema2IsValid = () => {
        try {
            const waxBlendItems = updatedRecipe.wax_blend_map.blends
            const waxChoicesWithPopulatedNamesOrValues = waxBlendItems.filter((waxBlendItem) => {
                if (waxBlendItem.name) return true
                if (waxBlendItem.weight) return true
                return false
            })
            // No options
            if (waxChoicesWithPopulatedNamesOrValues.length === 0) return false
            // only one wax option
            if (waxChoicesWithPopulatedNamesOrValues.length === 1) return true
            // multiple wax options
            if (getTotalWeightsOfBlend(waxBlendItems) !== 100) return false
            const choicesMissingNames = waxChoicesWithPopulatedNamesOrValues.find(a => !a.name)
            if (choicesMissingNames) return false
            return true
        } catch (error) {
            return false
        }
    }
    const foSchema2IsValid = () => {
        try {
            const foBlendItems = updatedRecipe.wax_blend_map.blends
            const foChoicesWithPopulatedNamesOrValues = foBlendItems.filter((foBlendItem) => {
                if (foBlendItem.name) return true
                if (foBlendItem.weight) return true
                return false
            })
            // No options
            if (foChoicesWithPopulatedNamesOrValues.length === 0) return false
            // only one option
            if (foChoicesWithPopulatedNamesOrValues.length === 1) return true
            // multiple options
            if (getTotalWeightsOfBlend(foBlendItems) !== 100) return false
            const choicesMissingNames = foChoicesWithPopulatedNamesOrValues.find(a => !a.name)
            if (choicesMissingNames) return false
            return true
        } catch (error) {
            return false
        }
    }
    const isValid = () => {
        try {
            if (updatedRecipe.name.length === 0) return false
            if (updatedRecipe.wax_blend_map && !waxSchema2IsValid()) return false
            if (updatedRecipe.fragrance_oil_blend_map && !foSchema2IsValid()) return false
            if (updatedRecipe.wax.length === 0 && (updatedRecipe.schema === 1 || !updatedRecipe.schema)) return false
            if ((updatedRecipe.fragrance_oil.length === 0) && (updatedRecipe.schema === 1 || !updatedRecipe.schema)) return false
            if (updatedRecipe.fragrance_load.length === 0) return false
            if (recipe.wick_in_recipe && recipe.wick_name && !updatedRecipe.wick_name) return false
            if (recipe.wick_in_recipe && recipe.wick_id && !updatedRecipe.wick_id) return false
            if (recipe.recipe_type === "container" && !recipe.container_id && !updatedRecipe.container_name) return false
            if (recipe.container_id && !updatedRecipe.container_id) return false
            return true
        } catch (error) {
            return false
        }
    }
    const getAdditionalWickCountClass = (count) => {
        if (count === updatedRecipe.wick_count) return "general-page-item-unit-selection-selected"
        return ""
    }
    const updateWaxValueBasedOnIndex = (value, index) => {
        let blendWeights = Array.from(updatedRecipe.wax_blend_map.blends)
        blendWeights[index] = { name: value, weight: blendWeights[index]['weight']}
        const updatedBlendWeights = {
            blends: blendWeights
        }
        updatedRecipeDispatch({type: "update", prop: "wax_blend_map", value: updatedBlendWeights })
    }
    const updateWaxIdValueBasedOnIndex = (value, index) => {
        let blendWeights = Array.from(updatedRecipe.wax_blend_map.blends)
        blendWeights[index] = { name: value, weight: blendWeights[index]['weight'], supply_id: value }
        const updatedBlendWeights = {
            blends: blendWeights
        }
        updatedRecipeDispatch({type: "update", prop: "wax_blend_map", value: updatedBlendWeights })
    }
    const updateFoIdValueBasedOnIndex = (value, index) => {
        let blendWeights = Array.from(updatedRecipe.fragrance_oil_blend_map.blends)
        blendWeights[index] = { name: value, weight: blendWeights[index]['weight'], supply_id: value }
        const updatedBlendWeights = {
            blends: blendWeights
        }
        updatedRecipeDispatch({type: "update", prop: "fragrance_oil_blend_map", value: updatedBlendWeights })
    }
    const updateFoValueBasedOnIndex = (value, index) => {
        let blendWeights = Array.from(updatedRecipe.fragrance_oil_blend_map.blends)
        blendWeights[index] = { name: value, weight: blendWeights[index]['weight']}
        const updatedBlendWeights = {
            blends: blendWeights
        }
        updatedRecipeDispatch({type: "update", prop: "fragrance_oil_blend_map", value: updatedBlendWeights })
    }
    const getSymbolForBlendWeightUpdate = () => {
        const waxBlendItems = updatedRecipe.wax_blend_map.blends
        if (getTotalWeightsOfBlend(waxBlendItems) === 100) return false
        return <TiWarning style={{color: "red", fontSize: "16px"}} />
    }
    const getSymbolForFoBlendWeightUpdate = () => {
        const foBlendItems = updatedRecipe.fragrance_oil_blend_map.blends
        if (getTotalWeightsOfBlend(foBlendItems) === 100) return false
        return <TiWarning style={{color: "red", fontSize: "16px"}} />
    }
    const adjustFragranceLoad = (adjustment) => {
        if (adjustment > 0 && parseFloat(updatedRecipe.fragrance_load) === 12) return
        if (adjustment < 0 && parseFloat(updatedRecipe.fragrance_load) === 0) return
        const proposedLoad = parseFloat(updatedRecipe.fragrance_load) + parseFloat(adjustment)
        updatedRecipeDispatch({type: "update", prop: "fragrance_load", value: proposedLoad.toFixed(1)})
    }
    const updateAvailableSupplies = () => {
        try {
            if (!globalState) return false
            if (!globalState.supplies) return false
            const copyOfSupplies = Array.from(globalState.supplies)
            const onlyContainers = copyOfSupplies.filter(s => s.type === "container")
            const sortedContainers = sortByProperty(onlyContainers, "supply_name")
            setValidContainers(sortedContainers)
            const onlyWicks = copyOfSupplies.filter(s => s.type === "wick")
            const sortedWicks = sortByProperty(onlyWicks, "supply_name")
            setValidWicks(sortedWicks)
            const onlyWax = copyOfSupplies.filter(s => s.type === "wax")
            const sortedWax = sortByProperty(onlyWax, "supply_name")
            setValidWax(sortedWax)
            const onlyFragrance = copyOfSupplies.filter(s => s.type === "fragrance")
            const sortedFragrance = sortByProperty(onlyFragrance, "supply_name")
            setValidFragrance(sortedFragrance)
        } catch (error) {
            console.log(error)
            return
        }
    }
    const getColorMap = () => {
        try {
            let colorMap  = []
            if (updatedRecipe.color_map.colors) colorMap = updatedRecipe.color_map.colors
            return colorMap
        } catch (error) {
            return []
        }
    }
    useEffect(() => {
        if (!recipe) return
        updatedRecipeDispatch({type: "init"})
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (!globalState) return
        if (!globalState.supplies) return
        if (!globalState.supplies.length === 0) return
        updateAvailableSupplies()
    // eslint-disable-next-line
    }, [globalState])
    return (
        <div className="modal-boundary">
            <div className="modal-backdrop">
                <div className="modal-holder">
                    <div className="modal-overlay">

                        {(isEditingBlendWeights && updatedRecipe.schema === 2) && <ModalEditBlendWeights blendWeights={updatedRecipe.wax_blend_map.blends} hideModal={() => setIsEditingBlendWeights(false)} modalTitle="Edit wax blend" updateBlendWeights={saveBlendWeightEdits} />}
                        {(isEditingFoBlendWeights && updatedRecipe.schema === 2) && <ModalEditBlendWeights blendWeights={updatedRecipe.fragrance_oil_blend_map.blends} hideModal={() => setIsEditingFoBlendWeights(false)} modalTitle="Edit fragrance oil blend" updateBlendWeights={saveFoBlendWeightEdits} />}

                        <div className="modal-header">
                            <h2>Edit Recipe</h2>
                            <MdClear onClick={hideModal}/>
                        </div>

                        <div className="modal-edit-section">
                            <h3>Recipe Name</h3>
                            <input type="text" placeholder="What should this recipe be called?" value={updatedRecipe.name} onChange={(e) => updatedRecipeDispatch({type: "update", prop: "name", value: e.target.value })} maxLength={48} />
                        </div>

                        {updatedRecipe.isImmutable && updatedRecipe.loaded &&
                        <div style={{color: "grey", textAlign: "center"}}>
                            <em>Recipes can't be changed after they're used for batches</em>
                        </div>
                        }

                        {/* No batches exist - existing chosen attributes can change */}
                        {!updatedRecipe.isImmutable && updatedRecipe.loaded &&
                        <div className="modal-edit-sections">

                            {/* Wax: Schema 1 */}
                            {(updatedRecipe.schema === 1 || !updatedRecipe.schema || !updatedRecipe.wax_blend_map) &&
                            <div className="modal-edit-section">
                                <h3>Wax</h3>
                                <input type="text" placeholder="Enter a wax name" value={updatedRecipe.wax} onChange={(e) => updatedRecipeDispatch({type: "update", prop: "wax", value: e.target.value })} maxLength={36} />
                            </div>
                            }
                            {/* Wax: Schema 2 */}
                            {(updatedRecipe.schema === 2) && updatedRecipe.wax_blend_map &&
                            <div className="modal-edit-section">
                                <h3>Wax</h3>
                                {updatedRecipe.wax_blend_map.blends.map((waxBlend, index) => (
                                    <div className="general-page-create-input-text general-page-create-option-single-weighted-blend util-row util-align-center" key={index}>
                                        <span>{waxBlend.weight}%</span>
                                        {waxBlend.supply_id && validWax.length > 0 &&
                                            <div className="general-page-create-input-text" style={{marginLeft: 0, marginRight: 0, width: "100%"}}>
                                                <select value={updatedRecipe.wax_blend_map.blends[index].supply_id} onChange={(e) => updateWaxIdValueBasedOnIndex(e.target.value, index)}>
                                                    {validWax.map((wax) => (
                                                        <option key={wax._id} value={wax._id}>{wax.supply_name}{wax.supplier_name && ` (${wax.supplier_name})`}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        }
                                        {!waxBlend.supply_id &&
                                            <input placeholder="Enter a wax name" type="text" value={waxBlend.name} onChange={(e) => updateWaxValueBasedOnIndex(e.target.value, index)} maxLength={48}/>
                                        }
                                    </div>
                                ))}
                                <div className="form-blend-option-buttons">
                                    {updatedRecipe.wax_blend_map.blends.length > 1 && <button onClick={() => setIsEditingBlendWeights(true)}>{getSymbolForBlendWeightUpdate()}Update blend weights</button>}
                                </div>
                            </div>
                            }
                            
                            {parseFloat(updatedRecipe.fragrance_load) === 0 &&
                            <div className="modal-edit-section">
                                <h3>Fragrance Oil</h3>
                                <p style={{color: "grey"}}>This recipe was created without fragrance.  To add fragrance oil, please <a href={`/create/recipe?based_on=${updatedRecipe._id}`}>create a new recipe</a></p>
                            </div>
                            }

                            {parseFloat(updatedRecipe.fragrance_load) > 0 &&
                            <>
                            {/* Fragrance Oil: Schema 1 & Schema 2 with no blend map */}
                            {(updatedRecipe.schema === 1 || !updatedRecipe.schema || !updatedRecipe.fragrance_oil_blend_map) &&
                            <div className="modal-edit-section">
                                <h3>Fragrance Oil</h3>
                                <input type="text" placeholder="What fragrance oil are you using?" value={updatedRecipe.fragrance_oil} onChange={(e) => updatedRecipeDispatch({type: "update", prop: "fragrance_oil", value: e.target.value })} maxLength={36} />
                            </div>
                            }

                            {/* Fragrance Oil: Schema 2 with blend map */}
                            {(updatedRecipe.schema === 2 && updatedRecipe.fragrance_oil_blend_map) &&
                            <div className="modal-edit-section">
                                <h3>Fragrance Oil</h3>
                                {updatedRecipe.fragrance_oil_blend_map.blends.map((foBlend, index) => (
                                    <div className="general-page-create-input-text general-page-create-option-single-weighted-blend" key={index}>
                                        <span>{foBlend.weight}%</span>
                                        {foBlend.supply_id && validWax.length > 0 &&
                                            <div className="general-page-create-input-text" style={{marginLeft: 0, marginRight: 0, marginTop: 0, width: "100%"}}>
                                                <select value={updatedRecipe.fragrance_oil_blend_map.blends[index].supply_id} onChange={(e) => updateFoIdValueBasedOnIndex(e.target.value, index)}>
                                                    {validFragrance.map((fragrance) => (
                                                        <option key={fragrance._id} value={fragrance._id}>{fragrance.supply_name}{fragrance.supplier_name && ` (${fragrance.supplier_name})`}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        }
                                        {!foBlend.supply_id &&
                                            <input placeholder="Enter a wax name" type="text" value={foBlend.name} onChange={(e) => updateFoValueBasedOnIndex(e.target.value, index)} maxLength={48}/>
                                        }
                                    </div>
                                ))}
                                <div className="form-blend-option-buttons">
                                    {updatedRecipe.fragrance_oil_blend_map.blends.length > 1 && <button onClick={() => setIsEditingFoBlendWeights(true)}>{getSymbolForFoBlendWeightUpdate()}Update blend weights</button>}
                                </div>
                            </div>
                            }
                            
                            <div className="modal-edit-section">
                                <h3>Fragrance Load</h3>
                                <input autoComplete="off" type='range' step="0.1" min="0" max="12" list='size' value={updatedRecipe.fragrance_load} onChange={(e) => updatedRecipeDispatch({type: "update", prop: "fragrance_load", value: e.target.value})}/>
                                <datalist id="size">
                                    <option value="1"></option>
                                    <option value="2"></option>
                                    <option value="3"></option>
                                    <option value="4"></option>
                                    <option value="5"></option>
                                    <option value="6"></option>
                                    <option value="7"></option>
                                    <option value="8"></option>
                                    <option value="9"></option>
                                    <option value="10"></option>
                                    <option value="11"></option>
                                </datalist>
                                <div className="general-page-create-options-single">
                                    <div className="util-row util-align-center" style={{gap: "14px"}}>
                                        <span className="general-page-create-button-adjust" onClick={() => adjustFragranceLoad(-0.1)}><GrFormSubtract/></span>
                                        <div className="general-page-create-option-single">{updatedRecipe.fragrance_load}%</div>
                                        <span className="general-page-create-button-adjust" onClick={() => adjustFragranceLoad(0.1)}><GrFormAdd/></span>
                                    </div>
                                </div>
                            </div>
                            </>
                            }

                            {/* Legacy Wicks - String-based */}
                            {recipe.wick_in_recipe && recipe.wick_name &&
                            <div className="modal-edit-section">
                                <h3>Wick Name</h3>
                                <input type="text" placeholder="Which wick are you using?" value={updatedRecipe.wick_name} onChange={(e) => updatedRecipeDispatch({type: "update", prop: "wick_name", value: e.target.value })} maxLength={36} />
                            </div>
                            }

                            {/* Wicks based on inventory */}
                            {recipe.wick_in_recipe && recipe.wick_id && validWicks.length > 0 &&
                            <div className="modal-edit-section">
                                <h3>Wick Name</h3>
                                <div className="general-page-create-input-text" style={{marginLeft: 0, marginRight: 0}}>
                                    <select value={updatedRecipe.wick_id} onChange={(e) => updatedRecipeDispatch({ type: "update", prop: "wick_id", value: e.target.value})}>
                                        {validWicks.map((wick) => (
                                            <option key={wick._id} value={wick._id}>{wick.supply_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            }

                            {recipe.recipe_type !== "wax_melt" &&
                            <div className="modal-edit-section">
                                <h3>Wick Count</h3>
                                <div className="general-page-item-unit-selection">
                                    <div className="util-row util-align-center" style={{gap: "12px"}}>
                                        <span onClick={() => updatedRecipeDispatch({type: "update", prop: "wick_count", value: 1})} className={getAdditionalWickCountClass(1)}>single</span>
                                        <span onClick={() => updatedRecipeDispatch({type: "update", prop: "wick_count", value: 2})} className={getAdditionalWickCountClass(2)}>double</span> 
                                    </div>
                                </div>
                            </div>
                            }

                            {recipe.recipe_type === "container" && !recipe.container_id &&
                            <>
                            <div className="modal-edit-section">
                                <h3>Vessel Name</h3>
                                <input type="text" placeholder="What do you want to call this vessel?" value={updatedRecipe.container_name} onChange={(e) => updatedRecipeDispatch({type: "update", prop: "container_name", value: e.target.value })} />
                            </div>

                            <div className="modal-edit-section">
                                {updatedRecipe.recipe_type === "container" && <h3>Vessel Capacity</h3>}
                                {updatedRecipe.recipe_type === "containerless" && <h3>Mold Capacity</h3>}
                                <input type="number" placeholder="How much can this container hold?" value={updatedRecipe.vessel_capacity_grams} onChange={(e) => updatedRecipeDispatch({type: "update", prop: "vessel_capacity_grams", value: e.target.value })} />
                                <div className="general-page-item-unit-selection">
                                    <div className="general-page-create-option-label">Units</div>
                                    <div className="util-row util-align-center" style={{gap: "12px"}}>
                                        <span onClick={() => setIsOunces(false)} className={getAdditionalUnitsClass(false)}>grams</span>
                                        <span onClick={() => setIsOunces(true)} className={getAdditionalUnitsClass(true)}>ounces</span> 
                                    </div>
                                </div>
                            </div>
                            </>
                            }

                            {recipe.container_id && validContainers.length > 0 &&
                            <>
                            <div className="modal-edit-section">
                                <h3>Select a container</h3>
                                <div className="general-page-create-input-text" style={{marginLeft: 0, marginRight: 0}}>
                                    <select value={updatedRecipe.container_id} onChange={(e) => updatedRecipeDispatch({ type: "update", prop: "container_id", value: e.target.value})}>
                                        {validContainers.map((container) => (
                                            <option key={container._id} value={container._id}>{container.supply_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            </>}

                            <div className="modal-edit-section">
                                <h3>Color</h3>
                                <div className="general-page-create-option-single" onClick={() => updatedRecipeDispatch({ type: "update", prop: 'color_in_recipe', value: false })}>
                                    {!updatedRecipe.color_in_recipe && <GrRadialSelected/>}
                                    {updatedRecipe.color_in_recipe &&<GrRadial/>}
                                    No color
                                </div>
                                <div className="general-page-create-option-single" onClick={() => updatedRecipeDispatch({ type: "update", prop: 'color_in_recipe', value: true })}>
                                    {updatedRecipe.color_in_recipe && <GrRadialSelected/>}
                                    {!updatedRecipe.color_in_recipe &&<GrRadial/>}
                                    Add color
                                </div>
                            </div>
                            {updatedRecipe.color_in_recipe && !updatedRecipe.color_map &&
                            <>
                            <div className="modal-edit-section">
                                <h3>Color Name</h3>
                                <input type="text" placeholder="What color(s) are you using?" value={updatedRecipe.color_name} onChange={(e) => updatedRecipeDispatch({type: "update", prop: "color_name", value: e.target.value })} />
                            </div>
                            <div className="modal-edit-section">
                                <h3>{updatedRecipe.color_units} per pound</h3>
                                <input style={{fontSize: "16px", padding: "12px"}} type='number' min="0" value={updatedRecipe.color_drops_per_pound} onChange={(e) => updatedRecipeDispatch({type: "update", prop: "color_drops_per_pound", value: e.target.value})}/>
                            </div>
                            </>
                            }
                            {updatedRecipe.color_in_recipe && updatedRecipe.color_map &&
                            <>
                            <div className="modal-edit-section">
                                <h3>Color Design</h3>
                                <div className="general-page-create-option-small-headings">
                                    <span>Name</span>
                                    <span>{updatedRecipe.color_units} per Pound</span>
                                </div>

                                {/* Colors initially added to recipe */}
                                {getColorMap().length > 0 &&
                                <>
                                {getColorMap().map((color, index) => (
                                    <div className="general-page-create-input-text general-page-create-option-single-weighted-blend" key={index}>
                                        <input placeholder="Enter a color name" type="text" maxLength={48} value={updatedRecipe.color_map.colors[index].color_name} onChange={(e) => updatedRecipeDispatch({name: e.target.value, index: index, type: "color_map_update", update: "name"})}/>
                                        <input className="general-page-create-option-small-input" type="number" min="0" value={updatedRecipe.color_map.colors[index].color_drops_per_pound} onChange={(e) => updatedRecipeDispatch({drops: e.target.value, index: index, type: "color_map_update", update: "drops"})} />
                                    </div>
                                ))}
                                </>
                                }

                                {/* No colors initially added to recipe */}
                                {getColorMap().length === 0 &&
                                    <div className="general-page-create-input-text general-page-create-option-single-weighted-blend">
                                        <input placeholder="Enter a color name" type="text" maxLength={48} value={newColorToAdd.name} onChange={(e) => updatedRecipeDispatch({name: e.target.value, index: 0, type: "color_map_update_new", update: "name"})}/>
                                        <input className="general-page-create-option-small-input" type="number" min="0" value={newColorToAdd.color_drops_per_pound} onChange={(e) => updatedRecipeDispatch({drops: e.target.value, index: 0, type: "color_map_update_new", update: "drops"})} />
                                    </div>
                                }
                            </div>
                            </>
                            }
                        </div>
                        }

                        <div className="modal-buttons">
                            {!isValid() && <span style={{backgroundColor: "grey"}}>Save</span>}
                            {isValid() && <span onClick={saveUpdates}>Save</span>}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}