import React from 'react'
import './BatchPlanDuplicateModal.css'

export default function BatchPlanDuplicateModal({ onClose, onSelect, recentBatchPlans }) {
    return (
        <div className="batch-plan-duplicate-modal-overlay">
            <div className="batch-plan-duplicate-modal">
                <h2>Select Batch to Copy From</h2>
                <div className="batch-plan-duplicate-list">
                    {recentBatchPlans.map((plan) => (
                        <div 
                            key={plan._id} 
                            className="batch-plan-duplicate-item"
                            onClick={() => onSelect(plan.batch_id)}
                        >
                            {plan.batch.name}
                        </div>
                    ))}
                </div>
                <div className="batch-plan-duplicate-buttons">
                    <button 
                        className="batch-plan-duplicate-button cancel" 
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
} 