import React from 'react'

export default function InternalNavigationSections({sections, view, setView}) {
    const getClass = (section) => {
        if (section === view) return "nav-internal-section-selected"
        return ""
    }
    return (
        <div className="nav-internal-sections">
            {sections.map((section) => (
                <span className={getClass(section)} onClick={() => setView(section)} key={section}>{section}</span>
            ))}
        </div>
    )
}
