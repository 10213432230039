import React, { useState, useEffect } from 'react';
import { MdClear } from 'react-icons/md';
import { GrFormSubtract, GrFormAdd } from 'react-icons/gr';
import { addDays, isAfter, startOfDay, format } from 'date-fns';
import './NotificationSetupModal.css';

export default function NotificationSetupModal({ 
    hideModal, 
    onConfirm,
    onCancel,
    initialDate,
    cureStartTimestamp,
    isStageChange = false 
}) {
    const [selectedDate, setSelectedDate] = useState(initialDate || addDays(new Date(), 14));
    const [notificationType, setNotificationType] = useState('length');
    const [customDate, setCustomDate] = useState(false);
    const [cureLengthDays, setCureLengthDays] = useState(14);
    const [error, setError] = useState('');

    // Reset selected date when switching to custom mode
    const handleSwitchToCustom = () => {
        // Set a default future date when switching to custom mode
        setSelectedDate(addDays(new Date(), 14));
        setCustomDate(true);
        setNotificationType('custom');
    };

    // Validate the selected date is at least 1 day in the future
    const validateDate = (date) => {
        const tomorrow = startOfDay(addDays(new Date(), 1));
        if (!isAfter(date, tomorrow)) {
            setError('Notification must be at least 1 day in the future');
            return false;
        }
        setError('');
        return true;
    };

    const handleCustomDateChange = (e) => {
        const dateValue = e.target.value;
        if (!dateValue) return;
        
        const newDate = new Date(dateValue);
        if (isNaN(newDate.getTime())) return;

        setSelectedDate(newDate);
        validateDate(newDate);
    };

    const handleConfirm = () => {
        if (notificationType === 'custom') {
            if (validateDate(selectedDate)) {
                onConfirm({
                    date: selectedDate,
                    type: 'custom'
                });
                hideModal();
            }
        } else {
            onConfirm({
                date: cureStartTimestamp ? addDays(new Date(cureStartTimestamp), cureLengthDays) : addDays(new Date(), cureLengthDays),
                type: 'length',
                lengthDays: cureLengthDays
            });
            hideModal();
        }
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
            hideModal();
        } else {
            hideModal();
        }
    };

    const adjustCureLength = (adjustment) => {
        const newLength = cureLengthDays + adjustment;
        if (newLength >= 1 && newLength <= 21) {
            setCureLengthDays(newLength);
            if (cureStartTimestamp) {
                const newDate = addDays(new Date(cureStartTimestamp), newLength);
                setSelectedDate(newDate);
            }
        }
    };

    useEffect(() => {
        if (customDate) {
            validateDate(selectedDate);
        }
    }, [selectedDate, customDate]);

    return (
        <div className="modal-boundary">
            <div className="modal-backdrop">
                <div className="modal-holder">
                    <div className="modal-overlay">
                        <div className="modal-header">
                            <h2>{isStageChange ? 'Set Cure Timer Notification' : 'Notification Setup'}</h2>
                            <MdClear onClick={hideModal}/>
                        </div>

                        <div className="notification-setup-content">
                            <p className="notification-setup-description">
                                {cureStartTimestamp 
                                    ? "Set how long you'd like to cure this batch for. You'll receive an email notification when the cure period is complete."
                                    : "Choose when you'd like to receive a notification for this batch."}
                            </p>

                            {!customDate && (
                                <div className="notification-cure-length">
                                    <div className="notification-cure-length-controls">
                                        <span className="notification-adjust-button" onClick={() => adjustCureLength(-1)}><GrFormSubtract/></span>
                                        <div className="notification-cure-length-display">
                                            <span className="notification-cure-length-value">{cureLengthDays}</span>
                                            <span className="notification-cure-length-label">days</span>
                                        </div>
                                        <span className="notification-adjust-button" onClick={() => adjustCureLength(1)}><GrFormAdd/></span>
                                    </div>
                                    <input 
                                        type="range" 
                                        min="1" 
                                        max="21" 
                                        value={cureLengthDays} 
                                        onChange={(e) => {
                                            const newLength = parseInt(e.target.value);
                                            setCureLengthDays(newLength);
                                            if (cureStartTimestamp) {
                                                setSelectedDate(addDays(new Date(cureStartTimestamp), newLength));
                                            }
                                        }}
                                    />
                                    {cureStartTimestamp && (
                                        <div className="notification-date-preview">
                                            Notification on: {format(addDays(new Date(cureStartTimestamp), cureLengthDays), 'MMM d, yyyy')}
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className="notification-custom-date">
                                {!customDate ? (
                                    <button className="notification-custom-button" onClick={handleSwitchToCustom}>
                                        Set custom date instead
                                    </button>
                                ) : (
                                    <>
                                        <div className="notification-date-picker">
                                            <input
                                                type="datetime-local"
                                                value={format(selectedDate, "yyyy-MM-dd'T'HH:mm")}
                                                onChange={handleCustomDateChange}
                                                min={format(addDays(new Date(), 1), "yyyy-MM-dd'T'HH:mm")}
                                            />
                                            <button 
                                                className="notification-preset-link" 
                                                onClick={() => {
                                                    setCustomDate(false);
                                                    setNotificationType('length');
                                                }}
                                            >
                                                Use cure length instead
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>

                            {error && <div className="notification-error">{error}</div>}

                            <div className="modal-buttons">
                                {initialDate && (
                                    <button 
                                        className="notification-delete-button" 
                                        onClick={handleCancel}
                                    >
                                        Remove Notification
                                    </button>
                                )}
                                <button className="notification-cancel-button" onClick={hideModal}>Cancel</button>
                                <button 
                                    className="notification-confirm-button" 
                                    onClick={handleConfirm}
                                    disabled={customDate && error}
                                >
                                    Set Notification
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
} 