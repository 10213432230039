import React from 'react'
import { useEffect, useState } from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { IoFlameSharp, IoWater, IoFlask } from 'react-icons/io5'
import { RiPlantFill } from 'react-icons/ri'
import LoadingSymbol from './misc/LoadingSymbol'
import { useNavigate } from 'react-router-dom'
import { parseISO, formatDistanceToNowStrict, format } from 'date-fns'
import { formatNumber } from '../common/Helpers'
import TaskList from './tasks/TaskList'
import { useSubscription } from '../context/SubscriptionContext'
import PopupQuestion from './popups/PopupQuestion'

export default function Home({globalState, dispatch, fetchUserLibrary}) {
    const navigate = useNavigate();
    const { subscriptionStatus, isLoading: isLoadingSubscription } = useSubscription();
    const [consumedSupplies, setConsumedSupplies] = useState(() => { return [
        {
            name: "Wax",
            value: false,
            units: "lbs poured",
            symbol: "wax"
        },
        {
            name: "Fragrance Oil",
            value: false,
            units: "lbs mixed",
            symbol: "fragrance"
        },
        {
            name: "Wicks",
            value: false,
            units: "attached",
            symbol: "wicks"
        },
        {
            name: "Containers",
            value: false,
            units: "made into candles",
            symbol: "containers"
        }
    ] })
    const isNewUser = () => {
        try {
            if (globalState.active_subscription && globalState.subscription_status === "active") return false
            return true 
        } catch (error) {
            return true
        } 
    }
    const updateSupplyUsageData = () => {
        if (!globalState.loaded) return
        let totalWaxGrams = 0
        let totalFOGrams = 0
        let totalContainers = 0
        let totalWicks = 0
        try {
            const waxWeightArray = globalState.batches.map(b => b.weight_wax_grams)
            totalWaxGrams = waxWeightArray.reduce((partialSum, a) => partialSum + a, 0);
            const foWeightArray = globalState.batches.map(b => b.weight_fragrance_grams)
            totalFOGrams = foWeightArray.reduce((partialSum, a) => partialSum + a, 0);
            const wickCount = globalState.candles.map(c => c.recipe.wick_count)
            let validWickCounts = wickCount.filter(w => w !== undefined)
            totalWicks = validWickCounts.reduce((partialSum, a) => partialSum + a, 0);
            const candlesWithContainers = globalState.candles.filter(a => a.recipe.recipe_type === "container")
            totalContainers = candlesWithContainers.length
            const updatedCalcs = [
                {
                    name: "Wax",
                    value: formatNumber((totalWaxGrams/453.592).toFixed(2)),
                    units: "lbs poured",
                    symbol: "wax"
                },
                {
                    name: "Fragrance Oil",
                    value: formatNumber((totalFOGrams/453.592).toFixed(2)),
                    units: "lbs mixed",
                    symbol: "fragrance"
                },
                {
                    name: "Wicks",
                    value: formatNumber(totalWicks),
                    units: "attached",
                    symbol: "wicks"
                },
                {
                    name: "Containers",
                    value: formatNumber(totalContainers),
                    units: "filled",
                    symbol: "containers"
                }
            ]
            setConsumedSupplies(updatedCalcs)
        } catch (error) {
            return
        }
    }
    const getRecentRecipe = () => {
        try {
            const masterList = globalState.recipes
            const sorted = masterList.sort((a, b) => {
                return parseISO(b.updatedAt) - parseISO(a.updatedAt)
            })
            return sorted[0]
        } catch (error) {
            console.log(error)
            return false
        } 
    }
    const getRecentItems = () => {
        try {
            // combine into one array
            const masterList = globalState.batches
            // sort array by updatedAt
            const sorted = masterList.sort((a, b) => {
                return parseISO(b.updatedAt) - parseISO(a.updatedAt)
            })
            // return first 5
            return sorted.slice(0,3)
        } catch (error) {
            return []
        }
    }
    useEffect(() => {
        fetchUserLibrary()
        dispatch({type: "nav", payload: {view: "home"}})
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        updateSupplyUsageData()
    // eslint-disable-next-line
    }, [globalState])
    useEffect(() => {
        if (!isLoadingSubscription && subscriptionStatus) {
            if (subscriptionStatus.needsPlanSelection || !subscriptionStatus.isActive) {
                navigate('/plans');
                return;
            }
        }
    }, [subscriptionStatus, isLoadingSubscription, navigate]);

    // Combine loading states
    const isPageLoading = !globalState.loaded || isLoadingSubscription;

    return (
        <div>
            {!isPageLoading && globalState.loaded &&
            <div className="home-container">
                <PopupQuestion />
                {!isNewUser() &&
                <div>
                    <div className="responsive-recipe-container">
                        <div style={{marginBottom: "120px"}}>
                            <div className="util-row util-space-between util-align-end" style={{marginBottom: "20px"}}>
                                <div className="home-authenticated-desktop-header">
                                    <h3> <span style={{fontWeight: 400}}>Hello,</span> {globalState.user.first_name}</h3>
                                    <p>{format(new Date(), 'eeee, LLLL do')}</p>
                                </div>
                            </div>
                            <div className="home-featured-section">
                                <h2>Summary</h2>
                                <div className="home-featured-items">
                                    <div className="home-featured-item" onClick={() => navigate("/candles/recipes")}>
                                        <h3>Recipes</h3>
                                        <p>{formatNumber(globalState.recipes.length)}</p>
                                    </div>
                                    <div className="home-featured-item" onClick={() => navigate("/candles/batches")}>
                                        <h3>Batches</h3>
                                        <p>{formatNumber(globalState.batches.length)}</p>
                                    </div>
                                    <div className="home-featured-item" onClick={() => navigate("/candles/candles")}>
                                        <h3>Candles</h3>
                                        <p>{formatNumber(globalState.candles.length)}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="home-featured-section">
                                <h2>Tasks & Reminders</h2>
                                <TaskList globalState={globalState} fetchUserLibrary={fetchUserLibrary} dispatch={dispatch} />
                            </div>

                            <div className="home-featured-section">
                                <h2>Latest Recipe</h2>
                                {globalState.recipes.length === 0 &&
                                <div className="home-featured-get-started-section">
                                    <p>Start by creating a recipe:</p>
                                    <div className="home-featured-get-started-section-button" onClick={() => navigate("/create/recipe")}>+ New recipe</div>
                                </div>
                                }
                                {getRecentRecipe() && 
                                <>
                                <div className="home-featured-recent-items">
                                    <RecentItem key={getRecentRecipe()._id} item={getRecentRecipe()} navigate={navigate}/>
                                </div>
                                <div className="home-auth-button-more"><span onClick={() => navigate(`/candles/recipes`)}>View More</span></div>
                                </>
                                }
                            </div>
                            
                            <div className="home-featured-section">
                                <h2>Recent Batches</h2>
                                {globalState.recipes.length === 0 &&
                                <div className="home-featured-get-started-section">
                                    <p>Start by creating a recipe:</p>
                                    <div className="home-featured-get-started-section-button" onClick={() => navigate("/create/recipe")}>+ New recipe</div>
                                </div>
                                }
                                {getRecentItems().length > 0 &&
                                <>
                                <div className="home-featured-recent-items">
                                    {getRecentItems().map((recentItem) => (
                                        <RecentItem key={recentItem._id} item={recentItem} navigate={navigate}/>
                                    ))}
                                </div>
                                <div className="home-auth-button-more"><span onClick={() => navigate(`/candles/batches`)}>See All</span></div>
                                </>
                                }
                            </div>

                            <div className="home-featured-section">
                                <h2>Supplies</h2>
                                <div className="home-auth-supplies">
                                    {consumedSupplies.map((supply) => (
                                        <div className="home-auth-supply-item" key={supply.name}>
                                            <SupplySymbol name={supply.symbol} />
                                            <div>
                                                <h5>{supply.name}</h5>
                                                {(supply.value || (supply.value === 0)) && <p>{supply.value} {supply.units}</p>}
                                                {!supply.value && supply.value !== 0 && <p>Loading...</p> }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="recipe-container-desktop">
                        <div className="util-row util-space-between util-align-end" style={{marginBottom: "40px"}}>
                            <div className="home-authenticated-desktop-header">
                                <h3> <span style={{fontWeight: 400}}>Hello,</span> {globalState.user.first_name}</h3>
                                <p>{format(new Date(), 'eeee, LLLL do')}</p>
                            </div>
                        </div>
                        <div className="home-authenticated-desktop">
                            
                            <div className="home-authenticated-desktop-main">
                                <div className="home-auth-featured-sections">
                                    <div className="home-auth-featured-section">
                                        <h4>Supplies</h4>
                                        <div className="home-auth-supplies">
                                            {consumedSupplies.map((supply) => (
                                                <div className="home-auth-supply-item" key={supply.name}>
                                                    <SupplySymbol name={supply.symbol} />
                                                    <div>
                                                        <h5>{supply.name}</h5>
                                                        {(supply.value || (supply.value === 0)) && <p>{supply.value} {supply.units}</p>}
                                                        {!supply.value && supply.value !== 0 && <p>Loading...</p> }
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="home-auth-featured-section">
                                        <h4>Tasks & Reminders</h4>
                                        <TaskList globalState={globalState} fetchUserLibrary={fetchUserLibrary} dispatch={dispatch} />
                                    </div>
                                </div>
                            </div>
                            <div className="home-authenticated-desktop-side">
                                <div className="home-auth-featured-sections">
                                    <div className="home-auth-featured-section">
                                        <h4>Summary</h4>
                                        <div className="home-auth-summaries">
                                            <div className="home-auth-summary" onClick={() => navigate(`/candles/recipes`)}>
                                                <div className="home-auth-summary-title">Recipes</div>
                                                <div>{globalState.recipes.length}</div>
                                            </div>
                                            <div className="home-auth-summary" onClick={() => navigate(`/candles/batches`)}>
                                                <div className="home-auth-summary-title">Batches</div>
                                                <div>{globalState.batches.length}</div>
                                            </div>
                                            <div className="home-auth-summary" onClick={() => navigate(`/candles/candles`)}>
                                                <div className="home-auth-summary-title">Candles</div>
                                                <div>{globalState.candles.length}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="home-auth-featured-section">
                                        <h4>Latest Recipe</h4>
                                        {getRecentRecipe() && <div className="home-auth-featured-item" onClick={() => navigate(`/recipe/${getRecentRecipe()._id}`)}>{getRecentRecipe().name}</div>}
                                        <div className="home-auth-button-more"><span onClick={() => navigate(`/candles/recipes`)}>View More</span></div>
                                    </div>
                                    <div className="home-auth-featured-section">
                                        <h4>Recent Batches</h4>
                                        {getRecentItems().map((batch) => (
                                            <div className="home-auth-featured-item" onClick={() => navigate(`/candles/batch/${batch._id}`)} key={batch._id}>{batch.name}</div>
                                        ))}
                                        <div className="home-auth-button-more"><span onClick={() => navigate(`/candles/batches`)}>See All</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }

                {/* INITIAL SIGNUP IF USER DOES NOT HAVE AN ACCOUNT */}
                {isNewUser() &&
                <div className="home-initial-signup-section">
                    <div style={{display: "flex", justifyContent: "center"}}><LoadingSymbol/></div>
                </div>
                }
            </div>
            }

            {isPageLoading &&
            <div style={{display: "flex", justifyContent: "center"}}><LoadingSymbol/></div>
            }
            
        </div>
        
    )
}

function RecentItem({item, navigate}) {
    const getType = () => {
        if (item.batch_id) return "Candle" // candle
        if (item.recipe_type) return "Recipe" // recipe
        return "Batch"
    }
    const goToItem = () => {
        if (item.batch_id) return navigate(`/candles/candle/${item._id}`) // candle
        if (item.recipe_type) return navigate(`/recipe/${item._id}`)
        return navigate(`/candles/batch/${item._id}`)
    }
    return (
        <div className="home-featured-recent-item" onClick={goToItem}>
            <div>
                <p>{item.name}</p>
                <div>{formatDistanceToNowStrict(parseISO(item.updatedAt))} ago • {getType()}</div>
            </div>
            <HiArrowNarrowRight/>
        </div>
    )
}

function SupplySymbol({name}) {
    const getSymbolStyle = () => {
        if (name === "wicks") return { color: "#0f860b70" }
        if (name === "wax") return { color: "#0b0b8670" }
        if (name === "containers") return { color: "#860b8270" }
        if (name === "fragrance") return { color: "#86420b70" }
    }
    const getBoxStyle = () => {
        if (name === "wicks") return { backgroundColor: "#0f860b24" }
        if (name === "wax") return { backgroundColor: "#0b0b8624" }
        if (name === "containers") return { backgroundColor: "#860b8224" }
        if (name === "fragrance") return { backgroundColor: "#86420b24" }
    }
    return (
        <div className="home-auth-supply-item-symbol" style={getBoxStyle()}>
            {name === "wicks" && <IoFlameSharp style={getSymbolStyle()}/>}
            {name === "wax" && <RiPlantFill style={getSymbolStyle()} />}
            {name === "containers" && <IoFlask style={getSymbolStyle()} />}
            {name === "fragrance" && <IoWater style={getSymbolStyle()} />}  
        </div>
    )
}