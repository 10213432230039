import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSubscription } from '../context/SubscriptionContext';
import LoadingSymbol from './misc/LoadingSymbol';
import './ProtectedRoute.css';

export default function ProtectedRoute({ children }) {
    const { 
        subscriptionStatus, 
        isLoading, 
        error, 
        checkSubscriptionStatus 
    } = useSubscription();

    // Verify subscription on mount and when subscription status changes
    useEffect(() => {
        if (!isLoading && !subscriptionStatus) {
            checkSubscriptionStatus();
        }
    }, [subscriptionStatus, isLoading, checkSubscriptionStatus]);

    if (isLoading) {
        return (
            <div className="protected-route-loading-container">
                <LoadingSymbol />
            </div>
        );
    }

    if (error) {
        return (
            <div className="protected-route-error-container">
                <p>Unable to verify subscription status. Please try again later.</p>
                <button onClick={() => checkSubscriptionStatus(true)}>
                    Retry
                </button>
            </div>
        );
    }

    if (!subscriptionStatus?.isActive) {
        return <Navigate to="/plans" replace />;
    }

    return children;
} 