import React, { useState } from 'react';
import './BatchTableStageSelector.css';
import Api from '../../common/APIUtils';
import CureTimerModal from './CureTimerModal';

const STAGES = [
    { id: 'NEW', label: 'New' },
    { id: 'POURING', label: 'Pouring' },
    { id: 'CURING', label: 'Curing' },
    { id: 'TESTING', label: 'Testing' },
    { id: 'DONE', label: 'Done' }
];

const BatchTableStageSelector = ({ batch, onClose, onStageChange }) => {
    const [selectedStage, setSelectedStage] = useState(batch.stage || 'DONE');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showCureModal, setShowCureModal] = useState(false);
    const api = new Api();

    const handleStageSelect = (newStage) => {
        if (newStage === selectedStage) return;
        
        if (newStage === 'CURING' && batch.cure_start_timestamp && batch.stage !== 'CURING') {
            setSelectedStage(newStage);
            setShowCureModal(true);
            return;
        }

        setSelectedStage(newStage);
    };

    const handleSave = async () => {
        if (isLoading || selectedStage === batch.stage) {
            onClose();
            return;
        }

        setIsLoading(true);
        setError(null);
        
        try {
            const updateData = { stage: selectedStage };
            
            if (selectedStage === 'CURING' && (!batch.cure_start_timestamp || batch.stage !== 'CURING')) {
                updateData.cure_start_timestamp = new Date();
            }

            await api.updateBatch({
                batch_id: batch._id,
                ...updateData
            });

            onStageChange(selectedStage);
            onClose();
        } catch (err) {
            console.error('Error updating batch stage:', err);
            setError('Failed to update stage. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeepExisting = () => {
        setShowCureModal(false);
        handleSave();
    };

    const handleResetTimer = async () => {
        setShowCureModal(false);
        setIsLoading(true);
        setError(null);
        
        try {
            await api.updateBatch({
                batch_id: batch._id,
                stage: selectedStage,
                cure_start_timestamp: new Date()
            });

            onStageChange(selectedStage);
            onClose();
        } catch (err) {
            console.error('Error updating batch stage with new timer:', err);
            setError('Failed to update stage. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="batch-table-stage-selector-overlay" onClick={onClose}>
                <div className="batch-table-stage-selector-modal" onClick={e => e.stopPropagation()}>
                    <div className="batch-table-stage-selector-header">
                        <div className="batch-table-stage-selector-header-content">
                            <h3>Change Stage</h3>
                            <span className="batch-table-stage-selector-batch-name">{batch.name}</span>
                        </div>
                        <button className="batch-table-stage-selector-close" onClick={onClose}>×</button>
                    </div>

                    <div className="batch-table-stage-selector-content">
                        <div className="batch-table-stage-selector-stages">
                            {STAGES.map(stage => (
                                <button
                                    key={stage.id}
                                    className={`batch-table-stage-selector-option ${selectedStage === stage.id ? 'selected' : ''}`}
                                    onClick={() => handleStageSelect(stage.id)}
                                    style={{
                                        '--stage-bg': `var(--ml-color-stage-${stage.id.toLowerCase()})`,
                                        '--stage-color': `var(--ml-color-stage-${stage.id.toLowerCase()}-text)`
                                    }}
                                >
                                    {stage.label}
                                </button>
                            ))}
                        </div>

                        {error && (
                            <div className="batch-table-stage-selector-error">
                                {error}
                            </div>
                        )}

                        <div className="batch-table-stage-selector-actions">
                            <button 
                                className="batch-table-stage-selector-button secondary" 
                                onClick={onClose}
                                disabled={isLoading}
                            >
                                Cancel
                            </button>
                            <button 
                                className="batch-table-stage-selector-button primary" 
                                onClick={handleSave}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {showCureModal && batch.cure_start_timestamp && (
                <CureTimerModal
                    timestamp={batch.cure_start_timestamp}
                    onKeepExisting={handleKeepExisting}
                    onResetTimer={handleResetTimer}
                />
            )}
        </>
    );
};

export default BatchTableStageSelector; 