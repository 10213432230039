import React from 'react'
import Api from './common/APIUtils'
import { useReducer, useEffect, useState } from 'react'
import { Routes, Route, BrowserRouter, useLocation } from 'react-router-dom'
import { SubscriptionProvider, useSubscription } from './context/SubscriptionContext'
import ProtectedRoute from './components/ProtectedRoute'
import Home from './components/Home'
import Header from './components/Header'
import CreateRecipe from './components/forms/CreateRecipe'
import CreateBatch from './components/forms/CreateBatch'
import CreateBatchRecipeSelection from './components/forms/CreateBatchRecipeSelection'
import Recipes from './components/recipes/Recipes'
import Recipe from './components/recipes/Recipe'
import Batch from './components/batches/Batch'
import Batches from './components/batches/Batches'
import Candle from './components/candles/Candle'
import InventoryMain from './components/inventory/InventoryMain'
import InventorySupplyItem from './components/inventory/InventorySupplyItem'
import Collection from './components/collections/Collection'
import RoutingErrorPage from './components/RoutingErrorPage'
import PrivacyPolicy from './components/PrivacyPolicy'
import NavigationBar from './components/navigation/NavigationBar'
import LoadingSymbol from './components/misc/LoadingSymbol'
import PlanSelection from './components/subscription/PlanSelection'
import LandingMain from './components/landings/LandingMain'
import './styles/variables.css'
import './styles/styles.css'

// Move App content to a new component to use hooks
function AppContent() {
  const [isLoading, setIsLoading] = useState(() => { return true })
  const { subscriptionStatus } = useSubscription();
  const location = useLocation();
  const PUBLIC_PATHS = ['/privacy-policy', '/plans'];
  const api = new Api()
  const initialState = {
    authenticated: false,
    view: "home",
    recipes: [],
    archived_recipes: [],
    batches: [],
    candles: [],
    collections: [],
    tasks: [],
    supplies: [],
    library_loaded: false,
    active_subscription: false,
    subscription_id: false,
    subscription_status: false,
    user: false,
    loaded: false,
  }
  const [globalState, dispatch] = useReducer((state, action) => {
    if (action.type === "login") {
      if (action.payload.success) return { ...state, authenticated: true, loaded: true, user: action.payload.user, active_subscription: action.payload.active_subscription, subscription_status: action.payload.subscription_status, subscription_id: action.payload.subscription_id }
      if (!action.payload.success) return { ...state, authenticated: false, loaded: true }
    }
    if (action.type === "dismiss_warning") return { ...state, mobile_warning_dismissed: true }
    if (action.type === "nav") {
      return { ...state, view: action.payload.view }
    }
    if (action.type === "update_recipes") {
      return {
        ...state,
        recipes: action.value.recipes,
        batches: action.value.batches,
        candles: action.value.candles,
        library_loaded: true,
        collections: action.value.collections,
        archived_recipes: action.value.archived_recipes,
        tasks: action.value.tasks,
        supplies: action.value.supplies
      }
    }
    if (action.type === "update_consumption") {
      return { ...state, consumed_supplies: action.value }
    }
    return state
  }, initialState)
  const fetchUserLibrary = () => {
    if (!globalState.authenticated) return
    api.fetchUserLibrary()
    .then((res) => {
      if (res.data) dispatch({type: "update_recipes", value: res.data.library }) 
    })
    .catch((err) => {
      return
    })
  }
  useEffect(() => {
    // check if logged in
    api.isAuthenticated()
    .then((res) => {
        if (res.data.user) {
          dispatch({type: "login", payload: {
            user: res.data.user,
            active_subscription: res.data.active_subscription,
            subscription_id: res.data.subscription_id,
            subscription_status: res.data.subscription_status,
            success: true,
            loaded: true
          }})

        }
        if (!res.data.user) dispatch({type: "login", payload: { success: false, user: false, loaded: true }})
        setIsLoading(false)
    })
    .catch((err) => {
      setIsLoading(false)
      return dispatch({type: "login", payload: { success: false, user: false, loaded: true }})
    })
  // eslint-disable-next-line
  }, [])
  return (
    <>
      <Header globalState={globalState} dispatch={dispatch}/>
      {!isLoading &&
      <Routes>
        <Route path="/" errorElement={<RoutingErrorPage/>} element={<LandingMain globalState={globalState} dispatch={dispatch}/>} />
        
        <Route path="/home" errorElement={<RoutingErrorPage/>} element={<Home fetchUserLibrary={fetchUserLibrary} globalState={globalState} dispatch={dispatch}/>} />
        <Route path="/privacy-policy" errorElement={<RoutingErrorPage/>} element={<PrivacyPolicy fetchUserLibrary={fetchUserLibrary} globalState={globalState} dispatch={dispatch}/>} />
        <Route path="/plans" errorElement={<RoutingErrorPage/>} element={<PlanSelection />} />
        
        {/* Protected Routes */}
        <Route path="/candles/recipes" errorElement={<RoutingErrorPage/>} element={
          <ProtectedRoute>
            <Recipes fetchUserLibrary={fetchUserLibrary} globalState={globalState} dispatch={dispatch}/>
          </ProtectedRoute>
        } />
        <Route path="/candles/batches" errorElement={<RoutingErrorPage/>} element={
          <ProtectedRoute>
            <Batches fetchUserLibrary={fetchUserLibrary} globalState={globalState} dispatch={dispatch}/>
          </ProtectedRoute>
        } />
        <Route path="/candles/inventory" errorElement={<RoutingErrorPage/>} element={
          <ProtectedRoute>
            <InventoryMain fetchUserLibrary={fetchUserLibrary} globalState={globalState} dispatch={dispatch}/>
          </ProtectedRoute>
        } />
        <Route path="/candles/inventory/supplies/:supplyId" errorElement={<RoutingErrorPage/>} element={
          <ProtectedRoute>
            <InventorySupplyItem fetchUserLibrary={fetchUserLibrary} globalState={globalState} dispatch={dispatch}/>
          </ProtectedRoute>
        } />
        <Route path="/candles/candle/:candleId" errorElement={<RoutingErrorPage/>} element={
          <ProtectedRoute>
            <Candle fetchUserLibrary={fetchUserLibrary} globalState={globalState} dispatch={dispatch}/>
          </ProtectedRoute>
        } />
        <Route path="/recipe/:recipeId" errorElement={<RoutingErrorPage/>} element={
          <ProtectedRoute>
            <Recipe globalState={globalState} dispatch={dispatch} fetchUserLibrary={fetchUserLibrary}/>
          </ProtectedRoute>
        } />
        <Route path="/collection/:collectionId" errorElement={<RoutingErrorPage/>} element={
          <ProtectedRoute>
            <Collection globalState={globalState} dispatch={dispatch} fetchUserLibrary={fetchUserLibrary}/>
          </ProtectedRoute>
        } />
        <Route path="/candles/batch/:batchId" errorElement={<RoutingErrorPage/>} element={
          <ProtectedRoute>
            <Batch globalState={globalState} dispatch={dispatch} fetchUserLibrary={fetchUserLibrary} />
          </ProtectedRoute>
        } />
        <Route path="/create/recipe" errorElement={<RoutingErrorPage/>} element={
          <ProtectedRoute>
            <CreateRecipe globalState={globalState} dispatch={dispatch} fetchUserLibrary={fetchUserLibrary}/>
          </ProtectedRoute>
        } />
        <Route path="/create/batch" errorElement={<RoutingErrorPage/>} element={
          <ProtectedRoute>
            <CreateBatchRecipeSelection globalState={globalState} dispatch={dispatch}/>
          </ProtectedRoute>
        } />
        <Route path="/create/batch/:recipeId" errorElement={<RoutingErrorPage/>} element={
          <ProtectedRoute>
            <CreateBatch globalState={globalState} dispatch={dispatch} fetchUserLibrary={fetchUserLibrary}/>
          </ProtectedRoute>
        } />
        
        {!isLoading && <Route path="*" element={<RedirectToHome/>} />}
      </Routes>
      }
      {isLoading && <div style={{display: "flex", justifyContent: "center"}}><LoadingSymbol/></div> }
      {globalState.authenticated && subscriptionStatus?.isActive && !PUBLIC_PATHS.includes(location.pathname) && 
        <NavigationBar globalState={globalState} dispatch={dispatch}/>
      }
    </>
  )
}

// Main App component
export default function App() {
  return (
    <div className="moonlite-main">
      <BrowserRouter>
        <SubscriptionProvider>
          <AppContent />
        </SubscriptionProvider>
      </BrowserRouter>
    </div>
  )
}

function RedirectToHome() {
  return (
    <div>
      Page not found (or you don't have access to it)
      <div><a href="/">Go back</a></div>
    </div>
  )
}