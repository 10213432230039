import React from 'react';
import './CureTimerModal.css';
import { format } from 'date-fns';

const CureTimerModal = ({ timestamp, onKeepExisting, onResetTimer }) => {
    return (
        <div className="cure-timer-modal-overlay">
            <div className="cure-timer-modal">
                <h2>Reset Cure Timer?</h2>
                <p>
                    This batch started curing on {format(new Date(timestamp), 'MMMM d, yyyy')}. 
                    Would you like to reset the cure timer?
                </p>
                <div className="cure-timer-modal-buttons">
                    <button 
                        className="cure-timer-modal-button keep" 
                        onClick={onKeepExisting}
                    >
                        Keep Existing
                    </button>
                    <button 
                        className="cure-timer-modal-button reset" 
                        onClick={onResetTimer}
                    >
                        Reset Timer
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CureTimerModal; 