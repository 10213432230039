import React, { useState, useEffect, useRef } from 'react';
import './BatchStageSelector.css';
import Api from '../../common/APIUtils';
import CureTimerModal from './CureTimerModal';

const BatchStageSelector = ({ batch, onStageChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showCureModal, setShowCureModal] = useState(false);
    const [pendingStageUpdate, setPendingStageUpdate] = useState(null);
    const dropdownRef = useRef(null);
    const api = new Api();

    const STAGES = [
        { id: 'NEW', label: 'New', color: '#E3F2FD' },
        { id: 'POURING', label: 'Pouring', color: '#FFF3E0' },
        { id: 'CURING', label: 'Curing', color: '#F3E5F5' },
        { id: 'TESTING', label: 'Testing', color: '#FFF9C4' },
        { id: 'DONE', label: 'Done', color: '#E8F5E9' }
    ];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const getCurrentStageLabel = () => {
        const currentStage = STAGES.find(s => s.id === (batch.stage || 'NEW'));
        return currentStage ? currentStage.label : 'New';
    };

    const handleStageSelect = async (newStage) => {
        if (isLoading || newStage === batch.stage) return;
        
        if (newStage === 'CURING' && batch.cure_start_timestamp && batch.stage !== 'CURING') {
            setPendingStageUpdate(newStage);
            setShowCureModal(true);
            setIsOpen(false);
            return;
        }

        await updateBatchStage(newStage);
    };

    const updateBatchStage = async (newStage, resetTimer = false) => {
        setIsLoading(true);
        setError(null);
        
        try {

            const updateData = { stage: newStage };
            
            if (newStage === 'CURING') {
                if (!batch.cure_start_timestamp || resetTimer) {
                    updateData.cure_start_timestamp = new Date();
                }
            }

            await api.updateBatch({
                batch_id: batch._id,
                ...updateData
            });

            onStageChange(newStage);
        } catch (err) {
            console.error('Detailed error updating batch stage:', {
                error: err,
                errorMessage: err.message,
                errorResponse: err.response?.data
            });
            setError('Failed to update stage. Please try again.');
        } finally {
            setIsLoading(false);
            setIsOpen(false);
            setPendingStageUpdate(null);
        }
    };

    const handleKeepExisting = () => {
        setShowCureModal(false);
        if (pendingStageUpdate) {
            updateBatchStage(pendingStageUpdate, false);
        }
    };

    const handleResetTimer = () => {
        setShowCureModal(false);
        if (pendingStageUpdate) {
            updateBatchStage(pendingStageUpdate, true);
        }
    };

    const currentStage = STAGES.find(s => s.id === (batch.stage || 'NEW'));

    return (
        <>
            <div className="batch-stage-selector" ref={dropdownRef}>
                <div 
                    className={`batch-stage-selector-current ${isLoading ? 'loading' : ''}`}
                    onClick={() => !isLoading && setIsOpen(!isOpen)}
                    style={{ backgroundColor: currentStage?.color }}
                >
                    <span>{getCurrentStageLabel()}</span>
                    <span className="batch-stage-selector-arrow">▼</span>
                </div>
                
                {isOpen && (
                    <div className="batch-stage-selector-dropdown">
                        {STAGES.map((stage) => (
                            <div
                                key={stage.id}
                                className={`batch-stage-selector-option ${stage.id === batch.stage ? 'selected' : ''}`}
                                onClick={() => handleStageSelect(stage.id)}
                                style={{ backgroundColor: stage.id === batch.stage ? stage.color : 'transparent' }}
                            >
                                {stage.label}
                            </div>
                        ))}
                    </div>
                )}
                
                {error && <div className="batch-stage-selector-error">{error}</div>}
            </div>

            {showCureModal && batch.cure_start_timestamp && (
                <CureTimerModal
                    timestamp={batch.cure_start_timestamp}
                    onKeepExisting={handleKeepExisting}
                    onResetTimer={handleResetTimer}
                />
            )}
        </>
    );
};

export default BatchStageSelector; 